import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";

type Props = {
    lg: string;
    md: string;
    sm: string;
    xs: string;
}
export function useDataGridResponsiveHeight(props: Props) {
    const { lg, md, sm, xs } = props;
    const theme = useTheme();
    const mediaQueryLg = useMediaQuery(theme.breakpoints.up("lg"));
    const mediaQueryMd = useMediaQuery(theme.breakpoints.up("md"));
    const mediaQuerySm = useMediaQuery(theme.breakpoints.up("sm"));

    const responsiveHeight = useMemo(() => {
        if (mediaQueryLg) return {
            height: lg,
            breakPoint: 'lg'
        };
        if (mediaQueryMd) return {
            height: md,
            breakPoint: 'md'
        };
        if (mediaQuerySm) return {
            height: sm,
            breakPoint: 'sm'
        };
        return {
            height: xs,
            breakPoint: 'xs'
        }; // Default size
    }, [lg, md, mediaQueryLg, mediaQueryMd, mediaQuerySm, sm, xs]);


    return responsiveHeight;
}