/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Profile API
 * The ASP.NET Core Web API for the Profile information services. That is, services that revolve around,receiving and distributing information about entities within the business domain of Medicaid to Schools billing and documentation.
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * Contains a List of DistrictAccessRequest and a List of ProxyPostAccessRequest
 * @export
 * @interface AccessNotificationResponse
 */
export interface AccessNotificationResponse {
    /**
     * 
     * @type {Array<DistrictAccessRequest>}
     * @memberof AccessNotificationResponse
     */
    districtAccessRequestNotifications?: Array<DistrictAccessRequest>;
    /**
     * 
     * @type {Array<ProxyPostAccessRequest>}
     * @memberof AccessNotificationResponse
     */
    serviceProviderNotifications?: Array<ProxyPostAccessRequest>;
    /**
     * 
     * @type {Array<SupervisorAccessRequest>}
     * @memberof AccessNotificationResponse
     */
    userNotifications?: Array<SupervisorAccessRequest>;
}
/**
 * 
 * @export
 * @interface Accommodation
 */
export interface Accommodation {
    /**
     * 
     * @type {string}
     * @memberof Accommodation
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof Accommodation
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Accommodation
     */
    endDate?: Date;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof Activity
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Activity
     */
    endDate?: Date;
}
/**
 * 
 * @export
 * @interface ActivityInventoryItem
 */
export interface ActivityInventoryItem {
    /**
     * 
     * @type {string}
     * @memberof ActivityInventoryItem
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityInventoryItem
     */
    billable?: boolean;
}
/**
 * 
 * @export
 * @interface AddDistrictsToUserRequest
 */
export interface AddDistrictsToUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AddDistrictsToUserRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddDistrictsToUserRequest
     */
    clientId?: string;
    /**
     * 
     * @type {Array<DistrictRef>}
     * @memberof AddDistrictsToUserRequest
     */
    districts?: Array<DistrictRef>;
}
/**
 * 
 * @export
 * @interface AddSchoolCampusRequest
 */
export interface AddSchoolCampusRequest {
    /**
     * 
     * @type {SchoolCampus}
     * @memberof AddSchoolCampusRequest
     */
    campus?: SchoolCampus;
}
/**
 * 
 * @export
 * @interface AdditionalDocumentationItem
 */
export interface AdditionalDocumentationItem {
    /**
     * 
     * @type {string}
     * @memberof AdditionalDocumentationItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalDocumentationItem
     */
    name?: string;
    /**
     * 
     * @type {DocumentationItemDataType}
     * @memberof AdditionalDocumentationItem
     */
    dataType?: DocumentationItemDataType;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalDocumentationItem
     */
    isRequired?: boolean;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zipCode?: string;
}
/**
 * 
 * @export
 * @interface AppointingServiceProviderRef
 */
export interface AppointingServiceProviderRef {
    /**
     * 
     * @type {string}
     * @memberof AppointingServiceProviderRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointingServiceProviderRef
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointingServiceProviderRef
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointingServiceProviderRef
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointingServiceProviderRef
     */
    hasGrantedAccessToPost?: boolean;
}
/**
 * 
 * @export
 * @interface AppraisalMethodology
 */
export interface AppraisalMethodology {
    /**
     * 
     * @type {number}
     * @memberof AppraisalMethodology
     */
    rate?: number;
    /**
     * Zero means \"not applicable\"
     * @type {number}
     * @memberof AppraisalMethodology
     */
    unitDuration?: number;
    /**
     * 
     * @type {UnitCompositionType}
     * @memberof AppraisalMethodology
     */
    unitCompositionType?: UnitCompositionType;
    /**
     * 
     * @type {number}
     * @memberof AppraisalMethodology
     */
    maximumUnits?: number;
    /**
     * 
     * @type {UnitRoundingMode}
     * @memberof AppraisalMethodology
     */
    unitRoundingMode?: UnitRoundingMode;
}
/**
 * 
 * @export
 * @interface ApproverCaseload
 */
export interface ApproverCaseload {
    /**
     * 
     * @type {string}
     * @memberof ApproverCaseload
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproverCaseload
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproverCaseload
     */
    campus?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproverCaseload
     */
    providerRole?: string;
}
/**
 * 
 * @export
 * @interface BillingCondition
 */
export interface BillingCondition {
}
/**
 * 
 * @export
 * @interface BillingConstraint
 */
export interface BillingConstraint {
    /**
     * 
     * @type {InclusionConstraint}
     * @memberof BillingConstraint
     */
    constraint?: InclusionConstraint;
    /**
     * 
     * @type {Array<ProcedureRef>}
     * @memberof BillingConstraint
     */
    procedures?: Array<ProcedureRef>;
    /**
     * 
     * @type {string}
     * @memberof BillingConstraint
     */
    followupInstructions?: string;
}
/**
 * 
 * @export
 * @interface BlockBillingHistory
 */
export interface BlockBillingHistory {
    /**
     * 
     * @type {boolean}
     * @memberof BlockBillingHistory
     */
    blockBillingForProvider?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof BlockBillingHistory
     */
    blockBillingStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BlockBillingHistory
     */
    datePosted?: Date;
}
/**
 * 
 * @export
 * @interface CampusContact
 */
export interface CampusContact {
    /**
     * 
     * @type {string}
     * @memberof CampusContact
     */
    contactType?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusContact
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusContact
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusContact
     */
    prefix?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusContact
     */
    suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusContact
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusContact
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusContact
     */
    role?: string;
}
/**
 * 
 * @export
 * @interface CampusLineItemCard
 */
export interface CampusLineItemCard {
    /**
     * 
     * @type {string}
     * @memberof CampusLineItemCard
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusLineItemCard
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusLineItemCard
     */
    stateId?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusLineItemCard
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusLineItemCard
     */
    contactFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusLineItemCard
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CampusLineItemCard
     */
    contactRole?: string;
}
/**
 * 
 * @export
 * @interface CaseloadStudentDisplay
 */
export interface CaseloadStudentDisplay {
    /**
     * 
     * @type {string}
     * @memberof CaseloadStudentDisplay
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseloadStudentDisplay
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseloadStudentDisplay
     */
    lastName?: string;
    /**
     * 
     * @type {Date}
     * @memberof CaseloadStudentDisplay
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {Grade}
     * @memberof CaseloadStudentDisplay
     */
    grade?: Grade;
    /**
     * 
     * @type {string}
     * @memberof CaseloadStudentDisplay
     */
    studentIdGivenByState?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseloadStudentDisplay
     */
    studentIdGivenBySchoolDistrict?: string;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof CaseloadStudentDisplay
     */
    activeSchoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {PlanOfCare}
     * @memberof CaseloadStudentDisplay
     */
    activePlanOfCare?: PlanOfCare;
    /**
     * 
     * @type {string}
     * @memberof CaseloadStudentDisplay
     */
    medicaidId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClassType {
    NUMBER_0 = <any> 0
}
/**
 * Enum: Population = 0, Credential = 1
 * @export
 * @enum {string}
 */
export enum ClassificationCriteriaType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1
}
/**
 * 
 * @export
 * @interface ClassificationCriterion
 */
export interface ClassificationCriterion {
    /**
     * 
     * @type {ClassificationCriteriaType}
     * @memberof ClassificationCriterion
     */
    type?: ClassificationCriteriaType;
    /**
     * 
     * @type {any}
     * @memberof ClassificationCriterion
     */
    acceptableValues?: any;
}
/**
 * Enum: Draft = 0, PublishingQueue = 1, Active = 2
 * @export
 * @enum {string}
 */
export enum ClientAccountStatus {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * 
 * @export
 * @interface ClientAssignment
 */
export interface ClientAssignment {
    /**
     * 
     * @type {ClientTermRef}
     * @memberof ClientAssignment
     */
    client?: ClientTermRef;
    /**
     * The districts this user is authorized to access for this client.
     * @type {Array<DistrictRef>}
     * @memberof ClientAssignment
     */
    authorizedDistricts?: Array<DistrictRef>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAssignment
     */
    isExecutiveAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAssignment
     */
    isDelegatedAdmin?: boolean;
    /**
     * 
     * @type {SelectedCalendarFilters}
     * @memberof ClientAssignment
     */
    selectedCalendarFilters?: SelectedCalendarFilters;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAssignment
     */
    isApprover?: boolean;
    /**
     * 
     * @type {Array<ServiceProviderRef>}
     * @memberof ClientAssignment
     */
    supervisedServiceProviders?: Array<ServiceProviderRef>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAssignment
     */
    isProxyDataEntry?: boolean;
    /**
     * A list of the service providers that have appointed this person as their proxy.
     * @type {Array<AppointingServiceProviderRef>}
     * @memberof ClientAssignment
     */
    appointingServiceProviders?: Array<AppointingServiceProviderRef>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAssignment
     */
    isAutonomous?: boolean;
    /**
     * 
     * @type {ServiceProviderRef}
     * @memberof ClientAssignment
     */
    serviceProviderProfile?: ServiceProviderRef;
    /**
     * 
     * @type {ServiceProviderRef}
     * @memberof ClientAssignment
     */
    approvingServiceProvider?: ServiceProviderRef;
}
/**
 * Enum: Access = 0, NoAccess = 1
 * @export
 * @enum {string}
 */
export enum ClientAssignmentStatus {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1
}
/**
 * 
 * @export
 * @interface ClientDistrictRef
 */
export interface ClientDistrictRef {
    /**
     * 
     * @type {string}
     * @memberof ClientDistrictRef
     */
    id?: string;
    /**
     * The name of the district.
     * @type {string}
     * @memberof ClientDistrictRef
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDistrictRef
     */
    region?: string;
}
/**
 * This is the response for a request to get all clients for a dropdown.
 * @export
 * @interface ClientDropdownResponse
 */
export interface ClientDropdownResponse {
    /**
     * The list of all clients in the state. Start and end dates will be null.
     * @type {Array<ClientRef>}
     * @memberof ClientDropdownResponse
     */
    clients?: Array<ClientRef>;
}
/**
 * 
 * @export
 * @interface ClientGoal
 */
export interface ClientGoal {
}
/**
 * 
 * @export
 * @interface ClientProfile
 */
export interface ClientProfile {
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientProfile
     */
    name?: string;
    /**
     * 
     * @type {ClientAccountStatus}
     * @memberof ClientProfile
     */
    status?: ClientAccountStatus;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof ClientProfile
     */
    msbRepresentatives?: Array<Contact>;
    /**
     * 
     * @type {ClientDistrictRef}
     * @memberof ClientProfile
     */
    fiscalAgent?: ClientDistrictRef;
    /**
     * 
     * @type {Array<ClientDistrictRef>}
     * @memberof ClientProfile
     */
    additionalDistricts?: Array<ClientDistrictRef>;
    /**
     * 
     * @type {Array<NonSchoolDay>}
     * @memberof ClientProfile
     */
    nonSchoolDays?: Array<NonSchoolDay>;
    /**
     * 
     * @type {Array<ProgressReportDate>}
     * @memberof ClientProfile
     */
    progressReportDates?: Array<ProgressReportDate>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientProfile
     */
    usesCampusRestrictions?: boolean;
    /**
     * 
     * @type {MedicaidPolicy}
     * @memberof ClientProfile
     */
    customMedicaidPolicy?: MedicaidPolicy;
    /**
     * 
     * @type {Array<GoalAreaOfFocus>}
     * @memberof ClientProfile
     */
    customGoalAreaOfFocusInventory?: Array<GoalAreaOfFocus>;
    /**
     * 
     * @type {Array<ServiceCategoryConfig>}
     * @memberof ClientProfile
     */
    customServiceCategoryConfigurations?: Array<ServiceCategoryConfig>;
}
/**
 * 
 * @export
 * @interface ClientRef
 */
export interface ClientRef {
    /**
     * 
     * @type {string}
     * @memberof ClientRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientRef
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ClientResponse
 */
export interface ClientResponse {
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    name?: string;
    /**
     * 
     * @type {ClientAccountStatus}
     * @memberof ClientResponse
     */
    status?: ClientAccountStatus;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof ClientResponse
     */
    msbRepresentatives?: Array<Contact>;
    /**
     * 
     * @type {ClientDistrictRef}
     * @memberof ClientResponse
     */
    fiscalAgent?: ClientDistrictRef;
    /**
     * 
     * @type {Array<ClientDistrictRef>}
     * @memberof ClientResponse
     */
    additionalDistricts?: Array<ClientDistrictRef>;
    /**
     * This acts as a feature flag for Campus Access Restrictions in the Client profile
     * @type {boolean}
     * @memberof ClientResponse
     */
    usesCampusRestrictions?: boolean;
}
/**
 * 
 * @export
 * @interface ClientTermRef
 */
export interface ClientTermRef {
    /**
     * 
     * @type {string}
     * @memberof ClientTermRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientTermRef
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof ClientTermRef
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClientTermRef
     */
    endDate?: Date;
}
/**
 * Enum: Yes = 0, No = 1, Refused = 2, NoneSelected = 3
 * @export
 * @enum {string}
 */
export enum ConsentDecision {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3
}
/**
 * 
 * @export
 * @interface ConsentForMedicaidBilling
 */
export interface ConsentForMedicaidBilling {
    /**
     * 
     * @type {ConsentDecision}
     * @memberof ConsentForMedicaidBilling
     */
    decision?: ConsentDecision;
    /**
     * 
     * @type {Date}
     * @memberof ConsentForMedicaidBilling
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ConsentForMedicaidBilling
     */
    endDate?: Date;
}
/**
 * 
 * @export
 * @interface ConsolidationRange
 */
export interface ConsolidationRange {
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    contactType?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    prefix?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phoneNumber?: string;
}
/**
 * 
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * 
     * @type {ContractStatus}
     * @memberof Contract
     */
    status?: ContractStatus;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    serviceLevelAgreementUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    contractDocumentUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    invoicingRate?: number;
}
/**
 * Enum: Active = 0, Inactive = 1, Perpetuity = 2
 * @export
 * @enum {string}
 */
export enum ContractStatus {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * 
 * @export
 * @interface CreateClientRequest
 */
export interface CreateClientRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    name?: string;
    /**
     * 
     * @type {ClientAccountStatus}
     * @memberof CreateClientRequest
     */
    status?: ClientAccountStatus;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof CreateClientRequest
     */
    msbRepresentatives?: Array<Contact>;
    /**
     * 
     * @type {ClientDistrictRef}
     * @memberof CreateClientRequest
     */
    fiscalAgent?: ClientDistrictRef;
    /**
     * 
     * @type {Array<ClientDistrictRef>}
     * @memberof CreateClientRequest
     */
    additionalDistricts?: Array<ClientDistrictRef>;
    /**
     * This acts as a feature flag for Campus Access Restrictions in the Client profile
     * @type {boolean}
     * @memberof CreateClientRequest
     */
    usesCampusRestrictions?: boolean;
}
/**
 * 
 * @export
 * @interface CreateDistrictRequest
 */
export interface CreateDistrictRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDistrictRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDistrictRequest
     */
    npi?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateDistrictRequest
     */
    billingStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateDistrictRequest
     */
    billingEnd?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateDistrictRequest
     */
    w9?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDistrictRequest
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDistrictRequest
     */
    stateProviderId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDistrictRequest
     */
    stateDistrictId?: string;
    /**
     * 
     * @type {Address}
     * @memberof CreateDistrictRequest
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof CreateDistrictRequest
     */
    billingAddress?: Address;
    /**
     * 
     * @type {Array<SchoolCampus>}
     * @memberof CreateDistrictRequest
     */
    schoolCampuses?: Array<SchoolCampus>;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof CreateDistrictRequest
     */
    contactDirectory?: Array<Contact>;
    /**
     * 
     * @type {Array<PastAudit>}
     * @memberof CreateDistrictRequest
     */
    pastAudits?: Array<PastAudit>;
    /**
     * 
     * @type {Array<ClientTermRef>}
     * @memberof CreateDistrictRequest
     */
    clients?: Array<ClientTermRef>;
    /**
     * 
     * @type {Array<Crm>}
     * @memberof CreateDistrictRequest
     */
    crms?: Array<Crm>;
    /**
     * 
     * @type {Array<Contract>}
     * @memberof CreateDistrictRequest
     */
    contracts?: Array<Contract>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDistrictRequest
     */
    isFiscalAgent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateDistrictRequest
     */
    spedCount?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDistrictRequest
     */
    region?: string;
}
/**
 * 
 * @export
 * @interface CreateGoalRequest
 */
export interface CreateGoalRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateGoalRequest
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGoalRequest
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateGoalRequest
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateGoalRequest
     */
    endDate?: Date;
    /**
     * 
     * @type {GoalAreaOfFocusRef}
     * @memberof CreateGoalRequest
     */
    goalAreaOfFocus?: GoalAreaOfFocusRef;
    /**
     * 
     * @type {string}
     * @memberof CreateGoalRequest
     */
    status?: string;
    /**
     * 
     * @type {Array<Objective>}
     * @memberof CreateGoalRequest
     */
    objectives?: Array<Objective>;
}
/**
 * This is request for adding prescribing provider
 * @export
 * @interface CreatePrescribingProviderRequest
 */
export interface CreatePrescribingProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePrescribingProviderRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrescribingProviderRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrescribingProviderRequest
     */
    npi?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePrescribingProviderRequest
     */
    stateMedicaidNumber?: string;
    /**
     * 
     * @type {ClientTermRef}
     * @memberof CreatePrescribingProviderRequest
     */
    client?: ClientTermRef;
}
/**
 * The request object for creating a service provider.
 * @export
 * @interface CreateServiceProviderRequest
 */
export interface CreateServiceProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    middleName?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateServiceProviderRequest
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    rtmsEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    notificationEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    employeeId?: string;
    /**
     * 
     * @type {DocumentationType}
     * @memberof CreateServiceProviderRequest
     */
    documentationType?: DocumentationType;
    /**
     * 
     * @type {ClassType}
     * @memberof CreateServiceProviderRequest
     */
    classType?: ClassType;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {ClientTermRef}
     * @memberof CreateServiceProviderRequest
     */
    client?: ClientTermRef;
    /**
     * 
     * @type {Array<DistrictRef>}
     * @memberof CreateServiceProviderRequest
     */
    districtsOfOperation?: Array<DistrictRef>;
    /**
     * 
     * @type {Array<SchoolCampusAssignmentRequest>}
     * @memberof CreateServiceProviderRequest
     */
    assignedSchoolCampuses?: Array<SchoolCampusAssignmentRequest>;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    jobTitle?: string;
    /**
     * 
     * @type {EmployeeType}
     * @memberof CreateServiceProviderRequest
     */
    employeeType?: EmployeeType;
    /**
     * 
     * @type {boolean}
     * @memberof CreateServiceProviderRequest
     */
    isOnParticipationList?: boolean;
    /**
     * 
     * @type {ParticipationListStatusType}
     * @memberof CreateServiceProviderRequest
     */
    participationListStatus?: ParticipationListStatusType;
    /**
     * 
     * @type {Array<MedicaidCredential>}
     * @memberof CreateServiceProviderRequest
     */
    medicaidCredentials?: Array<MedicaidCredential>;
    /**
     * 
     * @type {Array<ProviderRate>}
     * @memberof CreateServiceProviderRequest
     */
    rateRecords?: Array<ProviderRate>;
    /**
     * 
     * @type {Array<StudentRef>}
     * @memberof CreateServiceProviderRequest
     */
    studentCaseload?: Array<StudentRef>;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof CreateServiceProviderRequest
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
    /**
     * 
     * @type {Array<FederalFunding>}
     * @memberof CreateServiceProviderRequest
     */
    federalFundingRecords?: Array<FederalFunding>;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    npi?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceProviderRequest
     */
    stateMedicaidNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateServiceProviderRequest
     */
    blockBillingForProvider?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CreateServiceProviderRequest
     */
    blockBillingStartDate?: Date;
    /**
     * 
     * @type {Array<TrainingInformation>}
     * @memberof CreateServiceProviderRequest
     */
    trainingInformationLog?: Array<TrainingInformation>;
    /**
     * 
     * @type {ClientAssignmentStatus}
     * @memberof CreateServiceProviderRequest
     */
    clientAssignmentStatus?: ClientAssignmentStatus;
}
/**
 * 
 * @export
 * @interface CreateStateSnapshotRequest
 */
export interface CreateStateSnapshotRequest {
    /**
     * 
     * @type {Date}
     * @memberof CreateStateSnapshotRequest
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateStateSnapshotRequest
     */
    endDate?: Date;
    /**
     * 
     * @type {MedicaidAgency}
     * @memberof CreateStateSnapshotRequest
     */
    medicaidAgency?: MedicaidAgency;
    /**
     * 
     * @type {MedicaidPolicy}
     * @memberof CreateStateSnapshotRequest
     */
    medicaidPolicy?: MedicaidPolicy;
    /**
     * 
     * @type {Array<GoalAreaOfFocus>}
     * @memberof CreateStateSnapshotRequest
     */
    goalAreaOfFocusInventory?: Array<GoalAreaOfFocus>;
    /**
     * 
     * @type {Array<ServiceCategoryConfig>}
     * @memberof CreateStateSnapshotRequest
     */
    serviceCategoryConfigurations?: Array<ServiceCategoryConfig>;
}
/**
 * 
 * @export
 * @interface CreateStudentRequest
 */
export interface CreateStudentRequest {
    /**
     * 
     * @type {ClientTermRef}
     * @memberof CreateStudentRequest
     */
    client?: ClientTermRef;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof CreateStudentRequest
     */
    schoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {string}
     * @memberof CreateStudentRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStudentRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStudentRequest
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStudentRequest
     */
    preferredName?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateStudentRequest
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {Grade}
     * @memberof CreateStudentRequest
     */
    grade?: Grade;
    /**
     * 
     * @type {Gender}
     * @memberof CreateStudentRequest
     */
    gender?: Gender;
    /**
     * 
     * @type {string}
     * @memberof CreateStudentRequest
     */
    primaryLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStudentRequest
     */
    socialSecurityNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStudentRequest
     */
    medicaidId?: string;
    /**
     * 
     * @type {Array<DistrictOfLiability>}
     * @memberof CreateStudentRequest
     */
    districtOfLiabilityRecords?: Array<DistrictOfLiability>;
    /**
     * 
     * @type {Array<DistrictProvidingServices>}
     * @memberof CreateStudentRequest
     */
    districtProvidingServicesRecords?: Array<DistrictProvidingServices>;
    /**
     * 
     * @type {string}
     * @memberof CreateStudentRequest
     */
    studentIdGivenByState?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStudentRequest
     */
    studentIdGivenBySchoolDistrict?: string;
    /**
     * 
     * @type {StudentXLogsStatus}
     * @memberof CreateStudentRequest
     */
    xLogsStatus?: StudentXLogsStatus;
    /**
     * 
     * @type {Address}
     * @memberof CreateStudentRequest
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {SpedDossier}
     * @memberof CreateStudentRequest
     */
    spedDossier?: SpedDossier;
    /**
     * 
     * @type {CreatedBy}
     * @memberof CreateStudentRequest
     */
    createdBy?: CreatedBy;
}
/**
 * This request is used to create a new unregistered provider.
 * @export
 * @interface CreateUnregisteredProviderRequest
 */
export interface CreateUnregisteredProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUnregisteredProviderRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUnregisteredProviderRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUnregisteredProviderRequest
     */
    email?: string;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof CreateUnregisteredProviderRequest
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
    /**
     * 
     * @type {DocumentationType}
     * @memberof CreateUnregisteredProviderRequest
     */
    documentationType?: DocumentationType;
    /**
     * 
     * @type {ClientAssignmentStatus}
     * @memberof CreateUnregisteredProviderRequest
     */
    clientAssignmentStatus?: ClientAssignmentStatus;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof CreateUnregisteredProviderRequest
     */
    assignedSchoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {string}
     * @memberof CreateUnregisteredProviderRequest
     */
    employeeId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUnregisteredProviderRequest
     */
    isOnParticipationList?: boolean;
    /**
     * 
     * @type {ClientRef}
     * @memberof CreateUnregisteredProviderRequest
     */
    client?: ClientRef;
}
/**
 * This response is used to return the newly created unregistered provider.
 * @export
 * @interface CreateUnregisteredProviderResponse
 */
export interface CreateUnregisteredProviderResponse {
    /**
     * 
     * @type {ServiceProviderRef}
     * @memberof CreateUnregisteredProviderResponse
     */
    serviceProvider?: ServiceProviderRef;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * The objectId of the user in Azure B2C
     * @type {string}
     * @memberof CreateUserRequest
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUserRequest
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    isSuperAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    isMsbAdmin?: boolean;
    /**
     * 
     * @type {Array<ClientAssignment>}
     * @memberof CreateUserRequest
     */
    clientAssignments?: Array<ClientAssignment>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CreatedBy {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1
}
/**
 * 
 * @export
 * @interface Crm
 */
export interface Crm {
    /**
     * 
     * @type {string}
     * @memberof Crm
     */
    medicaidAnalysisUrl?: string;
    /**
     * 
     * @type {Array<ProductConfiguration>}
     * @memberof Crm
     */
    productConfigurations?: Array<ProductConfiguration>;
    /**
     * 
     * @type {Array<ClientGoal>}
     * @memberof Crm
     */
    clientGoals?: Array<ClientGoal>;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof Crm
     */
    invoices?: Array<Invoice>;
}
/**
 * 
 * @export
 * @interface DECCaseload
 */
export interface DECCaseload {
    /**
     * 
     * @type {string}
     * @memberof DECCaseload
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DECCaseload
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof DECCaseload
     */
    campus?: string;
    /**
     * 
     * @type {string}
     * @memberof DECCaseload
     */
    providerRole?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DECCaseload
     */
    hasGrantedAccessToPost?: boolean;
}
/**
 * 
 * @export
 * @interface DefaultCareProvisionsResponse
 */
export interface DefaultCareProvisionsResponse {
    /**
     * 
     * @type {Array<ActivityInventoryItem>}
     * @memberof DefaultCareProvisionsResponse
     */
    activities?: Array<ActivityInventoryItem>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultCareProvisionsResponse
     */
    accommodations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultCareProvisionsResponse
     */
    modifications?: Array<string>;
}
/**
 * Enum: Icd9 = 0, Icd10 = 1
 * @export
 * @enum {string}
 */
export enum DiagnosisCodeSource {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1
}
/**
 * 
 * @export
 * @interface Disability
 */
export interface Disability {
    /**
     * 
     * @type {string}
     * @memberof Disability
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Disability
     */
    name?: string;
    /**
     * This Diagnosis code is either an ICD-9 or ICD-10 code
     * @type {string}
     * @memberof Disability
     */
    diagnosisCode?: string;
    /**
     * 
     * @type {DiagnosisCodeSource}
     * @memberof Disability
     */
    source?: DiagnosisCodeSource;
}
/**
 * 
 * @export
 * @interface DistrictAccessRequest
 */
export interface DistrictAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof DistrictAccessRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictAccessRequest
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof DistrictAccessRequest
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DistrictAccessRequest
     */
    read?: Date;
    /**
     * Determined by the id of the district the user is requesting access to.
     * @type {string}
     * @memberof DistrictAccessRequest
     */
    partitionKey?: string;
    /**
     * 
     * @type {NotificationType}
     * @memberof DistrictAccessRequest
     */
    type?: NotificationType;
    /**
     * 
     * @type {RequestedDistrictAssignment}
     * @memberof DistrictAccessRequest
     */
    requestedDistrictAssignment?: RequestedDistrictAssignment;
    /**
     * 
     * @type {string}
     * @memberof DistrictAccessRequest
     */
    requestedClientId?: string;
    /**
     * 
     * @type {UserServiceProviderRef}
     * @memberof DistrictAccessRequest
     */
    requestingUser?: UserServiceProviderRef;
    /**
     * 
     * @type {Date}
     * @memberof DistrictAccessRequest
     */
    requestDate?: Date;
    /**
     * 
     * @type {DistrictNotificationResponse}
     * @memberof DistrictAccessRequest
     */
    response?: DistrictNotificationResponse;
}
/**
 * This is a reference to a district access request notifications.
 * @export
 * @interface DistrictAccessRequestRef
 */
export interface DistrictAccessRequestRef {
    /**
     * 
     * @type {DistrictRef}
     * @memberof DistrictAccessRequestRef
     */
    district?: DistrictRef;
    /**
     * 
     * @type {string}
     * @memberof DistrictAccessRequestRef
     */
    notificationId?: string;
}
/**
 * 
 * @export
 * @interface DistrictNotificationResponse
 */
export interface DistrictNotificationResponse {
    /**
     * 
     * @type {NotificationResponseType}
     * @memberof DistrictNotificationResponse
     */
    type?: NotificationResponseType;
    /**
     * 
     * @type {string}
     * @memberof DistrictNotificationResponse
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof DistrictNotificationResponse
     */
    dateTime?: Date;
    /**
     * 
     * @type {RoleAssignments}
     * @memberof DistrictNotificationResponse
     */
    roleAssignments?: RoleAssignments;
}
/**
 * 
 * @export
 * @interface DistrictOfLiability
 */
export interface DistrictOfLiability {
    /**
     * 
     * @type {DistrictRef}
     * @memberof DistrictOfLiability
     */
    liableDistrict?: DistrictRef;
    /**
     * 
     * @type {Date}
     * @memberof DistrictOfLiability
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DistrictOfLiability
     */
    endDate?: Date;
}
/**
 * 
 * @export
 * @interface DistrictProfile
 */
export interface DistrictProfile {
    /**
     * 
     * @type {string}
     * @memberof DistrictProfile
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictProfile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictProfile
     */
    npi?: string;
    /**
     * 
     * @type {Date}
     * @memberof DistrictProfile
     */
    billingStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DistrictProfile
     */
    billingEnd?: Date;
    /**
     * 
     * @type {string}
     * @memberof DistrictProfile
     */
    w9?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictProfile
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictProfile
     */
    stateProviderId?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictProfile
     */
    stateDistrictId?: string;
    /**
     * 
     * @type {Address}
     * @memberof DistrictProfile
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof DistrictProfile
     */
    billingAddress?: Address;
    /**
     * 
     * @type {Array<SchoolCampus>}
     * @memberof DistrictProfile
     */
    schoolCampuses?: Array<SchoolCampus>;
    /**
     * 
     * @type {Array<ProgressReportDate>}
     * @memberof DistrictProfile
     */
    progressReportDates?: Array<ProgressReportDate>;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof DistrictProfile
     */
    contactDirectory?: Array<Contact>;
    /**
     * 
     * @type {Array<PastAudit>}
     * @memberof DistrictProfile
     */
    pastAudits?: Array<PastAudit>;
    /**
     * 
     * @type {Array<ClientTermRef>}
     * @memberof DistrictProfile
     */
    clients?: Array<ClientTermRef>;
    /**
     * 
     * @type {Array<Crm>}
     * @memberof DistrictProfile
     */
    crms?: Array<Crm>;
    /**
     * 
     * @type {Array<Contract>}
     * @memberof DistrictProfile
     */
    contracts?: Array<Contract>;
    /**
     * 
     * @type {boolean}
     * @memberof DistrictProfile
     */
    isFiscalAgent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DistrictProfile
     */
    spedCount?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictProfile
     */
    region?: string;
    /**
     * 
     * @type {Array<NonSchoolDay>}
     * @memberof DistrictProfile
     */
    nonSchoolDays?: Array<NonSchoolDay>;
}
/**
 * This is a record of a district that has provided services to a student. It identifies where the student  is receiving services, and is not the same as the District of Liability, which informs which district the claims   for the student are submitted on behalf of. District Providing Services should impact which campuses the student  can be located in at a given point in time.
 * @export
 * @interface DistrictProvidingServices
 */
export interface DistrictProvidingServices {
    /**
     * 
     * @type {DistrictRef}
     * @memberof DistrictProvidingServices
     */
    district?: DistrictRef;
    /**
     * The date that the district began providing services to the student.
     * @type {Date}
     * @memberof DistrictProvidingServices
     */
    startDate?: Date;
    /**
     * The date the the district stopped providing services to the student.
     * @type {Date}
     * @memberof DistrictProvidingServices
     */
    endDate?: Date;
}
/**
 * 
 * @export
 * @interface DistrictRef
 */
export interface DistrictRef {
    /**
     * 
     * @type {string}
     * @memberof DistrictRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictRef
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface DistrictResponse
 */
export interface DistrictResponse {
    /**
     * 
     * @type {string}
     * @memberof DistrictResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictResponse
     */
    npi?: string;
    /**
     * 
     * @type {Date}
     * @memberof DistrictResponse
     */
    billingStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DistrictResponse
     */
    billingEnd?: Date;
    /**
     * 
     * @type {string}
     * @memberof DistrictResponse
     */
    w9?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictResponse
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictResponse
     */
    stateProviderId?: string;
    /**
     * 
     * @type {string}
     * @memberof DistrictResponse
     */
    stateDistrictId?: string;
    /**
     * 
     * @type {Address}
     * @memberof DistrictResponse
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof DistrictResponse
     */
    billingAddress?: Address;
    /**
     * 
     * @type {Array<SchoolCampus>}
     * @memberof DistrictResponse
     */
    schoolCampuses?: Array<SchoolCampus>;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof DistrictResponse
     */
    contactDirectory?: Array<Contact>;
    /**
     * 
     * @type {Array<PastAudit>}
     * @memberof DistrictResponse
     */
    pastAudits?: Array<PastAudit>;
    /**
     * 
     * @type {Array<ClientTermRef>}
     * @memberof DistrictResponse
     */
    clients?: Array<ClientTermRef>;
    /**
     * 
     * @type {Array<Crm>}
     * @memberof DistrictResponse
     */
    crms?: Array<Crm>;
    /**
     * 
     * @type {Array<Contract>}
     * @memberof DistrictResponse
     */
    contracts?: Array<Contract>;
    /**
     * 
     * @type {boolean}
     * @memberof DistrictResponse
     */
    isFiscalAgent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DistrictResponse
     */
    spedCount?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictResponse
     */
    region?: string;
}
/**
 * Enum: String = 0 | Number = 1 | Date = 2 | Boolean = 3 | File = 4
 * @export
 * @enum {string}
 */
export enum DocumentationItemDataType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4
}
/**
 * Enum: Paper = 0, Import = 1, XLogs = 2
 * @export
 * @enum {string}
 */
export enum DocumentationType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * 
 * @export
 * @interface EdiInstructionManual
 */
export interface EdiInstructionManual {
}
/**
 * The class represents an electronic signature for a specific statement type. The class is used as a property of the UserProfile class  as part of a hashset of electronic signatures which are used to track the user's agreement to various statements. No two statements  of the same type may exist in the hashset.
 * @export
 * @interface ElectronicSignature
 */
export interface ElectronicSignature {
    /**
     * 
     * @type {StatementType}
     * @memberof ElectronicSignature
     */
    statementType?: StatementType;
    /**
     * 
     * @type {boolean}
     * @memberof ElectronicSignature
     */
    isSigned?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ElectronicSignature
     */
    signedOnDateLocal?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ElectronicSignature
     */
    signedOnDateUtc?: Date;
    /**
     * 
     * @type {string}
     * @memberof ElectronicSignature
     */
    signedByFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ElectronicSignature
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ElectronicSignature
     */
    documentText?: string;
    /**
     * 
     * @type {string}
     * @memberof ElectronicSignature
     */
    requestIpAddress?: string;
}
/**
 * 
 * @export
 * @interface EligibilityRecord
 */
export interface EligibilityRecord {
    /**
     * 
     * @type {EligibilityStatus}
     * @memberof EligibilityRecord
     */
    status?: EligibilityStatus;
    /**
     * 
     * @type {Date}
     * @memberof EligibilityRecord
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EligibilityRecord
     */
    endDate?: Date;
}
/**
 * Enum: Eligible = 0 | Ineligible = 1 | Unknown = 2
 * @export
 * @enum {string}
 */
export enum EligibilityStatus {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * Enum: FullTime = 0, PartTime = 1, Contract = 2, OutOfDistrictEmployee = 3
 * @export
 * @enum {string}
 */
export enum EmployeeType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3
}
/**
 * 
 * @export
 * @interface FederalFunding
 */
export interface FederalFunding {
    /**
     * 
     * @type {string}
     * @memberof FederalFunding
     */
    fiscalYear?: string;
    /**
     * 
     * @type {string}
     * @memberof FederalFunding
     */
    annualSalary?: string;
    /**
     * 
     * @type {string}
     * @memberof FederalFunding
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof FederalFunding
     */
    percentage?: string;
    /**
     * 
     * @type {Date}
     * @memberof FederalFunding
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FederalFunding
     */
    endDate?: Date;
}
/**
 * Enum: Male = 0, Female = 1, Unknown = 2
 * @export
 * @enum {string}
 */
export enum Gender {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * 
 * @export
 * @interface GetCaseloadResponse
 */
export interface GetCaseloadResponse {
    /**
     * 
     * @type {Array<DECCaseload>}
     * @memberof GetCaseloadResponse
     */
    decCaseloads?: Array<DECCaseload>;
    /**
     * 
     * @type {Array<StudentCaseloadProfile>}
     * @memberof GetCaseloadResponse
     */
    studentCaseloadProfiles?: Array<StudentCaseloadProfile>;
    /**
     * 
     * @type {Array<ApproverCaseload>}
     * @memberof GetCaseloadResponse
     */
    approverCaseloads?: Array<ApproverCaseload>;
    /**
     * 
     * @type {Array<ProviderCaseload>}
     * @memberof GetCaseloadResponse
     */
    otherCaseloads?: Array<ProviderCaseload>;
}
/**
 * 
 * @export
 * @interface GetCaseloadStudentsResponse
 */
export interface GetCaseloadStudentsResponse {
    /**
     * 
     * @type {Array<CaseloadStudentDisplay>}
     * @memberof GetCaseloadStudentsResponse
     */
    caseloadStudentDisplays?: Array<CaseloadStudentDisplay>;
    /**
     * The student references that had issues when fetching from the database.
     * @type {Array<StudentRef>}
     * @memberof GetCaseloadStudentsResponse
     */
    failedStudentFetches?: Array<StudentRef>;
}
/**
 * 
 * @export
 * @interface GetClientsResponse
 */
export interface GetClientsResponse {
    /**
     * 
     * @type {Array<ClientProfile>}
     * @memberof GetClientsResponse
     */
    clients?: Array<ClientProfile>;
}
/**
 * 
 * @export
 * @interface GetDistrictsResponse
 */
export interface GetDistrictsResponse {
    /**
     * 
     * @type {Array<DistrictProfile>}
     * @memberof GetDistrictsResponse
     */
    districts?: Array<DistrictProfile>;
}
/**
 * 
 * @export
 * @interface GetDuplicateStudentProfilesForSingleStudentResponse
 */
export interface GetDuplicateStudentProfilesForSingleStudentResponse {
    /**
     * 
     * @type {Array<StudentMergeLineItemDisplay>}
     * @memberof GetDuplicateStudentProfilesForSingleStudentResponse
     */
    studentMergeLineItemDisplays?: Array<StudentMergeLineItemDisplay>;
}
/**
 * 
 * @export
 * @interface GetGoalAreasOfFocusResponse
 */
export interface GetGoalAreasOfFocusResponse {
    /**
     * 
     * @type {Array<GoalAreaOfFocus>}
     * @memberof GetGoalAreasOfFocusResponse
     */
    areasOfFocus?: Array<GoalAreaOfFocus>;
}
/**
 * This response returns the non-school dates for client/district/campuses
 * @export
 * @interface GetNonSchoolDatesResponse
 */
export interface GetNonSchoolDatesResponse {
    /**
     * List of Non school dates from the document.
     * @type {Array<NonSchoolDay>}
     * @memberof GetNonSchoolDatesResponse
     */
    nonSchoolDays?: Array<NonSchoolDay>;
}
/**
 * Response for list of prescribing providers
 * @export
 * @interface GetPrescribingProvidersResponse
 */
export interface GetPrescribingProvidersResponse {
    /**
     * 
     * @type {Array<PrescribingProviderRef>}
     * @memberof GetPrescribingProvidersResponse
     */
    prescribingProviders?: Array<PrescribingProviderRef>;
}
/**
 * 
 * @export
 * @interface GetProgressReportDatesResponse
 */
export interface GetProgressReportDatesResponse {
    /**
     * 
     * @type {Array<ProgressReportDate>}
     * @memberof GetProgressReportDatesResponse
     */
    progressReportDates?: Array<ProgressReportDate>;
}
/**
 * The response object for getting a provider caseload.
 * @export
 * @interface GetProviderCaseloadResponse
 */
export interface GetProviderCaseloadResponse {
    /**
     * 
     * @type {Array<ServiceProviderCaseloadOption>}
     * @memberof GetProviderCaseloadResponse
     */
    serviceProviderCaseloadOptions?: Array<ServiceProviderCaseloadOption>;
}
/**
 * Defines the respons for requesting a list of school campus dropdown options
 * @export
 * @interface GetSchoolCampusDropdownsResponse
 */
export interface GetSchoolCampusDropdownsResponse {
    /**
     * A list of references to school campuses.
     * @type {Array<SchoolCampusRef>}
     * @memberof GetSchoolCampusDropdownsResponse
     */
    schoolCampuses?: Array<SchoolCampusRef>;
}
/**
 * 
 * @export
 * @interface GetSchoolCampusLineItemsResponse
 */
export interface GetSchoolCampusLineItemsResponse {
    /**
     * 
     * @type {Array<CampusLineItemCard>}
     * @memberof GetSchoolCampusLineItemsResponse
     */
    lineItemCards?: Array<CampusLineItemCard>;
}
/**
 * 
 * @export
 * @interface GetServiceAreasResponse
 */
export interface GetServiceAreasResponse {
    /**
     * 
     * @type {Array<ServiceAreaRef>}
     * @memberof GetServiceAreasResponse
     */
    serviceAreas?: Array<ServiceAreaRef>;
}
/**
 * The response object for getting service providers.
 * @export
 * @interface GetServiceProvidersResponse
 */
export interface GetServiceProvidersResponse {
    /**
     * 
     * @type {Array<ServiceProviderRef>}
     * @memberof GetServiceProvidersResponse
     */
    serviceProviders?: Array<ServiceProviderRef>;
}
/**
 * The response model for the GetStaffDirectory endpoint.
 * @export
 * @interface GetStaffDirectoryResponse
 */
export interface GetStaffDirectoryResponse {
    /**
     * 
     * @type {number}
     * @memberof GetStaffDirectoryResponse
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStaffDirectoryResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStaffDirectoryResponse
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStaffDirectoryResponse
     */
    totalRecords?: number;
    /**
     * 
     * @type {Array<StaffDirectoryProfile>}
     * @memberof GetStaffDirectoryResponse
     */
    dataList?: Array<StaffDirectoryProfile>;
}
/**
 * 
 * @export
 * @interface GetStateSnapshotsResponse
 */
export interface GetStateSnapshotsResponse {
    /**
     * 
     * @type {Array<StateSnapshot>}
     * @memberof GetStateSnapshotsResponse
     */
    stateSnapshots?: Array<StateSnapshot>;
}
/**
 * 
 * @export
 * @interface GetStudentMergeDisplaysResponse
 */
export interface GetStudentMergeDisplaysResponse {
    /**
     * 
     * @type {Array<StudentMergeDisplay>}
     * @memberof GetStudentMergeDisplaysResponse
     */
    studentMergeDisplays?: Array<StudentMergeDisplay>;
    /**
     * 
     * @type {Array<StudentRef>}
     * @memberof GetStudentMergeDisplaysResponse
     */
    failedStudentFetches?: Array<StudentRef>;
}
/**
 * 
 * @export
 * @interface GetStudentsResponse
 */
export interface GetStudentsResponse {
    /**
     * 
     * @type {Array<StudentRef>}
     * @memberof GetStudentsResponse
     */
    students?: Array<StudentRef>;
}
/**
 * 
 * @export
 * @interface GetUsersResponse
 */
export interface GetUsersResponse {
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof GetUsersResponse
     */
    users?: Array<UserProfile>;
}
/**
 * 
 * @export
 * @interface Goal
 */
export interface Goal {
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    internalId?: string;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    legacyGoalId?: number;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    legacyStudentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof Goal
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Goal
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Goal
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Goal
     */
    deactivationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Goal
     */
    completionDate?: Date;
    /**
     * 
     * @type {GoalAreaOfFocusRef}
     * @memberof Goal
     */
    goalAreaOfFocus?: GoalAreaOfFocusRef;
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Goal
     */
    includeInReports?: boolean;
    /**
     * 
     * @type {Array<Objective>}
     * @memberof Goal
     */
    objectives?: Array<Objective>;
}
/**
 * This class is a reference to a service area, used to indicate the area of focus for a student's goal.
 * @export
 * @interface GoalAreaOfFocus
 */
export interface GoalAreaOfFocus {
    /**
     * 
     * @type {string}
     * @memberof GoalAreaOfFocus
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GoalAreaOfFocus
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GoalAreaOfFocus
     */
    legacyName?: string;
    /**
     * 
     * @type {string}
     * @memberof GoalAreaOfFocus
     */
    legacyId?: string;
    /**
     * 
     * @type {Array<ServiceAreaRef>}
     * @memberof GoalAreaOfFocus
     */
    serviceAreas?: Array<ServiceAreaRef>;
}
/**
 * 
 * @export
 * @interface GoalAreaOfFocusRef
 */
export interface GoalAreaOfFocusRef {
    /**
     * 
     * @type {string}
     * @memberof GoalAreaOfFocusRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GoalAreaOfFocusRef
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GoalAreaOfFocusRef
     */
    legacyName?: string;
    /**
     * 
     * @type {string}
     * @memberof GoalAreaOfFocusRef
     */
    legacyId?: string;
}
/**
 * 
 * @export
 * @interface GoalDisplay
 */
export interface GoalDisplay {
    /**
     * 
     * @type {string}
     * @memberof GoalDisplay
     */
    internalId?: string;
    /**
     * 
     * @type {string}
     * @memberof GoalDisplay
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof GoalDisplay
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof GoalDisplay
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GoalDisplay
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GoalDisplay
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GoalDisplay
     */
    deactivationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GoalDisplay
     */
    completionDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GoalDisplay
     */
    isActive?: boolean;
    /**
     * 
     * @type {GoalAreaOfFocusRef}
     * @memberof GoalDisplay
     */
    goalAreaOfFocus?: GoalAreaOfFocusRef;
    /**
     * 
     * @type {string}
     * @memberof GoalDisplay
     */
    status?: string;
    /**
     * 
     * @type {Array<Objective>}
     * @memberof GoalDisplay
     */
    objectives?: Array<Objective>;
}
/**
 * A response containing a list of goals.
 * @export
 * @interface GoalsResponse
 */
export interface GoalsResponse {
    /**
     * The list of goals.
     * @type {Array<Goal>}
     * @memberof GoalsResponse
     */
    goals?: Array<Goal>;
}
/**
 * Enum: PreK = 0, K = 1, First = 2, Second = 3, Third = 4, Fourth = 5, Fifth = 6, Sixth = 7, Seventh = 8, Eighth = 9, Ninth = 10, Tenth = 11, Eleventh = 12, Twelfth = 13, Other = 14
 * @export
 * @enum {string}
 */
export enum Grade {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7,
    NUMBER_8 = <any> 8,
    NUMBER_9 = <any> 9,
    NUMBER_10 = <any> 10,
    NUMBER_11 = <any> 11,
    NUMBER_12 = <any> 12,
    NUMBER_13 = <any> 13,
    NUMBER_14 = <any> 14
}
/**
 * This is a historical record of a user's status being changed.
 * @export
 * @interface HistoricalStatus
 */
export interface HistoricalStatus {
    /**
     * 
     * @type {DistrictRef}
     * @memberof HistoricalStatus
     */
    district?: DistrictRef;
    /**
     * 
     * @type {UserManagementStatus}
     * @memberof HistoricalStatus
     */
    status?: UserManagementStatus;
    /**
     * 
     * @type {Date}
     * @memberof HistoricalStatus
     */
    date?: Date;
}
/**
 * Enum: MustBeIncludedWith = 0, CannotBeIncludedWith = 1
 * @export
 * @enum {string}
 */
export enum InclusionConstraint {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
}
/**
 * 
 * @export
 * @interface MedicaidAgency
 */
export interface MedicaidAgency {
    /**
     * 
     * @type {string}
     * @memberof MedicaidAgency
     */
    name?: string;
    /**
     * 
     * @type {Address}
     * @memberof MedicaidAgency
     */
    address?: Address;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof MedicaidAgency
     */
    contacts?: Array<Contact>;
}
/**
 * 
 * @export
 * @interface MedicaidCredential
 */
export interface MedicaidCredential {
    /**
     * Also referred to as license type, this is the profession of the credential.
     * @type {string}
     * @memberof MedicaidCredential
     */
    profession?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicaidCredential
     */
    nameOnLicense?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicaidCredential
     */
    licenseNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof MedicaidCredential
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MedicaidCredential
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MedicaidCredential
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicaidCredential
     */
    state?: string;
}
/**
 * 
 * @export
 * @interface MedicaidPolicy
 */
export interface MedicaidPolicy {
    /**
     * 
     * @type {Array<ServiceArea>}
     * @memberof MedicaidPolicy
     */
    serviceAreaInventory?: Array<ServiceArea>;
    /**
     * 
     * @type {Array<ServiceType>}
     * @memberof MedicaidPolicy
     */
    serviceTypeInventory?: Array<ServiceType>;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof MedicaidPolicy
     */
    serviceProviderTypeInventory?: Array<ServiceProviderType>;
    /**
     * 
     * @type {Array<Service>}
     * @memberof MedicaidPolicy
     */
    serviceInventory?: Array<Service>;
    /**
     * 
     * @type {EdiInstructionManual}
     * @memberof MedicaidPolicy
     */
    ediInstructionManual?: EdiInstructionManual;
    /**
     * 
     * @type {Array<BillingCondition>}
     * @memberof MedicaidPolicy
     */
    generalBillingConditions?: Array<BillingCondition>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MergeStudentStatusMessage {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1
}
/**
 * Request to merge multiple student profiles into a single profile
 * @export
 * @interface MergeStudentsRequest
 */
export interface MergeStudentsRequest {
    /**
     * 
     * @type {string}
     * @memberof MergeStudentsRequest
     */
    primaryProfileId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MergeStudentsRequest
     */
    profileIdsToMerge?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MergeStudentsRequest
     */
    clientId?: string;
}
/**
 * 
 * @export
 * @interface MergedWith
 */
export interface MergedWith {
    /**
     * 
     * @type {StudentRef}
     * @memberof MergedWith
     */
    student?: StudentRef;
    /**
     * 
     * @type {Date}
     * @memberof MergedWith
     */
    date?: Date;
}
/**
 * 
 * @export
 * @interface MigrateStudentsToContainerPostRequest
 */
export interface MigrateStudentsToContainerPostRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MigrateStudentsToContainerPostRequest
     */
    clientIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MigrateStudentsToContainerPostRequest
     */
    maxStudentMigrationsPerClient?: number;
    /**
     * 
     * @type {number}
     * @memberof MigrateStudentsToContainerPostRequest
     */
    maxRetries?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MigrateStudentsToContainerPostRequest
     */
    migrateOnlyUnmigrated?: boolean;
}
/**
 * 
 * @export
 * @interface Modification
 */
export interface Modification {
    /**
     * 
     * @type {string}
     * @memberof Modification
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof Modification
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Modification
     */
    endDate?: Date;
}
/**
 * 
 * @export
 * @interface NonSchoolDay
 */
export interface NonSchoolDay {
    /**
     * 
     * @type {Date}
     * @memberof NonSchoolDay
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NonSchoolDay
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof NonSchoolDay
     */
    title?: string;
    /**
     * 
     * @type {NonSchoolDayType}
     * @memberof NonSchoolDay
     */
    type?: NonSchoolDayType;
}
/**
 * Enum:   MakeupDay = 0,   ChristmasBreak = 1,   InServiceDay = 2,   NonSchoolDay = 3,   ProfessionalDevelopmentDay = 4,   SnowDay = 5,   SpringBreak = 6,   ThanksgivingBreak = 7,   VacationDay = 8,   Other = 9  <remarks>  Must include the string names of the enum in order for swagger to generate valueable  documentation, since it does not allow for named integer enums.  </remarks>
 * @export
 * @enum {string}
 */
export enum NonSchoolDayType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7,
    NUMBER_8 = <any> 8,
    NUMBER_9 = <any> 9
}
/**
 * 
 * @export
 * @interface NotatorSection
 */
export interface NotatorSection {
    /**
     * 
     * @type {NotatorSectionName}
     * @memberof NotatorSection
     */
    sectionName?: NotatorSectionName;
    /**
     * 
     * @type {boolean}
     * @memberof NotatorSection
     */
    required?: boolean;
}
/**
 * 0 = Attendance  1 = Session Times  2 = Activities  3 = Accommodations  4 = Modifications  5 = Goals and Objectives  6 = Observations  7 = Aide Required  8 = Route
 * @export
 * @enum {string}
 */
export enum NotatorSectionName {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7,
    NUMBER_8 = <any> 8
}
/**
 * 
 * @export
 * @interface NotatorSectionResponse
 */
export interface NotatorSectionResponse {
    /**
     * 
     * @type {Array<NotatorSection>}
     * @memberof NotatorSectionResponse
     */
    notatorSections?: Array<NotatorSection>;
}
/**
 * 
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
    /**
     * 
     * @type {NotificationResponseType}
     * @memberof NotificationResponse
     */
    type?: NotificationResponseType;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof NotificationResponse
     */
    dateTime?: Date;
}
/**
 * Enum: Affirmative = 0, Negative = 1
 * @export
 * @enum {string}
 */
export enum NotificationResponseType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1
}
/**
 * Enum:       ProxyAccessRequest = 0,       SupervisorAccessRequest = 1,       DistrictAccessRequest = 2,       ProxyAccessResponse = 3,       SupervisorAccessResponse = 4,       DistrictAccessResponse = 5,      ServiceProviderNotification = 6,      ServiceProviderDistrictAssignmentsUpdate = 7
 * @export
 * @enum {string}
 */
export enum NotificationType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7
}
/**
 * 
 * @export
 * @interface Objective
 */
export interface Objective {
    /**
     * 
     * @type {string}
     * @memberof Objective
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Objective
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Objective
     */
    internalId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderByDirection {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1
}
/**
 * 
 * @export
 * @interface OrderedSubject
 */
export interface OrderedSubject {
    /**
     * 
     * @type {string}
     * @memberof OrderedSubject
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderedSubject
     */
    name?: string;
    /**
     * 
     * @type {ServiceAreaRef}
     * @memberof OrderedSubject
     */
    serviceArea?: ServiceAreaRef;
}
/**
 * Enum  NewParticipant = 0,  UpdatedParticipant = 1,  CurrentParticipant = 2,  RemoveParticipant = 3,  ParticipantRemoved = 4
 * @export
 * @enum {string}
 */
export enum ParticipationListStatusType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4
}
/**
 * 
 * @export
 * @interface PastAudit
 */
export interface PastAudit {
    /**
     * 
     * @type {string}
     * @memberof PastAudit
     */
    auditAgency?: string;
    /**
     * 
     * @type {Date}
     * @memberof PastAudit
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof PastAudit
     */
    auditDocumentUrl?: string;
}
/**
 * 
 * @export
 * @interface PatchAuthorizedDistrictsRequest
 */
export interface PatchAuthorizedDistrictsRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchAuthorizedDistrictsRequest
     */
    userIdPerformingUpdate: string;
    /**
     * 
     * @type {Array<DistrictRef>}
     * @memberof PatchAuthorizedDistrictsRequest
     */
    districts: Array<DistrictRef>;
}
/**
 * 
 * @export
 * @interface PatchClientAssignmentRequest
 */
export interface PatchClientAssignmentRequest {
    /**
     * 
     * @type {Array<ServiceProviderRef>}
     * @memberof PatchClientAssignmentRequest
     */
    supervisedServiceProviders?: Array<ServiceProviderRef>;
    /**
     * 
     * @type {Array<AppointingServiceProviderRef>}
     * @memberof PatchClientAssignmentRequest
     */
    appointingServiceProviders?: Array<AppointingServiceProviderRef>;
    /**
     * 
     * @type {SelectedCalendarFilters}
     * @memberof PatchClientAssignmentRequest
     */
    calendarFilters?: SelectedCalendarFilters;
    /**
     * 
     * @type {ClientAssignmentStatus}
     * @memberof PatchClientAssignmentRequest
     */
    status?: ClientAssignmentStatus;
}
/**
 * This request used to patch the progress report dates for a client.
 * @export
 * @interface PatchClientProgressReportDateRequest
 */
export interface PatchClientProgressReportDateRequest {
    /**
     * The client Id we are targeting
     * @type {string}
     * @memberof PatchClientProgressReportDateRequest
     */
    clientId?: string;
    /**
     * The progress report date blocks
     * @type {Array<ProgressReportDate>}
     * @memberof PatchClientProgressReportDateRequest
     */
    progressReportDates?: Array<ProgressReportDate>;
}
/**
 * This request can be used for the create, update, delete of progress report dates in a district or a campus.
 * @export
 * @interface PatchDistrictClientProgressReportDateRequest
 */
export interface PatchDistrictClientProgressReportDateRequest {
    /**
     * The district id.
     * @type {string}
     * @memberof PatchDistrictClientProgressReportDateRequest
     */
    districtId?: string;
    /**
     * The campus id. If this is optional then it will update the district progress report.
     * @type {string}
     * @memberof PatchDistrictClientProgressReportDateRequest
     */
    campusId?: string;
    /**
     * The list of progress report dates.
     * @type {Array<ProgressReportDate>}
     * @memberof PatchDistrictClientProgressReportDateRequest
     */
    progressReportDates?: Array<ProgressReportDate>;
}
/**
 * This is the request body for modifying an electronic signature.
 * @export
 * @interface PatchElectronicSignatureRequest
 */
export interface PatchElectronicSignatureRequest {
    /**
     * A boolean value indicating if the user intends to sign the statement. When set to false, the user is retracting              their signature.
     * @type {boolean}
     * @memberof PatchElectronicSignatureRequest
     */
    isSigned?: boolean;
    /**
     * The typed full name of the individual wishing to sign the statement.
     * @type {string}
     * @memberof PatchElectronicSignatureRequest
     */
    signedByFullName?: string;
    /**
     * The object id of the user from the authentication provider.
     * @type {string}
     * @memberof PatchElectronicSignatureRequest
     */
    objectId?: string;
    /**
     * The complete, unaltered text of the agreement, as visible to the user at the time of signing.
     * @type {string}
     * @memberof PatchElectronicSignatureRequest
     */
    documentText?: string;
    /**
     * The date and time the user signed the agreement, in the user's local time zone.
     * @type {Date}
     * @memberof PatchElectronicSignatureRequest
     */
    signedOnDateLocal?: Date;
    /**
     * The IpAddress of the original request.
     * @type {string}
     * @memberof PatchElectronicSignatureRequest
     */
    requestIpAddress?: string;
}
/**
 * This is request we recieve for prescribing provider patching
 * @export
 * @interface PatchPrescribingProviderRequest
 */
export interface PatchPrescribingProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchPrescribingProviderRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchPrescribingProviderRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchPrescribingProviderRequest
     */
    npi?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchPrescribingProviderRequest
     */
    stateMedicaidNumber?: string;
    /**
     * 
     * @type {ClientTermRef}
     * @memberof PatchPrescribingProviderRequest
     */
    client?: ClientTermRef;
}
/**
 * The request object to patch a service provider
 * @export
 * @interface PatchServiceProviderRequest
 */
export interface PatchServiceProviderRequest {
    /**
     * 
     * @type {ServiceProviderProfile}
     * @memberof PatchServiceProviderRequest
     */
    profile?: ServiceProviderProfile;
    /**
     * 
     * @type {Array<SchoolCampusRef>}
     * @memberof PatchServiceProviderRequest
     */
    schoolCampuses?: Array<SchoolCampusRef>;
}
/**
 * The request object for the PATCH /api/v1/Students/{id} endpoint. All null fields will be ignored.
 * @export
 * @interface PatchStudentRequest
 */
export interface PatchStudentRequest {
    /**
     * 
     * @type {ClientTermRef}
     * @memberof PatchStudentRequest
     */
    client?: ClientTermRef;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof PatchStudentRequest
     */
    schoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {string}
     * @memberof PatchStudentRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchStudentRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchStudentRequest
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchStudentRequest
     */
    preferredName?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchStudentRequest
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {Grade}
     * @memberof PatchStudentRequest
     */
    grade?: Grade;
    /**
     * 
     * @type {Gender}
     * @memberof PatchStudentRequest
     */
    gender?: Gender;
    /**
     * 
     * @type {string}
     * @memberof PatchStudentRequest
     */
    primaryLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchStudentRequest
     */
    socialSecurityNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchStudentRequest
     */
    medicaidId?: string;
    /**
     * 
     * @type {Array<DistrictOfLiability>}
     * @memberof PatchStudentRequest
     */
    districtOfLiabilityRecords?: Array<DistrictOfLiability>;
    /**
     * 
     * @type {Array<DistrictProvidingServices>}
     * @memberof PatchStudentRequest
     */
    districtProvidingServicesRecords?: Array<DistrictProvidingServices>;
    /**
     * 
     * @type {string}
     * @memberof PatchStudentRequest
     */
    studentIdGivenByState?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchStudentRequest
     */
    studentIdGivenBySchoolDistrict?: string;
    /**
     * 
     * @type {StudentXLogsStatus}
     * @memberof PatchStudentRequest
     */
    xLogsStatus?: StudentXLogsStatus;
    /**
     * 
     * @type {Address}
     * @memberof PatchStudentRequest
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {SpedDossier}
     * @memberof PatchStudentRequest
     */
    spedDossier?: SpedDossier;
    /**
     * 
     * @type {MergedWith}
     * @memberof PatchStudentRequest
     */
    mergedWith?: MergedWith;
}
/**
 * 
 * @export
 * @interface PatchUserRequest
 */
export interface PatchUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchUserRequest
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    isSuperAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    isMsbAdmin?: boolean;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof PatchUserRequest
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    loggedInClientId?: string;
    /**
     * 
     * @type {Array<ClientAssignment>}
     * @memberof PatchUserRequest
     */
    clientAssignments?: Array<ClientAssignment>;
}
/**
 * 
 * @export
 * @interface PlanOfCare
 */
export interface PlanOfCare {
    /**
     * 
     * @type {PlanOfCareType}
     * @memberof PlanOfCare
     */
    type?: PlanOfCareType;
    /**
     * This is the description given to \"Other\" types of plans of care.
     * @type {string}
     * @memberof PlanOfCare
     */
    otherDescription?: string;
    /**
     * 
     * @type {Date}
     * @memberof PlanOfCare
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlanOfCare
     */
    endDate?: Date;
}
/**
 * Enum: Iep = 0, Referral = 1, _504 = 2, RtiMtss = 3, Ell = 4, Other = 5
 * @export
 * @enum {string}
 */
export enum PlanOfCareType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5
}
/**
 * 
 * @export
 * @interface PostAccessRequest
 */
export interface PostAccessRequest {
    /**
     * 
     * @type {UserRef}
     * @memberof PostAccessRequest
     */
    requestingUser?: UserRef;
    /**
     * 
     * @type {AppointingServiceProviderRef}
     * @memberof PostAccessRequest
     */
    requestedServiceProvider?: AppointingServiceProviderRef;
}
/**
 * 
 * @export
 * @interface PrescribedCareProvisionsLedger
 */
export interface PrescribedCareProvisionsLedger {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof PrescribedCareProvisionsLedger
     */
    activities?: Array<Activity>;
    /**
     * 
     * @type {Array<Accommodation>}
     * @memberof PrescribedCareProvisionsLedger
     */
    accommodations?: Array<Accommodation>;
    /**
     * 
     * @type {Array<Modification>}
     * @memberof PrescribedCareProvisionsLedger
     */
    modifications?: Array<Modification>;
    /**
     * 
     * @type {Array<Goal>}
     * @memberof PrescribedCareProvisionsLedger
     */
    goals?: Array<Goal>;
}
/**
 * The request object for the GET /api/v1/Students/{id}/PrescribedCareProvisions/ByDate endpoint. All null fields will be ignored.
 * @export
 * @interface PrescribedCareProvisionsResponse
 */
export interface PrescribedCareProvisionsResponse {
    /**
     * The activities that falls within the parameters.
     * @type {Array<Activity>}
     * @memberof PrescribedCareProvisionsResponse
     */
    activities?: Array<Activity>;
    /**
     * The modifications that falls within the parameters.
     * @type {Array<Modification>}
     * @memberof PrescribedCareProvisionsResponse
     */
    modifications?: Array<Modification>;
    /**
     * The accommodations that falls within the  parameters.
     * @type {Array<Accommodation>}
     * @memberof PrescribedCareProvisionsResponse
     */
    accommodations?: Array<Accommodation>;
}
/**
 * 
 * @export
 * @interface PrescribedServiceArea
 */
export interface PrescribedServiceArea {
    /**
     * 
     * @type {PrescriptionDecision}
     * @memberof PrescribedServiceArea
     */
    decision?: PrescriptionDecision;
    /**
     * 
     * @type {ServiceAreaRef}
     * @memberof PrescribedServiceArea
     */
    serviceArea?: ServiceAreaRef;
    /**
     * 
     * @type {PrescribingProvider}
     * @memberof PrescribedServiceArea
     */
    prescribingProvider?: PrescribingProvider;
    /**
     * 
     * @type {Date}
     * @memberof PrescribedServiceArea
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PrescribedServiceArea
     */
    endDate?: Date;
}
/**
 * 
 * @export
 * @interface PrescribingProvider
 */
export interface PrescribingProvider {
    /**
     * 
     * @type {string}
     * @memberof PrescribingProvider
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProvider
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProvider
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProvider
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProvider
     */
    npi?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProvider
     */
    stateMedicaidNumber?: string;
}
/**
 * 
 * @export
 * @interface PrescribingProviderRef
 */
export interface PrescribingProviderRef {
    /**
     * 
     * @type {string}
     * @memberof PrescribingProviderRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProviderRef
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProviderRef
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProviderRef
     */
    npi?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProviderRef
     */
    stateMedicaidNumber?: string;
}
/**
 * This is response after adding and updating prescribing provider.
 * @export
 * @interface PrescribingProviderResponse
 */
export interface PrescribingProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof PrescribingProviderResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProviderResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProviderResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProviderResponse
     */
    npi?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescribingProviderResponse
     */
    stateMedicaidNumber?: string;
}
/**
 * Enum: Yes = 0, No = 1, NoneSelected = 2
 * @export
 * @enum {string}
 */
export enum PrescriptionDecision {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

}
/**
 * 
 * @export
 * @interface Procedure
 */
export interface Procedure {
    /**
     * 
     * @type {string}
     * @memberof Procedure
     */
    description?: string;
    /**
     * 
     * @type {ProcedureClassification}
     * @memberof Procedure
     */
    classification?: ProcedureClassification;
    /**
     * 
     * @type {Array<BillingConstraint>}
     * @memberof Procedure
     */
    billingConstraints?: Array<BillingConstraint>;
    /**
     * 
     * @type {AppraisalMethodology}
     * @memberof Procedure
     */
    appraisalMethodology?: AppraisalMethodology;
    /**
     * 
     * @type {Array<ClassificationCriterion>}
     * @memberof Procedure
     */
    classificationCriteria?: Array<ClassificationCriterion>;
}
/**
 * 
 * @export
 * @interface ProcedureClassification
 */
export interface ProcedureClassification {
    /**
     * 
     * @type {string}
     * @memberof ProcedureClassification
     */
    cptCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcedureClassification
     */
    modifiers?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProcedureRef
 */
export interface ProcedureRef {
    /**
     * 
     * @type {ProcedureClassification}
     * @memberof ProcedureRef
     */
    classification?: ProcedureClassification;
    /**
     * 
     * @type {string}
     * @memberof ProcedureRef
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface ProductConfiguration
 */
export interface ProductConfiguration {
    /**
     * 
     * @type {boolean}
     * @memberof ProductConfiguration
     */
    campusRestrictions?: boolean;
}
/**
 * 
 * @export
 * @interface ProgressReportDate
 */
export interface ProgressReportDate {
    /**
     * 
     * @type {string}
     * @memberof ProgressReportDate
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProgressReportDate
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface ProviderCaseload
 */
export interface ProviderCaseload {
    /**
     * 
     * @type {string}
     * @memberof ProviderCaseload
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderCaseload
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderCaseload
     */
    campus?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderCaseload
     */
    providerRole?: string;
}
/**
 * 
 * @export
 * @interface ProviderRate
 */
export interface ProviderRate {
    /**
     * 
     * @type {number}
     * @memberof ProviderRate
     */
    hourlyRate?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProviderRate
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProviderRate
     */
    endDate?: Date;
}
/**
 * 
 * @export
 * @interface ProxyPostAccessRequest
 */
export interface ProxyPostAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof ProxyPostAccessRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPostAccessRequest
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProxyPostAccessRequest
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProxyPostAccessRequest
     */
    read?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProxyPostAccessRequest
     */
    partitionKey?: string;
    /**
     * 
     * @type {AppointingServiceProviderRef}
     * @memberof ProxyPostAccessRequest
     */
    notifiedServiceProvider?: AppointingServiceProviderRef;
    /**
     * 
     * @type {NotificationType}
     * @memberof ProxyPostAccessRequest
     */
    type?: NotificationType;
    /**
     * 
     * @type {UserRef}
     * @memberof ProxyPostAccessRequest
     */
    requestingUser?: UserRef;
    /**
     * 
     * @type {Date}
     * @memberof ProxyPostAccessRequest
     */
    requestDate?: Date;
    /**
     * 
     * @type {NotificationResponse}
     * @memberof ProxyPostAccessRequest
     */
    response?: NotificationResponse;
}
/**
 * 
 * @export
 * @interface ProxyPostAccessResponse
 */
export interface ProxyPostAccessResponse {
    /**
     * 
     * @type {string}
     * @memberof ProxyPostAccessResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyPostAccessResponse
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProxyPostAccessResponse
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProxyPostAccessResponse
     */
    read?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProxyPostAccessResponse
     */
    partitionKey?: string;
    /**
     * 
     * @type {UserRef}
     * @memberof ProxyPostAccessResponse
     */
    notifiedUser?: UserRef;
    /**
     * 
     * @type {NotificationType}
     * @memberof ProxyPostAccessResponse
     */
    type?: NotificationType;
    /**
     * 
     * @type {AppointingServiceProviderRef}
     * @memberof ProxyPostAccessResponse
     */
    respondedServiceProvider?: AppointingServiceProviderRef;
    /**
     * 
     * @type {NotificationResponse}
     * @memberof ProxyPostAccessResponse
     */
    response?: NotificationResponse;
}
/**
 * 
 * @export
 * @interface RemoveCampusRequest
 */
export interface RemoveCampusRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveCampusRequest
     */
    campusIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface RequestAccessToClientRequest
 */
export interface RequestAccessToClientRequest {
    /**
     * 
     * @type {UserServiceProviderRef}
     * @memberof RequestAccessToClientRequest
     */
    requestingUser?: UserServiceProviderRef;
    /**
     * 
     * @type {ClientRef}
     * @memberof RequestAccessToClientRequest
     */
    requestedClient?: ClientRef;
    /**
     * 
     * @type {boolean}
     * @memberof RequestAccessToClientRequest
     */
    isExecutiveAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestAccessToClientRequest
     */
    isDelegatedAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestAccessToClientRequest
     */
    isApprover?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestAccessToClientRequest
     */
    isProxyDataEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestAccessToClientRequest
     */
    isAutonomous?: boolean;
}
/**
 * 
 * @export
 * @interface RequestedDistrictAssignment
 */
export interface RequestedDistrictAssignment {
    /**
     * 
     * @type {DistrictRef}
     * @memberof RequestedDistrictAssignment
     */
    district?: DistrictRef;
    /**
     * 
     * @type {string}
     * @memberof RequestedDistrictAssignment
     */
    clientId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequestedDistrictAssignment
     */
    isExecutiveAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestedDistrictAssignment
     */
    isDelegatedAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestedDistrictAssignment
     */
    isApprover?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestedDistrictAssignment
     */
    isProxyDataEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestedDistrictAssignment
     */
    isAutonomous?: boolean;
}
/**
 * This is the request body for responding to multiple district access requests.
 * @export
 * @interface RespondToManyRequestsForDistrictAccessRequest
 */
export interface RespondToManyRequestsForDistrictAccessRequest {
    /**
     * A list of items adhering to the request contract for               responding to a single district access request.
     * @type {Array<RespondToRequestForDistrictAccessRequest>}
     * @memberof RespondToManyRequestsForDistrictAccessRequest
     */
    responsesToRequestsForDistrictAccess?: Array<RespondToRequestForDistrictAccessRequest>;
}
/**
 * 
 * @export
 * @interface RespondToRequestForAccessToPost
 */
export interface RespondToRequestForAccessToPost {
    /**
     * 
     * @type {string}
     * @memberof RespondToRequestForAccessToPost
     */
    notificationId?: string;
    /**
     * 
     * @type {string}
     * @memberof RespondToRequestForAccessToPost
     */
    requestedUserId?: string;
    /**
     * 
     * @type {AppointingServiceProviderRef}
     * @memberof RespondToRequestForAccessToPost
     */
    respondingUser?: AppointingServiceProviderRef;
    /**
     * 
     * @type {NotificationResponseType}
     * @memberof RespondToRequestForAccessToPost
     */
    responseType?: NotificationResponseType;
    /**
     * 
     * @type {string}
     * @memberof RespondToRequestForAccessToPost
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface RespondToRequestForDistrictAccessRequest
 */
export interface RespondToRequestForDistrictAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof RespondToRequestForDistrictAccessRequest
     */
    notificationId?: string;
    /**
     * 
     * @type {string}
     * @memberof RespondToRequestForDistrictAccessRequest
     */
    districtId?: string;
    /**
     * 
     * @type {string}
     * @memberof RespondToRequestForDistrictAccessRequest
     */
    clientId?: string;
    /**
     * 
     * @type {UserRef}
     * @memberof RespondToRequestForDistrictAccessRequest
     */
    respondingUser?: UserRef;
    /**
     * 
     * @type {NotificationResponseType}
     * @memberof RespondToRequestForDistrictAccessRequest
     */
    responseType?: NotificationResponseType;
    /**
     * 
     * @type {RoleAssignments}
     * @memberof RespondToRequestForDistrictAccessRequest
     */
    roleAssignments?: RoleAssignments;
    /**
     * A message to be sent with the response to the request for district access.
     * @type {string}
     * @memberof RespondToRequestForDistrictAccessRequest
     */
    message?: string;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof RespondToRequestForDistrictAccessRequest
     */
    serviceProviderTypesAssignments?: Array<ServiceProviderType>;
}
/**
 * A ledger of all the roles assigned to the user in a district.
 * @export
 * @interface RoleAssignments
 */
export interface RoleAssignments {
    /**
     * 
     * @type {boolean}
     * @memberof RoleAssignments
     */
    isExecutiveAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleAssignments
     */
    isDelegatedAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleAssignments
     */
    isApprover?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleAssignments
     */
    isProxyDataEntry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleAssignments
     */
    isAutonomous?: boolean;
    /**
     * 
     * @type {XLogsRole}
     * @memberof RoleAssignments
     */
    xLogsRole?: XLogsRole;
}
/**
 * 
 * @export
 * @interface ScheduleOfOrderedSubject
 */
export interface ScheduleOfOrderedSubject {
    /**
     * 
     * @type {OrderedSubject}
     * @memberof ScheduleOfOrderedSubject
     */
    orderedSubject?: OrderedSubject;
    /**
     * Specifies how long each instance of this occurrence should be in minutes.
     * @type {number}
     * @memberof ScheduleOfOrderedSubject
     */
    durationOfOccurrences?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleOfOrderedSubject
     */
    numberOfOccurrences?: number;
    /**
     * 
     * @type {ScheduleOfServicesInterval}
     * @memberof ScheduleOfOrderedSubject
     */
    interval?: ScheduleOfServicesInterval;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleOfOrderedSubject
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleOfOrderedSubject
     */
    endDate?: Date;
}
/**
 * 
 * @export
 * @interface ScheduleOfService
 */
export interface ScheduleOfService {
    /**
     * 
     * @type {string}
     * @memberof ScheduleOfService
     */
    subject?: string;
    /**
     * 
     * @type {ServiceAreaRef}
     * @memberof ScheduleOfService
     */
    serviceArea?: ServiceAreaRef;
    /**
     * 
     * @type {ScheduleOfServicesInterval}
     * @memberof ScheduleOfService
     */
    interval?: ScheduleOfServicesInterval;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleOfService
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleOfService
     */
    endDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ScheduleOfService
     */
    durationOfOccurances?: number;
}
/**
 * Enum: Daily = 0, Weekly = 1, BiWeekly = 2, Monthly = 3, BiMonthly = 4
 * @export
 * @enum {string}
 */
export enum ScheduleOfServicesInterval {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5
}
/**
 * 
 * @export
 * @interface SchoolCampus
 */
export interface SchoolCampus {
    /**
     * 
     * @type {string}
     * @memberof SchoolCampus
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolCampus
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolCampus
     */
    stateId?: string;
    /**
     * 
     * @type {number}
     * @memberof SchoolCampus
     */
    legacyId?: number;
    /**
     * 
     * @type {Address}
     * @memberof SchoolCampus
     */
    address?: Address;
    /**
     * 
     * @type {CampusContact}
     * @memberof SchoolCampus
     */
    campusContact?: CampusContact;
    /**
     * 
     * @type {Array<ProgressReportDate>}
     * @memberof SchoolCampus
     */
    progressReportDates?: Array<ProgressReportDate>;
}
/**
 * 
 * @export
 * @interface SchoolCampusAssignment
 */
export interface SchoolCampusAssignment {
    /**
     * 
     * @type {string}
     * @memberof SchoolCampusAssignment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolCampusAssignment
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof SchoolCampusAssignment
     */
    attendanceStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SchoolCampusAssignment
     */
    attendanceEndDate?: Date;
}
/**
 * The request object for assigning a school campus to a provider.
 * @export
 * @interface SchoolCampusAssignmentRequest
 */
export interface SchoolCampusAssignmentRequest {
    /**
     * 
     * @type {string}
     * @memberof SchoolCampusAssignmentRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolCampusAssignmentRequest
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof SchoolCampusAssignmentRequest
     */
    attendanceStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SchoolCampusAssignmentRequest
     */
    attendanceEndDate?: Date;
}
/**
 * 
 * @export
 * @interface SchoolCampusRef
 */
export interface SchoolCampusRef {
    /**
     * 
     * @type {string}
     * @memberof SchoolCampusRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolCampusRef
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface SchoolCampusResponse
 */
export interface SchoolCampusResponse {
    /**
     * 
     * @type {string}
     * @memberof SchoolCampusResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolCampusResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolCampusResponse
     */
    stateId?: string;
    /**
     * 
     * @type {number}
     * @memberof SchoolCampusResponse
     */
    legacyId?: number;
    /**
     * 
     * @type {Address}
     * @memberof SchoolCampusResponse
     */
    address?: Address;
    /**
     * 
     * @type {CampusContact}
     * @memberof SchoolCampusResponse
     */
    campusContact?: CampusContact;
    /**
     * 
     * @type {Array<ProgressReportDate>}
     * @memberof SchoolCampusResponse
     */
    progressReportDates?: Array<ProgressReportDate>;
}
/**
 * 
 * @export
 * @interface SelectedCalendarFilters
 */
export interface SelectedCalendarFilters {
    /**
     * 
     * @type {boolean}
     * @memberof SelectedCalendarFilters
     */
    selfIsSelected?: boolean;
    /**
     * 
     * @type {Array<ServiceProviderRef>}
     * @memberof SelectedCalendarFilters
     */
    serviceProviders?: Array<ServiceProviderRef>;
    /**
     * 
     * @type {Array<ServiceAreaRef>}
     * @memberof SelectedCalendarFilters
     */
    serviceAreas?: Array<ServiceAreaRef>;
    /**
     * 
     * @type {Array<StudentRef>}
     * @memberof SelectedCalendarFilters
     */
    students?: Array<StudentRef>;
}
/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    description?: string;
    /**
     * 
     * @type {ServiceAreaRef}
     * @memberof Service
     */
    area?: ServiceAreaRef;
    /**
     * 
     * @type {ServiceTypeRef}
     * @memberof Service
     */
    serviceType?: ServiceTypeRef;
    /**
     * 
     * @type {Array<Procedure>}
     * @memberof Service
     */
    procedures?: Array<Procedure>;
    /**
     * 
     * @type {ConsolidationRange}
     * @memberof Service
     */
    consolidationRange?: ConsolidationRange;
    /**
     * 
     * @type {Array<BillingCondition>}
     * @memberof Service
     */
    billingConditions?: Array<BillingCondition>;
    /**
     * 
     * @type {Array<AdditionalDocumentationItem>}
     * @memberof Service
     */
    additionalDocumentationItems?: Array<AdditionalDocumentationItem>;
    /**
     * 
     * @type {Array<NotatorSection>}
     * @memberof Service
     */
    notatorSections?: Array<NotatorSection>;
}
/**
 * 
 * @export
 * @interface ServiceArea
 */
export interface ServiceArea {
    /**
     * 
     * @type {string}
     * @memberof ServiceArea
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceArea
     */
    legacyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceArea
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceArea
     */
    idealBillingPercent?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceArea
     */
    appearsInMedicaidInfoTab?: boolean;
    /**
     * 
     * @type {Array<NotatorSection>}
     * @memberof ServiceArea
     */
    defaultNotatorSections?: Array<NotatorSection>;
}
/**
 * 
 * @export
 * @interface ServiceAreaRef
 */
export interface ServiceAreaRef {
    /**
     * 
     * @type {string}
     * @memberof ServiceAreaRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAreaRef
     */
    name?: string;
}
/**
 * This classs defines a \"Category\" of services, which is a combination of a ServiceArea and a ServiceType.
 * @export
 * @interface ServiceCategory
 */
export interface ServiceCategory {
    /**
     * 
     * @type {ServiceAreaRef}
     * @memberof ServiceCategory
     */
    serviceArea?: ServiceAreaRef;
    /**
     * 
     * @type {ServiceTypeRef}
     * @memberof ServiceCategory
     */
    serviceType?: ServiceTypeRef;
}
/**
 * 
 * @export
 * @interface ServiceCategoryConfig
 */
export interface ServiceCategoryConfig {
    /**
     * 
     * @type {ServiceCategory}
     * @memberof ServiceCategoryConfig
     */
    serviceCategory?: ServiceCategory;
    /**
     * 
     * @type {Array<ActivityInventoryItem>}
     * @memberof ServiceCategoryConfig
     */
    defaultActivities?: Array<ActivityInventoryItem>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceCategoryConfig
     */
    defaultAccommodations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceCategoryConfig
     */
    defaultModifications?: Array<string>;
    /**
     * 
     * @type {Array<NotatorSection>}
     * @memberof ServiceCategoryConfig
     */
    defaultNotatorSections?: Array<NotatorSection>;
}
/**
 * 
 * @export
 * @interface ServiceProviderCaseloadOption
 */
export interface ServiceProviderCaseloadOption {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderCaseloadOption
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderCaseloadOption
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderCaseloadOption
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderCaseloadOption
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderCaseloadOption
     */
    campus?: string;
}
/**
 * 
 * @export
 * @interface ServiceProviderProfile
 */
export interface ServiceProviderProfile {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    middleName?: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceProviderProfile
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    rtmsEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    notificationEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    employeeId?: string;
    /**
     * 
     * @type {DocumentationType}
     * @memberof ServiceProviderProfile
     */
    documentationType?: DocumentationType;
    /**
     * 
     * @type {ClassType}
     * @memberof ServiceProviderProfile
     */
    classType?: ClassType;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    phoneNumber?: string;
    /**
     * 
     * @type {ClientTermRef}
     * @memberof ServiceProviderProfile
     */
    client?: ClientTermRef;
    /**
     * 
     * @type {Array<DistrictRef>}
     * @memberof ServiceProviderProfile
     */
    districtsOfOperation?: Array<DistrictRef>;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof ServiceProviderProfile
     */
    assignedSchoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof ServiceProviderProfile
     */
    activeSchoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    jobTitle?: string;
    /**
     * 
     * @type {EmployeeType}
     * @memberof ServiceProviderProfile
     */
    employeeType?: EmployeeType;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProviderProfile
     */
    isOnParticipationList?: boolean;
    /**
     * 
     * @type {ParticipationListStatusType}
     * @memberof ServiceProviderProfile
     */
    participationListStatus?: ParticipationListStatusType;
    /**
     * 
     * @type {Array<MedicaidCredential>}
     * @memberof ServiceProviderProfile
     */
    medicaidCredentials?: Array<MedicaidCredential>;
    /**
     * 
     * @type {Array<ProviderRate>}
     * @memberof ServiceProviderProfile
     */
    rateRecords?: Array<ProviderRate>;
    /**
     * A list of student references representig the students currently assigned to this service provider's caseload.
     * @type {Array<StudentRef>}
     * @memberof ServiceProviderProfile
     */
    studentCaseload?: Array<StudentRef>;
    /**
     * 
     * @type {Array<StudentAssignment>}
     * @memberof ServiceProviderProfile
     */
    studentCaseloadAssignments?: Array<StudentAssignment>;
    /**
     * 
     * @type {ServiceProviderType}
     * @memberof ServiceProviderProfile
     */
    serviceProviderType?: ServiceProviderType;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof ServiceProviderProfile
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof ServiceProviderProfile
     */
    legacyServiceProviderTypes?: Array<ServiceProviderType>;
    /**
     * 
     * @type {Array<FederalFunding>}
     * @memberof ServiceProviderProfile
     */
    federalFundingRecords?: Array<FederalFunding>;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    npi?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    stateMedicaidNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProviderProfile
     */
    blockBillingForProvider?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ServiceProviderProfile
     */
    blockBillingStartDate?: Date;
    /**
     * 
     * @type {Array<BlockBillingHistory>}
     * @memberof ServiceProviderProfile
     */
    blockBillingHistory?: Array<BlockBillingHistory>;
    /**
     * 
     * @type {Array<TrainingInformation>}
     * @memberof ServiceProviderProfile
     */
    trainingInformationLog?: Array<TrainingInformation>;
    /**
     * 
     * @type {ClientAssignmentStatus}
     * @memberof ServiceProviderProfile
     */
    clientAssignmentStatus?: ClientAssignmentStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProviderProfile
     */
    hasAccess?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProviderProfile
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    legacyUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderProfile
     */
    fullNameEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProviderProfile
     */
    districtStatus?: boolean;
}
/**
 * 
 * @export
 * @interface ServiceProviderRef
 */
export interface ServiceProviderRef {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderRef
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderRef
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderRef
     */
    email?: string;
}
/**
 * The response object for returning a service provider.
 * @export
 * @interface ServiceProviderResponse
 */
export interface ServiceProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    middleName?: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceProviderResponse
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    rtmsEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    notificationEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    employeeId?: string;
    /**
     * 
     * @type {DocumentationType}
     * @memberof ServiceProviderResponse
     */
    documentationType?: DocumentationType;
    /**
     * 
     * @type {ClassType}
     * @memberof ServiceProviderResponse
     */
    classType?: ClassType;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {ClientTermRef}
     * @memberof ServiceProviderResponse
     */
    client?: ClientTermRef;
    /**
     * 
     * @type {Array<DistrictRef>}
     * @memberof ServiceProviderResponse
     */
    districtsOfOperation?: Array<DistrictRef>;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof ServiceProviderResponse
     */
    assignedSchoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof ServiceProviderResponse
     */
    activeSchoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    jobTitle?: string;
    /**
     * 
     * @type {EmployeeType}
     * @memberof ServiceProviderResponse
     */
    employeeType?: EmployeeType;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProviderResponse
     */
    isOnParticipationList?: boolean;
    /**
     * 
     * @type {ParticipationListStatusType}
     * @memberof ServiceProviderResponse
     */
    participationListStatus?: ParticipationListStatusType;
    /**
     * 
     * @type {Array<MedicaidCredential>}
     * @memberof ServiceProviderResponse
     */
    medicaidCredentials?: Array<MedicaidCredential>;
    /**
     * 
     * @type {Array<ProviderRate>}
     * @memberof ServiceProviderResponse
     */
    rateRecords?: Array<ProviderRate>;
    /**
     * 
     * @type {Array<StudentRef>}
     * @memberof ServiceProviderResponse
     */
    studentCaseload?: Array<StudentRef>;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof ServiceProviderResponse
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
    /**
     * 
     * @type {Array<FederalFunding>}
     * @memberof ServiceProviderResponse
     */
    federalFundingRecordsS?: Array<FederalFunding>;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    npi?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderResponse
     */
    stateMedicaidNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProviderResponse
     */
    blockBillingForProvider?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ServiceProviderResponse
     */
    blockBillingStartDate?: Date;
    /**
     * 
     * @type {Array<BlockBillingHistory>}
     * @memberof ServiceProviderResponse
     */
    blockBillingHistory?: Array<BlockBillingHistory>;
    /**
     * 
     * @type {Array<TrainingInformation>}
     * @memberof ServiceProviderResponse
     */
    trainingInformationLog?: Array<TrainingInformation>;
    /**
     * 
     * @type {ClientAssignmentStatus}
     * @memberof ServiceProviderResponse
     */
    clientAssignmentStatus?: ClientAssignmentStatus;
}
/**
 * 
 * @export
 * @interface ServiceProviderType
 */
export interface ServiceProviderType {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderType
     */
    legacyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderType
     */
    name?: string;
    /**
     * 
     * @type {ServiceAreaRef}
     * @memberof ServiceProviderType
     */
    serviceArea?: ServiceAreaRef;
}
/**
 * 
 * @export
 * @interface ServiceProviderTypesResponse
 */
export interface ServiceProviderTypesResponse {
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof ServiceProviderTypesResponse
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
}
/**
 * 
 * @export
 * @interface ServiceType
 */
export interface ServiceType {
    /**
     * 
     * @type {string}
     * @memberof ServiceType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceType
     */
    name?: string;
    /**
     * 
     * @type {Array<NotatorSection>}
     * @memberof ServiceType
     */
    defaultNotatorSections?: Array<NotatorSection>;
}
/**
 * 
 * @export
 * @interface ServiceTypeRef
 */
export interface ServiceTypeRef {
    /**
     * 
     * @type {string}
     * @memberof ServiceTypeRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTypeRef
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ServicesByServiceProviderTypeResponse
 */
export interface ServicesByServiceProviderTypeResponse {
    /**
     * 
     * @type {ServiceProviderType}
     * @memberof ServicesByServiceProviderTypeResponse
     */
    serviceProviderType?: ServiceProviderType;
    /**
     * 
     * @type {Array<Service>}
     * @memberof ServicesByServiceProviderTypeResponse
     */
    services?: Array<Service>;
}
/**
 * 
 * @export
 * @interface SpedDossier
 */
export interface SpedDossier {
    /**
     * 
     * @type {Disability}
     * @memberof SpedDossier
     */
    primaryDisability?: Disability;
    /**
     * 
     * @type {Disability}
     * @memberof SpedDossier
     */
    secondaryDisability?: Disability;
    /**
     * 
     * @type {Disability}
     * @memberof SpedDossier
     */
    tertiaryDisability?: Disability;
    /**
     * 
     * @type {Array<PlanOfCare>}
     * @memberof SpedDossier
     */
    plansOfCare?: Array<PlanOfCare>;
    /**
     * 
     * @type {Array<ConsentForMedicaidBilling>}
     * @memberof SpedDossier
     */
    consent?: Array<ConsentForMedicaidBilling>;
    /**
     * 
     * @type {Array<PrescribedServiceArea>}
     * @memberof SpedDossier
     */
    prescribedServiceAreas?: Array<PrescribedServiceArea>;
    /**
     * 
     * @type {Array<ScheduleOfOrderedSubject>}
     * @memberof SpedDossier
     */
    schedulesOfOrderedSubjects?: Array<ScheduleOfOrderedSubject>;
    /**
     * 
     * @type {PrescribedCareProvisionsLedger}
     * @memberof SpedDossier
     */
    prescribedCareProvisionsLedger?: PrescribedCareProvisionsLedger;
    /**
     * 
     * @type {Array<EligibilityRecord>}
     * @memberof SpedDossier
     */
    eligibilityRecords?: Array<EligibilityRecord>;
    /**
     * 
     * @type {Array<ScheduleOfService>}
     * @memberof SpedDossier
     */
    scheduleOfServices?: Array<ScheduleOfService>;
    /**
     * This data will be informed by the individual IEP vendor imports, however, if there is no import these are the options that  will appear on the frontend:  <para /> - Currently Served  <para /> - Referral  <para /> - Transfer  <para /> - Private School  <para /> - Withdrawn  <para /> - Dismissed  <para /> - Parent Decline  <para /> However, these options are not input into the SpedStatus field, they are simply used to determine if the spedstatus  is true or false.
     * @type {boolean}
     * @memberof SpedDossier
     */
    spedStatus?: boolean;
    /**
     * This is for Personal Care Supplement ordered in the IEP
     * @type {boolean}
     * @memberof SpedDossier
     */
    personalCareSupplement?: boolean;
    /**
     * This is for Specially Adjusted Vehicle ordered in the IEP
     * @type {boolean}
     * @memberof SpedDossier
     */
    speciallyAdjustedVehicle?: boolean;
    /**
     * 
     * @type {StudentBillingBlockTerm}
     * @memberof SpedDossier
     */
    billingBlockTerm?: StudentBillingBlockTerm;
}
/**
 * 
 * @export
 * @interface StaffDirectoryProfile
 */
export interface StaffDirectoryProfile {
    /**
     * 
     * @type {string}
     * @memberof StaffDirectoryProfile
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StaffDirectoryProfile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof StaffDirectoryProfile
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof StaffDirectoryProfile
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StaffDirectoryProfile
     */
    districtStatus?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StaffDirectoryProfile
     */
    employeeId?: string;
    /**
     * 
     * @type {DocumentationType}
     * @memberof StaffDirectoryProfile
     */
    docType?: DocumentationType;
    /**
     * 
     * @type {string}
     * @memberof StaffDirectoryProfile
     */
    serviceProviderType?: string;
    /**
     * 
     * @type {string}
     * @memberof StaffDirectoryProfile
     */
    primaryCampus?: string;
    /**
     * 
     * @type {ClientAssignmentStatus}
     * @memberof StaffDirectoryProfile
     */
    clientAssignmentStatus?: ClientAssignmentStatus;
    /**
     * 
     * @type {boolean}
     * @memberof StaffDirectoryProfile
     */
    hasAccess?: boolean;
}
/**
 * Enum: FirstName = 0, LastName = 1, Email = 2, DocumentationType = 3, ServiceProviderType = 4, AssignedSchoolCampuses = 5, EmployeeId = 6, DistrictStatus = 7
 * @export
 * @enum {string}
 */
export enum StaffDirectorySortableColumnType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5,
    NUMBER_6 = <any> 6,
    NUMBER_7 = <any> 7
}
/**
 * 
 * @export
 * @interface StateSnapshot
 */
export interface StateSnapshot {
    /**
     * 
     * @type {string}
     * @memberof StateSnapshot
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof StateSnapshot
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StateSnapshot
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StateSnapshot
     */
    dateAdded?: Date;
    /**
     * 
     * @type {MedicaidAgency}
     * @memberof StateSnapshot
     */
    medicaidAgency?: MedicaidAgency;
    /**
     * 
     * @type {MedicaidPolicy}
     * @memberof StateSnapshot
     */
    medicaidPolicy?: MedicaidPolicy;
    /**
     * 
     * @type {Array<GoalAreaOfFocus>}
     * @memberof StateSnapshot
     */
    goalAreaOfFocusInventory?: Array<GoalAreaOfFocus>;
    /**
     * This is a state-specific set of defaults for care provision options (activities, accommodations, and modifications).  The defaults are broken out by categories distinguished by the unique combination of Service Area and  ServiceType. DistrictProfiles will likely have these eventually, and be able to configure options independently,  but for now, this is a state-wide set of defaults.
     * @type {Array<ServiceCategoryConfig>}
     * @memberof StateSnapshot
     */
    serviceCategoryConfigurations?: Array<ServiceCategoryConfig>;
}
/**
 * 
 * @export
 * @interface StateSnapshotResponse
 */
export interface StateSnapshotResponse {
    /**
     * 
     * @type {string}
     * @memberof StateSnapshotResponse
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof StateSnapshotResponse
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StateSnapshotResponse
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StateSnapshotResponse
     */
    dateAdded?: Date;
    /**
     * 
     * @type {MedicaidAgency}
     * @memberof StateSnapshotResponse
     */
    medicaidAgency?: MedicaidAgency;
    /**
     * 
     * @type {MedicaidPolicy}
     * @memberof StateSnapshotResponse
     */
    medicaidPolicy?: MedicaidPolicy;
    /**
     * 
     * @type {Array<GoalAreaOfFocus>}
     * @memberof StateSnapshotResponse
     */
    goalAreaOfFocusInventory?: Array<GoalAreaOfFocus>;
    /**
     * 
     * @type {Array<ServiceCategoryConfig>}
     * @memberof StateSnapshotResponse
     */
    serviceCategoryConfigurations?: Array<ServiceCategoryConfig>;
}
/**
 * Enum: TrueAndAccurateDataAuthorization = 0, FerpaAuthorization = 1, ElectronicSignatureConsent = 2
 * @export
 * @enum {string}
 */
export enum StatementType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * Represents a student caseload assignment for a service provider, referencing the student with a  start and end date representing when the student was added/removed from the caseload.
 * @export
 * @interface StudentAssignment
 */
export interface StudentAssignment {
    /**
     * 
     * @type {string}
     * @memberof StudentAssignment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAssignment
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAssignment
     */
    lastName?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudentAssignment
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof StudentAssignment
     */
    medicaidId?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudentAssignment
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudentAssignment
     */
    endDate?: Date;
}
/**
 * 
 * @export
 * @interface StudentBillingBlockTerm
 */
export interface StudentBillingBlockTerm {
    /**
     * 
     * @type {boolean}
     * @memberof StudentBillingBlockTerm
     */
    blockBilling?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof StudentBillingBlockTerm
     */
    blockBillingStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudentBillingBlockTerm
     */
    blockBillingEndDate?: Date;
}
/**
 * 
 * @export
 * @interface StudentCaseloadProfile
 */
export interface StudentCaseloadProfile {
    /**
     * 
     * @type {string}
     * @memberof StudentCaseloadProfile
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCaseloadProfile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCaseloadProfile
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentCaseloadProfile
     */
    campus?: string;
    /**
     * 
     * @type {Grade}
     * @memberof StudentCaseloadProfile
     */
    grade?: Grade;
}
/**
 * 
 * @export
 * @interface StudentMergeDisplay
 */
export interface StudentMergeDisplay {
    /**
     * 
     * @type {string}
     * @memberof StudentMergeDisplay
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentMergeDisplay
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentMergeDisplay
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentMergeDisplay
     */
    studentId?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudentMergeDisplay
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof StudentMergeDisplay
     */
    schoolName?: string;
    /**
     * 
     * @type {Gender}
     * @memberof StudentMergeDisplay
     */
    gender?: Gender;
    /**
     * 
     * @type {CreatedBy}
     * @memberof StudentMergeDisplay
     */
    createdBy?: CreatedBy;
}
/**
 * 
 * @export
 * @interface StudentMergeLineItemDisplay
 */
export interface StudentMergeLineItemDisplay {
    /**
     * 
     * @type {string}
     * @memberof StudentMergeLineItemDisplay
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentMergeLineItemDisplay
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentMergeLineItemDisplay
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentMergeLineItemDisplay
     */
    lastName?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudentMergeLineItemDisplay
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {CreatedBy}
     * @memberof StudentMergeLineItemDisplay
     */
    createdBy?: CreatedBy;
    /**
     * 
     * @type {SchoolCampusAssignment}
     * @memberof StudentMergeLineItemDisplay
     */
    schoolCampus?: SchoolCampusAssignment;
    /**
     * 
     * @type {Gender}
     * @memberof StudentMergeLineItemDisplay
     */
    gender?: Gender;
    /**
     * 
     * @type {StudentXLogsStatus}
     * @memberof StudentMergeLineItemDisplay
     */
    xLogsStatus?: StudentXLogsStatus;
}
/**
 * This class should reflect the most complete model of the student in the context of Medicaid billing.   This model will be dumbed down into the various models for their individual contexts (billing,   sessions, etc.).
 * @export
 * @interface StudentProfile
 */
export interface StudentProfile {
    /**
     * 
     * @type {string}
     * @memberof StudentProfile
     */
    id?: string;
    /**
     * 
     * @type {ClientTermRef}
     * @memberof StudentProfile
     */
    client?: ClientTermRef;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof StudentProfile
     */
    schoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {string}
     * @memberof StudentProfile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentProfile
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentProfile
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentProfile
     */
    preferredName?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudentProfile
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {Grade}
     * @memberof StudentProfile
     */
    grade?: Grade;
    /**
     * 
     * @type {Gender}
     * @memberof StudentProfile
     */
    gender?: Gender;
    /**
     * 
     * @type {string}
     * @memberof StudentProfile
     */
    primaryLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentProfile
     */
    socialSecurityNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentProfile
     */
    medicaidId?: string;
    /**
     * 
     * @type {Array<DistrictOfLiability>}
     * @memberof StudentProfile
     */
    districtOfLiabilityRecords?: Array<DistrictOfLiability>;
    /**
     * 
     * @type {Array<DistrictProvidingServices>}
     * @memberof StudentProfile
     */
    districtProvidingServicesRecords?: Array<DistrictProvidingServices>;
    /**
     * This Unique Identifier is assigned by the state for each student.  Not to be confused with Profile.Domain.Models.Student.StudentProfile.Id.   In TX, this is called Texas Student Data System (TSDS) ID.  In NH, this is called State Assigned Student ID (SASID).
     * @type {string}
     * @memberof StudentProfile
     */
    studentIdGivenByState?: string;
    /**
     * This is the ID that the student's school assigns them.  Not to be confused with Profile.Domain.Models.Student.StudentProfile.Id.
     * @type {string}
     * @memberof StudentProfile
     */
    studentIdGivenBySchoolDistrict?: string;
    /**
     * 
     * @type {StudentXLogsStatus}
     * @memberof StudentProfile
     */
    xLogsStatus?: StudentXLogsStatus;
    /**
     * 
     * @type {Address}
     * @memberof StudentProfile
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {SpedDossier}
     * @memberof StudentProfile
     */
    spedDossier?: SpedDossier;
    /**
     * 
     * @type {boolean}
     * @memberof StudentProfile
     */
    migratedBackToLegacy?: boolean;
    /**
     * 
     * @type {CreatedBy}
     * @memberof StudentProfile
     */
    createdBy?: CreatedBy;
    /**
     * 
     * @type {MergedWith}
     * @memberof StudentProfile
     */
    mergedWith?: MergedWith;
    /**
     * 
     * @type {boolean}
     * @memberof StudentProfile
     */
    deleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StudentProfile
     */
    lastModified?: number;
}
/**
 * Response object for the MergeStudents endpoint
 * @export
 * @interface StudentProfileMergeResponse
 */
export interface StudentProfileMergeResponse {
    /**
     * 
     * @type {MergeStudentStatusMessage}
     * @memberof StudentProfileMergeResponse
     */
    statusMessage?: MergeStudentStatusMessage;
}
/**
 * 
 * @export
 * @interface StudentRef
 */
export interface StudentRef {
    /**
     * 
     * @type {string}
     * @memberof StudentRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRef
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentRef
     */
    lastName?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudentRef
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof StudentRef
     */
    medicaidId?: string;
}
/**
 * 
 * @export
 * @interface StudentResponse
 */
export interface StudentResponse {
    /**
     * 
     * @type {string}
     * @memberof StudentResponse
     */
    id?: string;
    /**
     * 
     * @type {ClientTermRef}
     * @memberof StudentResponse
     */
    client?: ClientTermRef;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof StudentResponse
     */
    schoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {string}
     * @memberof StudentResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentResponse
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentResponse
     */
    preferredName?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudentResponse
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {Grade}
     * @memberof StudentResponse
     */
    grade?: Grade;
    /**
     * 
     * @type {Gender}
     * @memberof StudentResponse
     */
    gender?: Gender;
    /**
     * 
     * @type {string}
     * @memberof StudentResponse
     */
    primaryLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentResponse
     */
    socialSecurityNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentResponse
     */
    medicaidId?: string;
    /**
     * 
     * @type {Array<DistrictOfLiability>}
     * @memberof StudentResponse
     */
    districtOfLiabilityRecords?: Array<DistrictOfLiability>;
    /**
     * 
     * @type {Array<DistrictProvidingServices>}
     * @memberof StudentResponse
     */
    districtProvidingServicesRecords?: Array<DistrictProvidingServices>;
    /**
     * 
     * @type {string}
     * @memberof StudentResponse
     */
    studentIdGivenByState?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentResponse
     */
    studentIdGivenBySchoolDistrict?: string;
    /**
     * 
     * @type {StudentXLogsStatus}
     * @memberof StudentResponse
     */
    xLogsStatus?: StudentXLogsStatus;
    /**
     * 
     * @type {Address}
     * @memberof StudentResponse
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {SpedDossier}
     * @memberof StudentResponse
     */
    spedDossier?: SpedDossier;
    /**
     * 
     * @type {boolean}
     * @memberof StudentResponse
     */
    migratedBackToLegacy?: boolean;
    /**
     * 
     * @type {CreatedBy}
     * @memberof StudentResponse
     */
    createdBy?: CreatedBy;
}
/**
 * Enum: Pending = 0, Active = 1, Inactive = 2, Referral = 3, Transfer = 4
 * @export
 * @enum {string}
 */
export enum StudentXLogsStatus {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4
}
/**
 * 
 * @export
 * @interface SupervisorAccessRequest
 */
export interface SupervisorAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof SupervisorAccessRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupervisorAccessRequest
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof SupervisorAccessRequest
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SupervisorAccessRequest
     */
    read?: Date;
    /**
     * 
     * @type {string}
     * @memberof SupervisorAccessRequest
     */
    partitionKey?: string;
    /**
     * 
     * @type {UserRef}
     * @memberof SupervisorAccessRequest
     */
    notifiedUser?: UserRef;
    /**
     * 
     * @type {NotificationType}
     * @memberof SupervisorAccessRequest
     */
    type?: NotificationType;
    /**
     * 
     * @type {UserRef}
     * @memberof SupervisorAccessRequest
     */
    requestingUser?: UserRef;
    /**
     * 
     * @type {Date}
     * @memberof SupervisorAccessRequest
     */
    requestDate?: Date;
    /**
     * 
     * @type {NotificationResponse}
     * @memberof SupervisorAccessRequest
     */
    response?: NotificationResponse;
}
/**
 * 
 * @export
 * @interface TrainingInformation
 */
export interface TrainingInformation {
    /**
     * 
     * @type {TrainingType}
     * @memberof TrainingInformation
     */
    trainingType?: TrainingType;
    /**
     * 
     * @type {Date}
     * @memberof TrainingInformation
     */
    trainingDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingInformation
     */
    certificateSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingInformation
     */
    visibleOnProfile?: boolean;
}
/**
 * Enum   XLogsWorkshopOnSite = 0   XLogsWorkshopVirtual = 1  XLogsAccountReview = 2  RMTSTrainingVideo = 3  MSBUserGroup = 4  ExpressTraining = 5
 * @export
 * @enum {string}
 */
export enum TrainingType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5
}
/**
 * Enum: Time = 0, NumberOfTrips = 1, MedicineAdministrations = 2
 * @export
 * @enum {string}
 */
export enum UnitCompositionType {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * Enum: _1 = 0, _2 = 1, _3 = 2
 * @export
 * @enum {string}
 */
export enum UnitRoundingMode {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * 
 * @export
 * @interface UpdateClientRequest
 */
export interface UpdateClientRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequest
     */
    name?: string;
    /**
     * 
     * @type {ClientAccountStatus}
     * @memberof UpdateClientRequest
     */
    status?: ClientAccountStatus;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof UpdateClientRequest
     */
    msbRepresentatives?: Array<Contact>;
    /**
     * 
     * @type {ClientDistrictRef}
     * @memberof UpdateClientRequest
     */
    fiscalAgent?: ClientDistrictRef;
    /**
     * 
     * @type {Array<ClientDistrictRef>}
     * @memberof UpdateClientRequest
     */
    additionalDistricts?: Array<ClientDistrictRef>;
    /**
     * 
     * @type {Array<ProgressReportDate>}
     * @memberof UpdateClientRequest
     */
    progressReportDates?: Array<ProgressReportDate>;
    /**
     * 
     * @type {Array<NonSchoolDay>}
     * @memberof UpdateClientRequest
     */
    nonSchoolDays?: Array<NonSchoolDay>;
    /**
     * This acts as a feature flag for Campus Access Restrictions in the Client profile
     * @type {boolean}
     * @memberof UpdateClientRequest
     */
    usesCampusRestrictions?: boolean;
    /**
     * 
     * @type {MedicaidPolicy}
     * @memberof UpdateClientRequest
     */
    customMedicaidPolicy?: MedicaidPolicy;
    /**
     * 
     * @type {Array<GoalAreaOfFocus>}
     * @memberof UpdateClientRequest
     */
    customGoalAreaOfFocusInventory?: Array<GoalAreaOfFocus>;
    /**
     * 
     * @type {Array<ServiceCategoryConfig>}
     * @memberof UpdateClientRequest
     */
    customServiceCategoryConfigurations?: Array<ServiceCategoryConfig>;
}
/**
 * 
 * @export
 * @interface UpdateDistrictRequest
 */
export interface UpdateDistrictRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDistrictRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDistrictRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDistrictRequest
     */
    npi?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateDistrictRequest
     */
    billingStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateDistrictRequest
     */
    billingEnd?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateDistrictRequest
     */
    w9?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDistrictRequest
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDistrictRequest
     */
    stateProviderId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDistrictRequest
     */
    stateDistrictId?: string;
    /**
     * 
     * @type {Address}
     * @memberof UpdateDistrictRequest
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof UpdateDistrictRequest
     */
    billingAddress?: Address;
    /**
     * 
     * @type {Array<SchoolCampus>}
     * @memberof UpdateDistrictRequest
     */
    schoolCampuses?: Array<SchoolCampus>;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof UpdateDistrictRequest
     */
    contactDirectory?: Array<Contact>;
    /**
     * 
     * @type {Array<PastAudit>}
     * @memberof UpdateDistrictRequest
     */
    pastAudits?: Array<PastAudit>;
    /**
     * 
     * @type {Array<ClientTermRef>}
     * @memberof UpdateDistrictRequest
     */
    clients?: Array<ClientTermRef>;
    /**
     * 
     * @type {Array<Crm>}
     * @memberof UpdateDistrictRequest
     */
    crms?: Array<Crm>;
    /**
     * 
     * @type {Array<Contract>}
     * @memberof UpdateDistrictRequest
     */
    contracts?: Array<Contract>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDistrictRequest
     */
    isFiscalAgent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateDistrictRequest
     */
    spedCount?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDistrictRequest
     */
    region?: string;
}
/**
 * 
 * @export
 * @interface UpdateGoalRequest
 */
export interface UpdateGoalRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalRequest
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalRequest
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateGoalRequest
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateGoalRequest
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateGoalRequest
     */
    deactivationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateGoalRequest
     */
    completionDate?: Date;
    /**
     * 
     * @type {GoalAreaOfFocusRef}
     * @memberof UpdateGoalRequest
     */
    goalAreaOfFocus?: GoalAreaOfFocusRef;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalRequest
     */
    status?: string;
    /**
     * 
     * @type {Array<Objective>}
     * @memberof UpdateGoalRequest
     */
    objectives?: Array<Objective>;
}
/**
 * 
 * @export
 * @interface UpdateSchoolCampusRequest
 */
export interface UpdateSchoolCampusRequest {
    /**
     * 
     * @type {SchoolCampus}
     * @memberof UpdateSchoolCampusRequest
     */
    campus?: SchoolCampus;
}
/**
 * The request object to update a service provider
 * @export
 * @interface UpdateServiceProviderRequest
 */
export interface UpdateServiceProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    middleName?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateServiceProviderRequest
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    rtmsEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    notificationEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    employeeId?: string;
    /**
     * 
     * @type {DocumentationType}
     * @memberof UpdateServiceProviderRequest
     */
    documentationType?: DocumentationType;
    /**
     * 
     * @type {ClassType}
     * @memberof UpdateServiceProviderRequest
     */
    classType?: ClassType;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {ClientTermRef}
     * @memberof UpdateServiceProviderRequest
     */
    client?: ClientTermRef;
    /**
     * 
     * @type {Array<DistrictRef>}
     * @memberof UpdateServiceProviderRequest
     */
    districtsOfOperation?: Array<DistrictRef>;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof UpdateServiceProviderRequest
     */
    assignedSchoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    jobTitle?: string;
    /**
     * 
     * @type {EmployeeType}
     * @memberof UpdateServiceProviderRequest
     */
    employeeType?: EmployeeType;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateServiceProviderRequest
     */
    isOnParticipationList?: boolean;
    /**
     * 
     * @type {ParticipationListStatusType}
     * @memberof UpdateServiceProviderRequest
     */
    participationListStatus?: ParticipationListStatusType;
    /**
     * 
     * @type {Array<MedicaidCredential>}
     * @memberof UpdateServiceProviderRequest
     */
    medicaidCredentials?: Array<MedicaidCredential>;
    /**
     * 
     * @type {Array<ProviderRate>}
     * @memberof UpdateServiceProviderRequest
     */
    rateRecords?: Array<ProviderRate>;
    /**
     * 
     * @type {Array<StudentAssignment>}
     * @memberof UpdateServiceProviderRequest
     */
    studentCaseloadAssignments?: Array<StudentAssignment>;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof UpdateServiceProviderRequest
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
    /**
     * 
     * @type {Array<FederalFunding>}
     * @memberof UpdateServiceProviderRequest
     */
    federalFundingRecords?: Array<FederalFunding>;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    npi?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceProviderRequest
     */
    stateMedicaidNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateServiceProviderRequest
     */
    blockBillingForProvider?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UpdateServiceProviderRequest
     */
    blockBillingStartDate?: Date;
    /**
     * 
     * @type {Array<TrainingInformation>}
     * @memberof UpdateServiceProviderRequest
     */
    trainingInformationLog?: Array<TrainingInformation>;
    /**
     * 
     * @type {ClientAssignmentStatus}
     * @memberof UpdateServiceProviderRequest
     */
    clientAssignmentStatus?: ClientAssignmentStatus;
}
/**
 * 
 * @export
 * @interface UpdateStateSnapshotRequest
 */
export interface UpdateStateSnapshotRequest {
    /**
     * 
     * @type {MedicaidAgency}
     * @memberof UpdateStateSnapshotRequest
     */
    medicaidAgency?: MedicaidAgency;
    /**
     * 
     * @type {MedicaidPolicy}
     * @memberof UpdateStateSnapshotRequest
     */
    medicaidPolicy?: MedicaidPolicy;
    /**
     * 
     * @type {Array<GoalAreaOfFocus>}
     * @memberof UpdateStateSnapshotRequest
     */
    goalAreaOfFocusInventory?: Array<GoalAreaOfFocus>;
    /**
     * 
     * @type {Array<ServiceCategoryConfig>}
     * @memberof UpdateStateSnapshotRequest
     */
    serviceCategoryConfigurations?: Array<ServiceCategoryConfig>;
}
/**
 * 
 * @export
 * @interface UpdateStudentRequest
 */
export interface UpdateStudentRequest {
    /**
     * 
     * @type {ClientTermRef}
     * @memberof UpdateStudentRequest
     */
    client?: ClientTermRef;
    /**
     * 
     * @type {Array<SchoolCampusAssignment>}
     * @memberof UpdateStudentRequest
     */
    schoolCampuses?: Array<SchoolCampusAssignment>;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudentRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudentRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudentRequest
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudentRequest
     */
    preferredName?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateStudentRequest
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {Grade}
     * @memberof UpdateStudentRequest
     */
    grade?: Grade;
    /**
     * 
     * @type {Gender}
     * @memberof UpdateStudentRequest
     */
    gender?: Gender;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudentRequest
     */
    primaryLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudentRequest
     */
    socialSecurityNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudentRequest
     */
    medicaidId?: string;
    /**
     * 
     * @type {Array<DistrictOfLiability>}
     * @memberof UpdateStudentRequest
     */
    districtOfLiabilityRecords?: Array<DistrictOfLiability>;
    /**
     * 
     * @type {Array<DistrictProvidingServices>}
     * @memberof UpdateStudentRequest
     */
    districtProvidingServicesRecords?: Array<DistrictProvidingServices>;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudentRequest
     */
    studentIdGivenByState?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudentRequest
     */
    studentIdGivenBySchoolDistrict?: string;
    /**
     * 
     * @type {StudentXLogsStatus}
     * @memberof UpdateStudentRequest
     */
    xLogsStatus?: StudentXLogsStatus;
    /**
     * 
     * @type {Address}
     * @memberof UpdateStudentRequest
     */
    mailingAddress?: Address;
    /**
     * 
     * @type {SpedDossier}
     * @memberof UpdateStudentRequest
     */
    spedDossier?: SpedDossier;
    /**
     * 
     * @type {CreatedBy}
     * @memberof UpdateStudentRequest
     */
    createdBy?: CreatedBy;
    /**
     * 
     * @type {MergedWith}
     * @memberof UpdateStudentRequest
     */
    mergedWith?: MergedWith;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateUserRequest
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    isSuperAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    isMsbAdmin?: boolean;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof UpdateUserRequest
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    loggedInClientId?: string;
    /**
     * 
     * @type {Array<ClientAssignment>}
     * @memberof UpdateUserRequest
     */
    clientAssignments?: Array<ClientAssignment>;
}
/**
 * This card contains the information needed to display and manage a user's client access.
 * @export
 * @interface UserManagementCard
 */
export interface UserManagementCard {
    /**
     * 
     * @type {UserServiceProviderRef}
     * @memberof UserManagementCard
     */
    user?: UserServiceProviderRef;
    /**
     * The list of district access request notifications that were combined to form this UserManagementCard.   NOTE: This list should populate the dropdown options for districts to approve/deny the user in,   but the options should be filtered so as to only display the ones that the acting district admin is authorized in.
     * @type {Array<DistrictAccessRequestRef>}
     * @memberof UserManagementCard
     */
    districtAccessRequests?: Array<DistrictAccessRequestRef>;
    /**
     * 
     * @type {ClientRef}
     * @memberof UserManagementCard
     */
    client?: ClientRef;
    /**
     * 
     * @type {UserManagementStatus}
     * @memberof UserManagementCard
     */
    status?: UserManagementStatus;
}
/**
 * This is the response given when request is made to get the UserManagementCards.
 * @export
 * @interface UserManagementCardsResponse
 */
export interface UserManagementCardsResponse {
    /**
     * The list of users that have not been approved or denied in any districts within this client.
     * @type {Array<UserManagementCard>}
     * @memberof UserManagementCardsResponse
     */
    unapprovedUsers?: Array<UserManagementCard>;
    /**
     * The list of users that have been denied access to at least one district within this client.
     * @type {Array<UserManagementCard>}
     * @memberof UserManagementCardsResponse
     */
    deniedUsers?: Array<UserManagementCard>;
    /**
     * The list of user cards detailing the user management activity for each of the users within this client.
     * @type {Array<UserManagementHistoryCard>}
     * @memberof UserManagementCardsResponse
     */
    approvalAndDenialHistory?: Array<UserManagementHistoryCard>;
}
/**
 * This card contains all of the information needed to display the historical management activity on a user's account.
 * @export
 * @interface UserManagementHistoryCard
 */
export interface UserManagementHistoryCard {
    /**
     * 
     * @type {UserServiceProviderRef}
     * @memberof UserManagementHistoryCard
     */
    user?: UserServiceProviderRef;
    /**
     * The list of districts this user has requested access to.
     * @type {Array<DistrictRef>}
     * @memberof UserManagementHistoryCard
     */
    districts?: Array<DistrictRef>;
    /**
     * 
     * @type {ClientRef}
     * @memberof UserManagementHistoryCard
     */
    client?: ClientRef;
    /**
     * The list of historical records of user management activity on this user's account.
     * @type {Array<HistoricalStatus>}
     * @memberof UserManagementHistoryCard
     */
    historicalStatuses?: Array<HistoricalStatus>;
    /**
     * 
     * @type {UserManagementStatus}
     * @memberof UserManagementHistoryCard
     */
    currentStatus?: UserManagementStatus;
    /**
     * 
     * @type {XLogsRole}
     * @memberof UserManagementHistoryCard
     */
    xLogsRole?: XLogsRole;
    /**
     * 
     * @type {Date}
     * @memberof UserManagementHistoryCard
     */
    initialRegistrationDate?: Date;
}
/**
 * Enum: Unapproved = 0, Approved = 1, Denied = 2
 * @export
 * @enum {string}
 */
export enum UserManagementStatus {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * This id should be set as the id of the user in the identity provider. However, the create user endpoint should not require this id  as a parameter. The id should be retrieved from the User object in the http context.
     * @type {string}
     * @memberof UserProfile
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    emailAddress?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserProfile
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    isSuperAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    isMsbAdmin?: boolean;
    /**
     * 
     * @type {ServiceProviderType}
     * @memberof UserProfile
     */
    serviceProviderType?: ServiceProviderType;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof UserProfile
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    loggedInClientId?: string;
    /**
     * 
     * @type {Array<ClientAssignment>}
     * @memberof UserProfile
     */
    clientAssignments?: Array<ClientAssignment>;
    /**
     * 
     * @type {Array<ElectronicSignature>}
     * @memberof UserProfile
     */
    electronicSignatures?: Array<ElectronicSignature>;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    legacyUserId?: string;
}
/**
 * 
 * @export
 * @interface UserRef
 */
export interface UserRef {
    /**
     * 
     * @type {string}
     * @memberof UserRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRef
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRef
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRef
     */
    lastName?: string;
}
/**
 * A record with information from the UserProfile with related information.
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    emailAddress?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    isSuperAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    isMsbAdmin?: boolean;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof UserResponse
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    loggedInClientId?: string;
    /**
     * 
     * @type {Array<ClientAssignment>}
     * @memberof UserResponse
     */
    clientAssignments?: Array<ClientAssignment>;
    /**
     * 
     * @type {Array<ElectronicSignature>}
     * @memberof UserResponse
     */
    electronicSignatures?: Array<ElectronicSignature>;
}
/**
 * 
 * @export
 * @interface UserServiceProviderRef
 */
export interface UserServiceProviderRef {
    /**
     * 
     * @type {string}
     * @memberof UserServiceProviderRef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserServiceProviderRef
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserServiceProviderRef
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserServiceProviderRef
     */
    lastName?: string;
    /**
     * 
     * @type {Array<ServiceProviderType>}
     * @memberof UserServiceProviderRef
     */
    serviceProviderTypes?: Array<ServiceProviderType>;
}
/**
 * Enum: ExecutiveAdmin = 0, DelegatedAdmin = 1, Approver = 2, ProxyDataEntry = 3, ServiceProviderAutonomous = 4, ServiceProviderAssistant = 5
 * @export
 * @enum {string}
 */
export enum XLogsRole {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3,
    NUMBER_4 = <any> 4,
    NUMBER_5 = <any> 5
}
/**
 * ClientsApi - fetch parameter creator
 * @export
 */
export const ClientsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Sample Request:                    GET /Clients/{clientId}
         * @summary Gets a single Client Profile, specified by id.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsDeletedGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsDeletedGet.');
            }
            const localVarPath = `/v1/Clients/Deleted`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET v1/Clients/DropdownOptions
         * @summary Gets a list of dropdown options for all the clients in the current database's clients container.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsDropdownOptionsGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsDropdownOptionsGet.');
            }
            const localVarPath = `/v1/Clients/DropdownOptions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /Clients
         * @summary Gets all clients in the current database's clients container.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsGet.');
            }
            const localVarPath = `/v1/Clients`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a specified client by client ID
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdDeletePatch(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ClientsIdDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsIdDeletePatch.');
            }
            const localVarPath = `/v1/Clients/{id}/Delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a single Client Profile, specified by id.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdGet(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ClientsIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsIdGet.');
            }
            const localVarPath = `/v1/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Non School Days for specific client.
         * @param {string} id The client Id
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdNonSchoolDatesGet(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ClientsIdNonSchoolDatesGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsIdNonSchoolDatesGet.');
            }
            const localVarPath = `/v1/Clients/{id}/NonSchoolDates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    PATCH /Clients
         * @summary Modifies a single Client Profile (specified by ID) in the clients container, by updating the given fields in the request body.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateClientRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdPatch(id: string, state: string, body?: UpdateClientRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ClientsIdPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsIdPatch.');
            }
            const localVarPath = `/v1/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateClientRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Progress Report Date for specific client.
         * @param {string} id The client ID
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdProgressReportDateGet(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ClientsIdProgressReportDateGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsIdProgressReportDateGet.');
            }
            const localVarPath = `/v1/Clients/{id}/ProgressReportDate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    PUT /Clients
         * @summary Modifies a single Client Profile (specified by ID) in the clients container, by replacing it with the profile  provided in the body of the request.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateClientRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdPut(id: string, state: string, body?: UpdateClientRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ClientsIdPut.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsIdPut.');
            }
            const localVarPath = `/v1/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateClientRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restores a deleted client profile by a specific client ID
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdUndoDeletePatch(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ClientsIdUndoDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsIdUndoDeletePatch.');
            }
            const localVarPath = `/v1/Clients/{id}/UndoDelete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    POST /Clients
         * @summary Creates a single Client Profile in the clients container for the current database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateClientRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsPost(state: string, body?: CreateClientRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsPost.');
            }
            const localVarPath = `/v1/Clients`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateClientRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Progress Report Date for specific client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchClientProgressReportDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsProgressReportDatePatch(state: string, body?: PatchClientProgressReportDateRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsProgressReportDatePatch.');
            }
            const localVarPath = `/v1/Clients/ProgressReportDate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchClientProgressReportDateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /Clients/Test
         * @summary This is a test endpoint.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsTestGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ClientsTestGet.');
            }
            const localVarPath = `/v1/Clients/Test`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Sample Request:                    GET /Clients/{clientId}
         * @summary Gets a single Client Profile, specified by id.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsDeletedGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetClientsResponse> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsDeletedGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET v1/Clients/DropdownOptions
         * @summary Gets a list of dropdown options for all the clients in the current database's clients container.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsDropdownOptionsGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientDropdownResponse> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsDropdownOptionsGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /Clients
         * @summary Gets all clients in the current database's clients container.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetClientsResponse> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Deletes a specified client by client ID
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdDeletePatch(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsIdDeletePatch(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets a single Client Profile, specified by id.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdGet(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientResponse> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsIdGet(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the Non School Days for specific client.
         * @param {string} id The client Id
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdNonSchoolDatesGet(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetNonSchoolDatesResponse> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsIdNonSchoolDatesGet(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    PATCH /Clients
         * @summary Modifies a single Client Profile (specified by ID) in the clients container, by updating the given fields in the request body.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateClientRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdPatch(id: string, state: string, body?: UpdateClientRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsIdPatch(id, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Progress Report Date for specific client.
         * @param {string} id The client ID
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdProgressReportDateGet(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetProgressReportDatesResponse> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsIdProgressReportDateGet(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    PUT /Clients
         * @summary Modifies a single Client Profile (specified by ID) in the clients container, by replacing it with the profile  provided in the body of the request.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateClientRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdPut(id: string, state: string, body?: UpdateClientRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsIdPut(id, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Restores a deleted client profile by a specific client ID
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdUndoDeletePatch(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientResponse> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsIdUndoDeletePatch(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    POST /Clients
         * @summary Creates a single Client Profile in the clients container for the current database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateClientRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsPost(state: string, body?: CreateClientRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientResponse> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set Progress Report Date for specific client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchClientProgressReportDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsProgressReportDatePatch(state: string, body?: PatchClientProgressReportDateRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsProgressReportDatePatch(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /Clients/Test
         * @summary This is a test endpoint.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsTestGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ClientsApiFetchParamCreator(configuration).v1ClientsTestGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Sample Request:                    GET /Clients/{clientId}
         * @summary Gets a single Client Profile, specified by id.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsDeletedGet(state: string, options?: any) {
            return ClientsApiFp(configuration).v1ClientsDeletedGet(state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET v1/Clients/DropdownOptions
         * @summary Gets a list of dropdown options for all the clients in the current database's clients container.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsDropdownOptionsGet(state: string, options?: any) {
            return ClientsApiFp(configuration).v1ClientsDropdownOptionsGet(state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /Clients
         * @summary Gets all clients in the current database's clients container.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsGet(state: string, options?: any) {
            return ClientsApiFp(configuration).v1ClientsGet(state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Deletes a specified client by client ID
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdDeletePatch(id: string, state: string, options?: any) {
            return ClientsApiFp(configuration).v1ClientsIdDeletePatch(id, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets a single Client Profile, specified by id.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdGet(id: string, state: string, options?: any) {
            return ClientsApiFp(configuration).v1ClientsIdGet(id, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the Non School Days for specific client.
         * @param {string} id The client Id
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdNonSchoolDatesGet(id: string, state: string, options?: any) {
            return ClientsApiFp(configuration).v1ClientsIdNonSchoolDatesGet(id, state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    PATCH /Clients
         * @summary Modifies a single Client Profile (specified by ID) in the clients container, by updating the given fields in the request body.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateClientRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdPatch(id: string, state: string, body?: UpdateClientRequest, options?: any) {
            return ClientsApiFp(configuration).v1ClientsIdPatch(id, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Progress Report Date for specific client.
         * @param {string} id The client ID
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdProgressReportDateGet(id: string, state: string, options?: any) {
            return ClientsApiFp(configuration).v1ClientsIdProgressReportDateGet(id, state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    PUT /Clients
         * @summary Modifies a single Client Profile (specified by ID) in the clients container, by replacing it with the profile  provided in the body of the request.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateClientRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdPut(id: string, state: string, body?: UpdateClientRequest, options?: any) {
            return ClientsApiFp(configuration).v1ClientsIdPut(id, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Restores a deleted client profile by a specific client ID
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsIdUndoDeletePatch(id: string, state: string, options?: any) {
            return ClientsApiFp(configuration).v1ClientsIdUndoDeletePatch(id, state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    POST /Clients
         * @summary Creates a single Client Profile in the clients container for the current database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateClientRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsPost(state: string, body?: CreateClientRequest, options?: any) {
            return ClientsApiFp(configuration).v1ClientsPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set Progress Report Date for specific client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchClientProgressReportDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsProgressReportDatePatch(state: string, body?: PatchClientProgressReportDateRequest, options?: any) {
            return ClientsApiFp(configuration).v1ClientsProgressReportDatePatch(state, body, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /Clients/Test
         * @summary This is a test endpoint.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClientsTestGet(state: string, options?: any) {
            return ClientsApiFp(configuration).v1ClientsTestGet(state, options)(fetch, basePath);
        },
    };
};

/**
 * ClientsApi - interface
 * @export
 * @interface ClientsApi
 */
export interface ClientsApiInterface {
    /**
     * Sample Request:                    GET /Clients/{clientId}
     * @summary Gets a single Client Profile, specified by id.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsDeletedGet(state: string, options?: any): Promise<GetClientsResponse>;

    /**
     * Sample Request:                    GET v1/Clients/DropdownOptions
     * @summary Gets a list of dropdown options for all the clients in the current database's clients container.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsDropdownOptionsGet(state: string, options?: any): Promise<ClientDropdownResponse>;

    /**
     * Sample Request:                    GET /Clients
     * @summary Gets all clients in the current database's clients container.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsGet(state: string, options?: any): Promise<GetClientsResponse>;

    /**
     * 
     * @summary Deletes a specified client by client ID
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsIdDeletePatch(id: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Gets a single Client Profile, specified by id.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsIdGet(id: string, state: string, options?: any): Promise<ClientResponse>;

    /**
     * 
     * @summary Get the Non School Days for specific client.
     * @param {string} id The client Id
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsIdNonSchoolDatesGet(id: string, state: string, options?: any): Promise<GetNonSchoolDatesResponse>;

    /**
     * Sample Request:                    PATCH /Clients
     * @summary Modifies a single Client Profile (specified by ID) in the clients container, by updating the given fields in the request body.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateClientRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsIdPatch(id: string, state: string, body?: UpdateClientRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary Get Progress Report Date for specific client.
     * @param {string} id The client ID
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsIdProgressReportDateGet(id: string, state: string, options?: any): Promise<GetProgressReportDatesResponse>;

    /**
     * Sample Request:                    PUT /Clients
     * @summary Modifies a single Client Profile (specified by ID) in the clients container, by replacing it with the profile  provided in the body of the request.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateClientRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsIdPut(id: string, state: string, body?: UpdateClientRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary Restores a deleted client profile by a specific client ID
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsIdUndoDeletePatch(id: string, state: string, options?: any): Promise<ClientResponse>;

    /**
     * Sample Request:                    POST /Clients
     * @summary Creates a single Client Profile in the clients container for the current database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateClientRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsPost(state: string, body?: CreateClientRequest, options?: any): Promise<ClientResponse>;

    /**
     * 
     * @summary Set Progress Report Date for specific client.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchClientProgressReportDateRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsProgressReportDatePatch(state: string, body?: PatchClientProgressReportDateRequest, options?: any): Promise<{}>;

    /**
     * Sample Request:                    GET /Clients/Test
     * @summary This is a test endpoint.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApiInterface
     */
    v1ClientsTestGet(state: string, options?: any): Promise<{}>;

}

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI implements ClientsApiInterface {
    /**
     * Sample Request:                    GET /Clients/{clientId}
     * @summary Gets a single Client Profile, specified by id.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsDeletedGet(state: string, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsDeletedGet(state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET v1/Clients/DropdownOptions
     * @summary Gets a list of dropdown options for all the clients in the current database's clients container.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsDropdownOptionsGet(state: string, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsDropdownOptionsGet(state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /Clients
     * @summary Gets all clients in the current database's clients container.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsGet(state: string, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsGet(state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Deletes a specified client by client ID
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsIdDeletePatch(id: string, state: string, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsIdDeletePatch(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets a single Client Profile, specified by id.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsIdGet(id: string, state: string, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsIdGet(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the Non School Days for specific client.
     * @param {string} id The client Id
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsIdNonSchoolDatesGet(id: string, state: string, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsIdNonSchoolDatesGet(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    PATCH /Clients
     * @summary Modifies a single Client Profile (specified by ID) in the clients container, by updating the given fields in the request body.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateClientRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsIdPatch(id: string, state: string, body?: UpdateClientRequest, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsIdPatch(id, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Progress Report Date for specific client.
     * @param {string} id The client ID
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsIdProgressReportDateGet(id: string, state: string, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsIdProgressReportDateGet(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    PUT /Clients
     * @summary Modifies a single Client Profile (specified by ID) in the clients container, by replacing it with the profile  provided in the body of the request.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateClientRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsIdPut(id: string, state: string, body?: UpdateClientRequest, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsIdPut(id, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Restores a deleted client profile by a specific client ID
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsIdUndoDeletePatch(id: string, state: string, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsIdUndoDeletePatch(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    POST /Clients
     * @summary Creates a single Client Profile in the clients container for the current database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateClientRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsPost(state: string, body?: CreateClientRequest, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set Progress Report Date for specific client.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchClientProgressReportDateRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsProgressReportDatePatch(state: string, body?: PatchClientProgressReportDateRequest, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsProgressReportDatePatch(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /Clients/Test
     * @summary This is a test endpoint.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public v1ClientsTestGet(state: string, options?: any) {
        return ClientsApiFp(this.configuration).v1ClientsTestGet(state, options)(this.fetch, this.basePath);
    }

}
/**
 * DistrictsApi - fetch parameter creator
 * @export
 */
export const DistrictsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Sample Request:                    GET /Districts/Deleted
         * @summary Gets a list of all deleted districts within the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDeletedGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsDeletedGet.');
            }
            const localVarPath = `/v1/Districts/Deleted`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a campus to a district profile
         * @param {string} districtId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {AddSchoolCampusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdCampusesAddPatch(districtId: string, state: string, body?: AddSchoolCampusRequest, options: any = {}): FetchArgs {
            // verify required parameter 'districtId' is not null or undefined
            if (districtId === null || districtId === undefined) {
                throw new RequiredError('districtId','Required parameter districtId was null or undefined when calling v1DistrictsDistrictIdCampusesAddPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsDistrictIdCampusesAddPatch.');
            }
            const localVarPath = `/v1/Districts/{districtId}/Campuses/Add`
                .replace(`{${"districtId"}}`, encodeURIComponent(String(districtId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddSchoolCampusRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a list specified campuses from a district profile.
         * @param {string} districtId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RemoveCampusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdCampusesRemovePatch(districtId: string, state: string, body?: RemoveCampusRequest, options: any = {}): FetchArgs {
            // verify required parameter 'districtId' is not null or undefined
            if (districtId === null || districtId === undefined) {
                throw new RequiredError('districtId','Required parameter districtId was null or undefined when calling v1DistrictsDistrictIdCampusesRemovePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsDistrictIdCampusesRemovePatch.');
            }
            const localVarPath = `/v1/Districts/{districtId}/Campuses/Remove`
                .replace(`{${"districtId"}}`, encodeURIComponent(String(districtId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RemoveCampusRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a specified campus to a district profile.
         * @param {string} districtId 
         * @param {string} campusId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateSchoolCampusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdCampusesUpdatePatch(districtId: string, campusId: string, state: string, body?: UpdateSchoolCampusRequest, options: any = {}): FetchArgs {
            // verify required parameter 'districtId' is not null or undefined
            if (districtId === null || districtId === undefined) {
                throw new RequiredError('districtId','Required parameter districtId was null or undefined when calling v1DistrictsDistrictIdCampusesUpdatePatch.');
            }
            // verify required parameter 'campusId' is not null or undefined
            if (campusId === null || campusId === undefined) {
                throw new RequiredError('campusId','Required parameter campusId was null or undefined when calling v1DistrictsDistrictIdCampusesUpdatePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsDistrictIdCampusesUpdatePatch.');
            }
            const localVarPath = `/v1/Districts/{districtId}/Campuses/Update`
                .replace(`{${"districtId"}}`, encodeURIComponent(String(districtId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (campusId !== undefined) {
                localVarQueryParameter['campusId'] = campusId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateSchoolCampusRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying Line Items in a dropdown. This should be as slim as possible for that purpose.
         * @param {string} districtId The Id of the district for which to get the list of school campuses.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdSchoolCampusesLineItemDisplaysGet(districtId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'districtId' is not null or undefined
            if (districtId === null || districtId === undefined) {
                throw new RequiredError('districtId','Required parameter districtId was null or undefined when calling v1DistrictsDistrictIdSchoolCampusesLineItemDisplaysGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsDistrictIdSchoolCampusesLineItemDisplaysGet.');
            }
            const localVarPath = `/v1/Districts/{districtId}/SchoolCampuses/LineItemDisplays`
                .replace(`{${"districtId"}}`, encodeURIComponent(String(districtId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /Districts
         * @summary Gets a list of all districts within the current database. Excludes districts which have been deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsGet.');
            }
            const localVarPath = `/v1/Districts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Progress Report Date for specific campus.
         * @param {string} id The district Id
         * @param {string} campusId The campus Id
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdCampusProgressReportDateCampusIdGet(id: string, campusId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DistrictsIdCampusProgressReportDateCampusIdGet.');
            }
            // verify required parameter 'campusId' is not null or undefined
            if (campusId === null || campusId === undefined) {
                throw new RequiredError('campusId','Required parameter campusId was null or undefined when calling v1DistrictsIdCampusProgressReportDateCampusIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsIdCampusProgressReportDateCampusIdGet.');
            }
            const localVarPath = `/v1/Districts/{id}/CampusProgressReportDate/{campusId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"campusId"}}`, encodeURIComponent(String(campusId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets a district by id and deletes it
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdDeletePatch(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DistrictsIdDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsIdDeletePatch.');
            }
            const localVarPath = `/v1/Districts/{id}/Delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Non School Days for specific district.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdDistrictNonSchoolDaysGet(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DistrictsIdDistrictNonSchoolDaysGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsIdDistrictNonSchoolDaysGet.');
            }
            const localVarPath = `/v1/Districts/{id}/DistrictNonSchoolDays`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Progress Report Date for specific district.
         * @param {string} id The district ID
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdDistrictProgressReportDateGet(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DistrictsIdDistrictProgressReportDateGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsIdDistrictProgressReportDateGet.');
            }
            const localVarPath = `/v1/Districts/{id}/DistrictProgressReportDate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /Districts/{districtId}
         * @summary Gets a single District Profile specidified by ID.
         * @param {string} id The ID of the district for which to retreive a District Profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdGet(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DistrictsIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsIdGet.');
            }
            const localVarPath = `/v1/Districts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a District profile by Id
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateDistrictRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdPut(id: string, state: string, body?: UpdateDistrictRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DistrictsIdPut.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsIdPut.');
            }
            const localVarPath = `/v1/Districts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateDistrictRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying it as an option in a dropdown. This should be as slim as possible for that purpose.
         * @param {string} id The Id of the district for which to get the list of school campuses.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdSchoolCampusesDropdownDisplaysGet(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DistrictsIdSchoolCampusesDropdownDisplaysGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsIdSchoolCampusesDropdownDisplaysGet.');
            }
            const localVarPath = `/v1/Districts/{id}/SchoolCampuses/DropdownDisplays`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a single school campus for the specified district.
         * @param {string} id The Id of the district for which to get the school campus.
         * @param {string} schoolCampusId The Id of the school campus to retrieve.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdSchoolCampusesSchoolCampusIdGet(id: string, schoolCampusId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DistrictsIdSchoolCampusesSchoolCampusIdGet.');
            }
            // verify required parameter 'schoolCampusId' is not null or undefined
            if (schoolCampusId === null || schoolCampusId === undefined) {
                throw new RequiredError('schoolCampusId','Required parameter schoolCampusId was null or undefined when calling v1DistrictsIdSchoolCampusesSchoolCampusIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsIdSchoolCampusesSchoolCampusIdGet.');
            }
            const localVarPath = `/v1/Districts/{id}/SchoolCampuses/{schoolCampusId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"schoolCampusId"}}`, encodeURIComponent(String(schoolCampusId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restores a deleted District.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdUndoDeletePatch(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1DistrictsIdUndoDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsIdUndoDeletePatch.');
            }
            const localVarPath = `/v1/Districts/{id}/UndoDelete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new district.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateDistrictRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsPost(state: string, body?: CreateDistrictRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsPost.');
            }
            const localVarPath = `/v1/Districts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateDistrictRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary If CampusId is specified, this will set the progress report dates for the specified campus.   Only if CampusId is null will this endpoint set the district progress report dates for the specified district.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchDistrictClientProgressReportDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsProgressReportDatePatch(state: string, body?: PatchDistrictClientProgressReportDateRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsProgressReportDatePatch.');
            }
            const localVarPath = `/v1/Districts/ProgressReportDate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchDistrictClientProgressReportDateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying it as an option in a dropdown. This should be as slim as possible for that purpose.
         * @param {string} districtIds A comma-separated list of district Ids for which to get the list of school campuses.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsSchoolCampusesDropdownDisplaysGet(districtIds: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'districtIds' is not null or undefined
            if (districtIds === null || districtIds === undefined) {
                throw new RequiredError('districtIds','Required parameter districtIds was null or undefined when calling v1DistrictsSchoolCampusesDropdownDisplaysGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1DistrictsSchoolCampusesDropdownDisplaysGet.');
            }
            const localVarPath = `/v1/Districts/SchoolCampuses/DropdownDisplays`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (districtIds !== undefined) {
                localVarQueryParameter['districtIds'] = districtIds;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DistrictsApi - functional programming interface
 * @export
 */
export const DistrictsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Sample Request:                    GET /Districts/Deleted
         * @summary Gets a list of all deleted districts within the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDeletedGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetDistrictsResponse> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsDeletedGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Adds a campus to a district profile
         * @param {string} districtId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {AddSchoolCampusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdCampusesAddPatch(districtId: string, state: string, body?: AddSchoolCampusRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsDistrictIdCampusesAddPatch(districtId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Removes a list specified campuses from a district profile.
         * @param {string} districtId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RemoveCampusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdCampusesRemovePatch(districtId: string, state: string, body?: RemoveCampusRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsDistrictIdCampusesRemovePatch(districtId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Updates a specified campus to a district profile.
         * @param {string} districtId 
         * @param {string} campusId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateSchoolCampusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdCampusesUpdatePatch(districtId: string, campusId: string, state: string, body?: UpdateSchoolCampusRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsDistrictIdCampusesUpdatePatch(districtId, campusId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying Line Items in a dropdown. This should be as slim as possible for that purpose.
         * @param {string} districtId The Id of the district for which to get the list of school campuses.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdSchoolCampusesLineItemDisplaysGet(districtId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetSchoolCampusLineItemsResponse> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsDistrictIdSchoolCampusesLineItemDisplaysGet(districtId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /Districts
         * @summary Gets a list of all districts within the current database. Excludes districts which have been deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetDistrictsResponse> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Progress Report Date for specific campus.
         * @param {string} id The district Id
         * @param {string} campusId The campus Id
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdCampusProgressReportDateCampusIdGet(id: string, campusId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsIdCampusProgressReportDateCampusIdGet(id, campusId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary gets a district by id and deletes it
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdDeletePatch(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsIdDeletePatch(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Non School Days for specific district.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdDistrictNonSchoolDaysGet(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetNonSchoolDatesResponse> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsIdDistrictNonSchoolDaysGet(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Progress Report Date for specific district.
         * @param {string} id The district ID
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdDistrictProgressReportDateGet(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsIdDistrictProgressReportDateGet(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /Districts/{districtId}
         * @summary Gets a single District Profile specidified by ID.
         * @param {string} id The ID of the district for which to retreive a District Profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdGet(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DistrictResponse> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsIdGet(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Updates a District profile by Id
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateDistrictRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdPut(id: string, state: string, body?: UpdateDistrictRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsIdPut(id, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying it as an option in a dropdown. This should be as slim as possible for that purpose.
         * @param {string} id The Id of the district for which to get the list of school campuses.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdSchoolCampusesDropdownDisplaysGet(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetSchoolCampusDropdownsResponse> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsIdSchoolCampusesDropdownDisplaysGet(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets a single school campus for the specified district.
         * @param {string} id The Id of the district for which to get the school campus.
         * @param {string} schoolCampusId The Id of the school campus to retrieve.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdSchoolCampusesSchoolCampusIdGet(id: string, schoolCampusId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SchoolCampusResponse> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsIdSchoolCampusesSchoolCampusIdGet(id, schoolCampusId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Restores a deleted District.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdUndoDeletePatch(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DistrictResponse> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsIdUndoDeletePatch(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Creates a new district.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateDistrictRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsPost(state: string, body?: CreateDistrictRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DistrictResponse> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary If CampusId is specified, this will set the progress report dates for the specified campus.   Only if CampusId is null will this endpoint set the district progress report dates for the specified district.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchDistrictClientProgressReportDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsProgressReportDatePatch(state: string, body?: PatchDistrictClientProgressReportDateRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsProgressReportDatePatch(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying it as an option in a dropdown. This should be as slim as possible for that purpose.
         * @param {string} districtIds A comma-separated list of district Ids for which to get the list of school campuses.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsSchoolCampusesDropdownDisplaysGet(districtIds: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetSchoolCampusDropdownsResponse> {
            const localVarFetchArgs = DistrictsApiFetchParamCreator(configuration).v1DistrictsSchoolCampusesDropdownDisplaysGet(districtIds, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DistrictsApi - factory interface
 * @export
 */
export const DistrictsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Sample Request:                    GET /Districts/Deleted
         * @summary Gets a list of all deleted districts within the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDeletedGet(state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsDeletedGet(state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Adds a campus to a district profile
         * @param {string} districtId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {AddSchoolCampusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdCampusesAddPatch(districtId: string, state: string, body?: AddSchoolCampusRequest, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsDistrictIdCampusesAddPatch(districtId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Removes a list specified campuses from a district profile.
         * @param {string} districtId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RemoveCampusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdCampusesRemovePatch(districtId: string, state: string, body?: RemoveCampusRequest, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsDistrictIdCampusesRemovePatch(districtId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Updates a specified campus to a district profile.
         * @param {string} districtId 
         * @param {string} campusId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateSchoolCampusRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdCampusesUpdatePatch(districtId: string, campusId: string, state: string, body?: UpdateSchoolCampusRequest, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsDistrictIdCampusesUpdatePatch(districtId, campusId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying Line Items in a dropdown. This should be as slim as possible for that purpose.
         * @param {string} districtId The Id of the district for which to get the list of school campuses.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsDistrictIdSchoolCampusesLineItemDisplaysGet(districtId: string, state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsDistrictIdSchoolCampusesLineItemDisplaysGet(districtId, state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /Districts
         * @summary Gets a list of all districts within the current database. Excludes districts which have been deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsGet(state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsGet(state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Progress Report Date for specific campus.
         * @param {string} id The district Id
         * @param {string} campusId The campus Id
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdCampusProgressReportDateCampusIdGet(id: string, campusId: string, state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsIdCampusProgressReportDateCampusIdGet(id, campusId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary gets a district by id and deletes it
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdDeletePatch(id: string, state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsIdDeletePatch(id, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Non School Days for specific district.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdDistrictNonSchoolDaysGet(id: string, state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsIdDistrictNonSchoolDaysGet(id, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Progress Report Date for specific district.
         * @param {string} id The district ID
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdDistrictProgressReportDateGet(id: string, state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsIdDistrictProgressReportDateGet(id, state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /Districts/{districtId}
         * @summary Gets a single District Profile specidified by ID.
         * @param {string} id The ID of the district for which to retreive a District Profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdGet(id: string, state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsIdGet(id, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Updates a District profile by Id
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateDistrictRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdPut(id: string, state: string, body?: UpdateDistrictRequest, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsIdPut(id, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying it as an option in a dropdown. This should be as slim as possible for that purpose.
         * @param {string} id The Id of the district for which to get the list of school campuses.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdSchoolCampusesDropdownDisplaysGet(id: string, state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsIdSchoolCampusesDropdownDisplaysGet(id, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets a single school campus for the specified district.
         * @param {string} id The Id of the district for which to get the school campus.
         * @param {string} schoolCampusId The Id of the school campus to retrieve.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdSchoolCampusesSchoolCampusIdGet(id: string, schoolCampusId: string, state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsIdSchoolCampusesSchoolCampusIdGet(id, schoolCampusId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Restores a deleted District.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsIdUndoDeletePatch(id: string, state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsIdUndoDeletePatch(id, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Creates a new district.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateDistrictRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsPost(state: string, body?: CreateDistrictRequest, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary If CampusId is specified, this will set the progress report dates for the specified campus.   Only if CampusId is null will this endpoint set the district progress report dates for the specified district.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchDistrictClientProgressReportDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsProgressReportDatePatch(state: string, body?: PatchDistrictClientProgressReportDateRequest, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsProgressReportDatePatch(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying it as an option in a dropdown. This should be as slim as possible for that purpose.
         * @param {string} districtIds A comma-separated list of district Ids for which to get the list of school campuses.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DistrictsSchoolCampusesDropdownDisplaysGet(districtIds: string, state: string, options?: any) {
            return DistrictsApiFp(configuration).v1DistrictsSchoolCampusesDropdownDisplaysGet(districtIds, state, options)(fetch, basePath);
        },
    };
};

/**
 * DistrictsApi - interface
 * @export
 * @interface DistrictsApi
 */
export interface DistrictsApiInterface {
    /**
     * Sample Request:                    GET /Districts/Deleted
     * @summary Gets a list of all deleted districts within the database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsDeletedGet(state: string, options?: any): Promise<GetDistrictsResponse>;

    /**
     * 
     * @summary Adds a campus to a district profile
     * @param {string} districtId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {AddSchoolCampusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsDistrictIdCampusesAddPatch(districtId: string, state: string, body?: AddSchoolCampusRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary Removes a list specified campuses from a district profile.
     * @param {string} districtId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RemoveCampusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsDistrictIdCampusesRemovePatch(districtId: string, state: string, body?: RemoveCampusRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary Updates a specified campus to a district profile.
     * @param {string} districtId 
     * @param {string} campusId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateSchoolCampusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsDistrictIdCampusesUpdatePatch(districtId: string, campusId: string, state: string, body?: UpdateSchoolCampusRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying Line Items in a dropdown. This should be as slim as possible for that purpose.
     * @param {string} districtId The Id of the district for which to get the list of school campuses.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsDistrictIdSchoolCampusesLineItemDisplaysGet(districtId: string, state: string, options?: any): Promise<GetSchoolCampusLineItemsResponse>;

    /**
     * Sample Request:                    GET /Districts
     * @summary Gets a list of all districts within the current database. Excludes districts which have been deleted.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsGet(state: string, options?: any): Promise<GetDistrictsResponse>;

    /**
     * 
     * @summary Get Progress Report Date for specific campus.
     * @param {string} id The district Id
     * @param {string} campusId The campus Id
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsIdCampusProgressReportDateCampusIdGet(id: string, campusId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary gets a district by id and deletes it
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsIdDeletePatch(id: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Get Non School Days for specific district.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsIdDistrictNonSchoolDaysGet(id: string, state: string, options?: any): Promise<GetNonSchoolDatesResponse>;

    /**
     * 
     * @summary Get Progress Report Date for specific district.
     * @param {string} id The district ID
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsIdDistrictProgressReportDateGet(id: string, state: string, options?: any): Promise<{}>;

    /**
     * Sample Request:                    GET /Districts/{districtId}
     * @summary Gets a single District Profile specidified by ID.
     * @param {string} id The ID of the district for which to retreive a District Profile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsIdGet(id: string, state: string, options?: any): Promise<DistrictResponse>;

    /**
     * 
     * @summary Updates a District profile by Id
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateDistrictRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsIdPut(id: string, state: string, body?: UpdateDistrictRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying it as an option in a dropdown. This should be as slim as possible for that purpose.
     * @param {string} id The Id of the district for which to get the list of school campuses.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsIdSchoolCampusesDropdownDisplaysGet(id: string, state: string, options?: any): Promise<GetSchoolCampusDropdownsResponse>;

    /**
     * 
     * @summary Gets a single school campus for the specified district.
     * @param {string} id The Id of the district for which to get the school campus.
     * @param {string} schoolCampusId The Id of the school campus to retrieve.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsIdSchoolCampusesSchoolCampusIdGet(id: string, schoolCampusId: string, state: string, options?: any): Promise<SchoolCampusResponse>;

    /**
     * 
     * @summary Restores a deleted District.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsIdUndoDeletePatch(id: string, state: string, options?: any): Promise<DistrictResponse>;

    /**
     * 
     * @summary Creates a new district.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateDistrictRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsPost(state: string, body?: CreateDistrictRequest, options?: any): Promise<DistrictResponse>;

    /**
     * 
     * @summary If CampusId is specified, this will set the progress report dates for the specified campus.   Only if CampusId is null will this endpoint set the district progress report dates for the specified district.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchDistrictClientProgressReportDateRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsProgressReportDatePatch(state: string, body?: PatchDistrictClientProgressReportDateRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying it as an option in a dropdown. This should be as slim as possible for that purpose.
     * @param {string} districtIds A comma-separated list of district Ids for which to get the list of school campuses.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApiInterface
     */
    v1DistrictsSchoolCampusesDropdownDisplaysGet(districtIds: string, state: string, options?: any): Promise<GetSchoolCampusDropdownsResponse>;

}

/**
 * DistrictsApi - object-oriented interface
 * @export
 * @class DistrictsApi
 * @extends {BaseAPI}
 */
export class DistrictsApi extends BaseAPI implements DistrictsApiInterface {
    /**
     * Sample Request:                    GET /Districts/Deleted
     * @summary Gets a list of all deleted districts within the database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsDeletedGet(state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsDeletedGet(state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Adds a campus to a district profile
     * @param {string} districtId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {AddSchoolCampusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsDistrictIdCampusesAddPatch(districtId: string, state: string, body?: AddSchoolCampusRequest, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsDistrictIdCampusesAddPatch(districtId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Removes a list specified campuses from a district profile.
     * @param {string} districtId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RemoveCampusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsDistrictIdCampusesRemovePatch(districtId: string, state: string, body?: RemoveCampusRequest, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsDistrictIdCampusesRemovePatch(districtId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Updates a specified campus to a district profile.
     * @param {string} districtId 
     * @param {string} campusId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateSchoolCampusRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsDistrictIdCampusesUpdatePatch(districtId: string, campusId: string, state: string, body?: UpdateSchoolCampusRequest, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsDistrictIdCampusesUpdatePatch(districtId, campusId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying Line Items in a dropdown. This should be as slim as possible for that purpose.
     * @param {string} districtId The Id of the district for which to get the list of school campuses.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsDistrictIdSchoolCampusesLineItemDisplaysGet(districtId: string, state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsDistrictIdSchoolCampusesLineItemDisplaysGet(districtId, state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /Districts
     * @summary Gets a list of all districts within the current database. Excludes districts which have been deleted.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsGet(state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsGet(state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Progress Report Date for specific campus.
     * @param {string} id The district Id
     * @param {string} campusId The campus Id
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsIdCampusProgressReportDateCampusIdGet(id: string, campusId: string, state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsIdCampusProgressReportDateCampusIdGet(id, campusId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary gets a district by id and deletes it
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsIdDeletePatch(id: string, state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsIdDeletePatch(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Non School Days for specific district.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsIdDistrictNonSchoolDaysGet(id: string, state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsIdDistrictNonSchoolDaysGet(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Progress Report Date for specific district.
     * @param {string} id The district ID
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsIdDistrictProgressReportDateGet(id: string, state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsIdDistrictProgressReportDateGet(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /Districts/{districtId}
     * @summary Gets a single District Profile specidified by ID.
     * @param {string} id The ID of the district for which to retreive a District Profile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsIdGet(id: string, state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsIdGet(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Updates a District profile by Id
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateDistrictRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsIdPut(id: string, state: string, body?: UpdateDistrictRequest, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsIdPut(id, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying it as an option in a dropdown. This should be as slim as possible for that purpose.
     * @param {string} id The Id of the district for which to get the list of school campuses.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsIdSchoolCampusesDropdownDisplaysGet(id: string, state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsIdSchoolCampusesDropdownDisplaysGet(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets a single school campus for the specified district.
     * @param {string} id The Id of the district for which to get the school campus.
     * @param {string} schoolCampusId The Id of the school campus to retrieve.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsIdSchoolCampusesSchoolCampusIdGet(id: string, schoolCampusId: string, state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsIdSchoolCampusesSchoolCampusIdGet(id, schoolCampusId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Restores a deleted District.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsIdUndoDeletePatch(id: string, state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsIdUndoDeletePatch(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Creates a new district.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateDistrictRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsPost(state: string, body?: CreateDistrictRequest, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary If CampusId is specified, this will set the progress report dates for the specified campus.   Only if CampusId is null will this endpoint set the district progress report dates for the specified district.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchDistrictClientProgressReportDateRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsProgressReportDatePatch(state: string, body?: PatchDistrictClientProgressReportDateRequest, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsProgressReportDatePatch(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The purpose of this endpoint is to get the campus model info for purposes of displaying it as an option in a dropdown. This should be as slim as possible for that purpose.
     * @param {string} districtIds A comma-separated list of district Ids for which to get the list of school campuses.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictsApi
     */
    public v1DistrictsSchoolCampusesDropdownDisplaysGet(districtIds: string, state: string, options?: any) {
        return DistrictsApiFp(this.configuration).v1DistrictsSchoolCampusesDropdownDisplaysGet(districtIds, state, options)(this.fetch, this.basePath);
    }

}
/**
 * PrescribingProvidersApi - fetch parameter creator
 * @export
 */
export const PrescribingProvidersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all prescribing providers.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersGet(clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1PrescribingProvidersGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1PrescribingProvidersGet.');
            }
            const localVarPath = `/v1/PrescribingProviders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a specified prescribing provider by id.
         * @param {string} id The ID of the prescribing provider to delete.
         * @param {string} clientId The ID of the client to delete the prescribing provider from.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersIdDeletePatch(id: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1PrescribingProvidersIdDeletePatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1PrescribingProvidersIdDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1PrescribingProvidersIdDeletePatch.');
            }
            const localVarPath = `/v1/PrescribingProviders/{id}/Delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchPrescribingProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersIdPatch(id: string, clientId: string, state: string, body?: PatchPrescribingProviderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1PrescribingProvidersIdPatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1PrescribingProvidersIdPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1PrescribingProvidersIdPatch.');
            }
            const localVarPath = `/v1/PrescribingProviders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchPrescribingProviderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new prescribing provider in the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreatePrescribingProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersPost(state: string, body?: CreatePrescribingProviderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1PrescribingProvidersPost.');
            }
            const localVarPath = `/v1/PrescribingProviders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreatePrescribingProviderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrescribingProvidersApi - functional programming interface
 * @export
 */
export const PrescribingProvidersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all prescribing providers.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersGet(clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetPrescribingProvidersResponse> {
            const localVarFetchArgs = PrescribingProvidersApiFetchParamCreator(configuration).v1PrescribingProvidersGet(clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Deletes a specified prescribing provider by id.
         * @param {string} id The ID of the prescribing provider to delete.
         * @param {string} clientId The ID of the client to delete the prescribing provider from.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersIdDeletePatch(id: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PrescribingProvidersApiFetchParamCreator(configuration).v1PrescribingProvidersIdDeletePatch(id, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchPrescribingProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersIdPatch(id: string, clientId: string, state: string, body?: PatchPrescribingProviderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PrescribingProvidersApiFetchParamCreator(configuration).v1PrescribingProvidersIdPatch(id, clientId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Creates a new prescribing provider in the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreatePrescribingProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersPost(state: string, body?: CreatePrescribingProviderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PrescribingProviderResponse> {
            const localVarFetchArgs = PrescribingProvidersApiFetchParamCreator(configuration).v1PrescribingProvidersPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PrescribingProvidersApi - factory interface
 * @export
 */
export const PrescribingProvidersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Gets all prescribing providers.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersGet(clientId: string, state: string, options?: any) {
            return PrescribingProvidersApiFp(configuration).v1PrescribingProvidersGet(clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Deletes a specified prescribing provider by id.
         * @param {string} id The ID of the prescribing provider to delete.
         * @param {string} clientId The ID of the client to delete the prescribing provider from.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersIdDeletePatch(id: string, clientId: string, state: string, options?: any) {
            return PrescribingProvidersApiFp(configuration).v1PrescribingProvidersIdDeletePatch(id, clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Depricated
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchPrescribingProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersIdPatch(id: string, clientId: string, state: string, body?: PatchPrescribingProviderRequest, options?: any) {
            return PrescribingProvidersApiFp(configuration).v1PrescribingProvidersIdPatch(id, clientId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Creates a new prescribing provider in the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreatePrescribingProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PrescribingProvidersPost(state: string, body?: CreatePrescribingProviderRequest, options?: any) {
            return PrescribingProvidersApiFp(configuration).v1PrescribingProvidersPost(state, body, options)(fetch, basePath);
        },
    };
};

/**
 * PrescribingProvidersApi - interface
 * @export
 * @interface PrescribingProvidersApi
 */
export interface PrescribingProvidersApiInterface {
    /**
     * 
     * @summary Gets all prescribing providers.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescribingProvidersApiInterface
     */
    v1PrescribingProvidersGet(clientId: string, state: string, options?: any): Promise<GetPrescribingProvidersResponse>;

    /**
     * 
     * @summary Deletes a specified prescribing provider by id.
     * @param {string} id The ID of the prescribing provider to delete.
     * @param {string} clientId The ID of the client to delete the prescribing provider from.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescribingProvidersApiInterface
     */
    v1PrescribingProvidersIdDeletePatch(id: string, clientId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Depricated
     * @param {string} id 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchPrescribingProviderRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescribingProvidersApiInterface
     */
    v1PrescribingProvidersIdPatch(id: string, clientId: string, state: string, body?: PatchPrescribingProviderRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary Creates a new prescribing provider in the database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreatePrescribingProviderRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescribingProvidersApiInterface
     */
    v1PrescribingProvidersPost(state: string, body?: CreatePrescribingProviderRequest, options?: any): Promise<PrescribingProviderResponse>;

}

/**
 * PrescribingProvidersApi - object-oriented interface
 * @export
 * @class PrescribingProvidersApi
 * @extends {BaseAPI}
 */
export class PrescribingProvidersApi extends BaseAPI implements PrescribingProvidersApiInterface {
    /**
     * 
     * @summary Gets all prescribing providers.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescribingProvidersApi
     */
    public v1PrescribingProvidersGet(clientId: string, state: string, options?: any) {
        return PrescribingProvidersApiFp(this.configuration).v1PrescribingProvidersGet(clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Deletes a specified prescribing provider by id.
     * @param {string} id The ID of the prescribing provider to delete.
     * @param {string} clientId The ID of the client to delete the prescribing provider from.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescribingProvidersApi
     */
    public v1PrescribingProvidersIdDeletePatch(id: string, clientId: string, state: string, options?: any) {
        return PrescribingProvidersApiFp(this.configuration).v1PrescribingProvidersIdDeletePatch(id, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Depricated
     * @param {string} id 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchPrescribingProviderRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescribingProvidersApi
     */
    public v1PrescribingProvidersIdPatch(id: string, clientId: string, state: string, body?: PatchPrescribingProviderRequest, options?: any) {
        return PrescribingProvidersApiFp(this.configuration).v1PrescribingProvidersIdPatch(id, clientId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Creates a new prescribing provider in the database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreatePrescribingProviderRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrescribingProvidersApi
     */
    public v1PrescribingProvidersPost(state: string, body?: CreatePrescribingProviderRequest, options?: any) {
        return PrescribingProvidersApiFp(this.configuration).v1PrescribingProvidersPost(state, body, options)(this.fetch, this.basePath);
    }

}
/**
 * ServiceProvidersApi - fetch parameter creator
 * @export
 */
export const ServiceProvidersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get paginated report of staff directory for a specific client.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {StaffDirectorySortableColumnType} [sortBy] 
         * @param {OrderByDirection} [sortDirection] 
         * @param {boolean} [showInactives] 
         * @param {string} [searchTerm] 
         * @param {string} [districtFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersClientIdGetStaffDirectoryGet(clientId: string, state: string, pageNumber?: number, pageSize?: number, sortBy?: StaffDirectorySortableColumnType, sortDirection?: OrderByDirection, showInactives?: boolean, searchTerm?: string, districtFilter?: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersClientIdGetStaffDirectoryGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersClientIdGetStaffDirectoryGet.');
            }
            const localVarPath = `/v1/ServiceProviders/{clientId}/GetStaffDirectory`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }

            if (showInactives !== undefined) {
                localVarQueryParameter['ShowInactives'] = showInactives;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (districtFilter !== undefined) {
                localVarQueryParameter['DistrictFilter'] = districtFilter;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all deleted service providers.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersDeletedGet(clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersDeletedGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersDeletedGet.');
            }
            const localVarPath = `/v1/ServiceProviders/Deleted`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all service providers.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersGet(clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersGet.');
            }
            const localVarPath = `/v1/ServiceProviders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all service providers in a certain campus for drop down menu.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [campusIdsString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersGetAllServiceProvidersInCampusesGet(clientId: string, state: string, campusIdsString?: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersGetAllServiceProvidersInCampusesGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersGetAllServiceProvidersInCampusesGet.');
            }
            const localVarPath = `/v1/ServiceProviders/GetAllServiceProvidersInCampuses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (campusIdsString !== undefined) {
                localVarQueryParameter['campusIdsString'] = campusIdsString;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a specified service provider by id.
         * @param {string} id The ID of the service provider to delete.
         * @param {string} clientId The ID of the client to delete the service provider from.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdDeletePatch(id: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ServiceProvidersIdDeletePatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersIdDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersIdDeletePatch.');
            }
            const localVarPath = `/v1/ServiceProviders/{id}/Delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a specific service provider by id in a given client, specified by the client id.
         * @param {string} id The id of the service provider to retrieve.
         * @param {string} clientId The client in which to get the specified service provider.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdGet(id: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ServiceProvidersIdGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersIdGet.');
            }
            const localVarPath = `/v1/ServiceProviders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a specific service provider by id, without needing a client id. This endpoint is for developers only, and not intended for use in the UI.
         * @param {string} id The id of the service provider to retrieve.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdGetProviderWithoutClientIdGet(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ServiceProvidersIdGetProviderWithoutClientIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersIdGetProviderWithoutClientIdGet.');
            }
            const localVarPath = `/v1/ServiceProviders/{id}/GetProviderWithoutClientId`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patches a service provider in the database, updating only the fields that are provided in the request.
         * @param {string} id The ID of the service provider to patch.
         * @param {string} clientId The ID of the client for which to patch the ServiceProviderProfile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchServiceProviderRequest} [body] 
         * @param {string} [userId] The ID of the user who is patching the serivce provider profile. This parameter must be provided if the user  modifying the provider is the same person as the service provider. This will set the  service provider profile as the user&#x27;s ServiceProviderProfile in the user&#x27;s ClientAssignment field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdPatch(id: string, clientId: string, state: string, body?: PatchServiceProviderRequest, userId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ServiceProvidersIdPatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersIdPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersIdPatch.');
            }
            const localVarPath = `/v1/ServiceProviders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchServiceProviderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateServiceProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdPut(id: string, state: string, body?: UpdateServiceProviderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ServiceProvidersIdPut.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersIdPut.');
            }
            const localVarPath = `/v1/ServiceProviders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateServiceProviderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The endpoint to add a student to a service provider's caseload.
         * @param {string} id The ID of the service provider for whom to add the student to their caseload.
         * @param {string} clientId The ID of the client that the service provider and student are operating-in/attending.
         * @param {string} studentId The ID of the student to add to the service provider&#x27;s caseload.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdStudentCaseloadAddStudentPatch(id: string, clientId: string, studentId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ServiceProvidersIdStudentCaseloadAddStudentPatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersIdStudentCaseloadAddStudentPatch.');
            }
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling v1ServiceProvidersIdStudentCaseloadAddStudentPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersIdStudentCaseloadAddStudentPatch.');
            }
            const localVarPath = `/v1/ServiceProviders/{id}/StudentCaseload/AddStudent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The endpoint to remove student(s) from a service provider's caseload.
         * @param {Array<string>} body The ID of the students to remove from the service provider&#x27;s caseload.
         * @param {string} id The ID of the service provider for whom to remove the student from their caseload.
         * @param {string} clientId The ID of the client that the service provider is operating in.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch(body: Array<string>, id: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch.');
            }
            const localVarPath = `/v1/ServiceProviders/{id}/StudentCaseload/RemoveStudents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restores a deleted Service Provider profile by a specific Service Provider ID
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdUndoDeletePatch(id: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1ServiceProvidersIdUndoDeletePatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersIdUndoDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersIdUndoDeletePatch.');
            }
            const localVarPath = `/v1/ServiceProviders/{id}/UndoDelete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary THIS IS NOT FOR USE IN PRODUCTION ONLY FOR TESTING PURPOSES. This is a method to delete all service providers with a specific legacy ID.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [legacyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersNukeServiceProviderDelete(clientId: string, state: string, legacyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1ServiceProvidersNukeServiceProviderDelete.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersNukeServiceProviderDelete.');
            }
            const localVarPath = `/v1/ServiceProviders/NukeServiceProvider`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (legacyId !== undefined) {
                localVarQueryParameter['legacyId'] = legacyId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new service provider in the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateServiceProviderRequest} [body] 
         * @param {string} [userId] The ID of the user who is creating the serivce provider profile. This parameter must be provided if the user  creating the provider is the same person as the service provider. This will set the newly created  service provider profile as the user&#x27;s service provider profile in the user&#x27;s ClientAssignment field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersPost(state: string, body?: CreateServiceProviderRequest, userId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersPost.');
            }
            const localVarPath = `/v1/ServiceProviders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateServiceProviderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get response with all the service provider's caseloads.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet(serviceProviderId: string, state: string, clientId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet.');
            }
            const localVarPath = `/v1/ServiceProviders/{serviceProviderId}/GetServiceProviderCaseload`
                .replace(`{${"serviceProviderId"}}`, encodeURIComponent(String(serviceProviderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceProvidersApi - functional programming interface
 * @export
 */
export const ServiceProvidersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get paginated report of staff directory for a specific client.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {StaffDirectorySortableColumnType} [sortBy] 
         * @param {OrderByDirection} [sortDirection] 
         * @param {boolean} [showInactives] 
         * @param {string} [searchTerm] 
         * @param {string} [districtFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersClientIdGetStaffDirectoryGet(clientId: string, state: string, pageNumber?: number, pageSize?: number, sortBy?: StaffDirectorySortableColumnType, sortDirection?: OrderByDirection, showInactives?: boolean, searchTerm?: string, districtFilter?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetStaffDirectoryResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersClientIdGetStaffDirectoryGet(clientId, state, pageNumber, pageSize, sortBy, sortDirection, showInactives, searchTerm, districtFilter, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all deleted service providers.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersDeletedGet(clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetServiceProvidersResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersDeletedGet(clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all service providers.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersGet(clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetServiceProvidersResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersGet(clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all service providers in a certain campus for drop down menu.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [campusIdsString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersGetAllServiceProvidersInCampusesGet(clientId: string, state: string, campusIdsString?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetProviderCaseloadResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersGetAllServiceProvidersInCampusesGet(clientId, state, campusIdsString, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Deletes a specified service provider by id.
         * @param {string} id The ID of the service provider to delete.
         * @param {string} clientId The ID of the client to delete the service provider from.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdDeletePatch(id: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersIdDeletePatch(id, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets a specific service provider by id in a given client, specified by the client id.
         * @param {string} id The id of the service provider to retrieve.
         * @param {string} clientId The client in which to get the specified service provider.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdGet(id: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceProviderResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersIdGet(id, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets a specific service provider by id, without needing a client id. This endpoint is for developers only, and not intended for use in the UI.
         * @param {string} id The id of the service provider to retrieve.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdGetProviderWithoutClientIdGet(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceProviderResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersIdGetProviderWithoutClientIdGet(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Patches a service provider in the database, updating only the fields that are provided in the request.
         * @param {string} id The ID of the service provider to patch.
         * @param {string} clientId The ID of the client for which to patch the ServiceProviderProfile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchServiceProviderRequest} [body] 
         * @param {string} [userId] The ID of the user who is patching the serivce provider profile. This parameter must be provided if the user  modifying the provider is the same person as the service provider. This will set the  service provider profile as the user&#x27;s ServiceProviderProfile in the user&#x27;s ClientAssignment field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdPatch(id: string, clientId: string, state: string, body?: PatchServiceProviderRequest, userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceProviderResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersIdPatch(id, clientId, state, body, userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateServiceProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdPut(id: string, state: string, body?: UpdateServiceProviderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersIdPut(id, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The endpoint to add a student to a service provider's caseload.
         * @param {string} id The ID of the service provider for whom to add the student to their caseload.
         * @param {string} clientId The ID of the client that the service provider and student are operating-in/attending.
         * @param {string} studentId The ID of the student to add to the service provider&#x27;s caseload.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdStudentCaseloadAddStudentPatch(id: string, clientId: string, studentId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceProviderResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersIdStudentCaseloadAddStudentPatch(id, clientId, studentId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The endpoint to remove student(s) from a service provider's caseload.
         * @param {Array<string>} body The ID of the students to remove from the service provider&#x27;s caseload.
         * @param {string} id The ID of the service provider for whom to remove the student from their caseload.
         * @param {string} clientId The ID of the client that the service provider is operating in.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch(body: Array<string>, id: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceProviderResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch(body, id, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Restores a deleted Service Provider profile by a specific Service Provider ID
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdUndoDeletePatch(id: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceProviderResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersIdUndoDeletePatch(id, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary THIS IS NOT FOR USE IN PRODUCTION ONLY FOR TESTING PURPOSES. This is a method to delete all service providers with a specific legacy ID.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [legacyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersNukeServiceProviderDelete(clientId: string, state: string, legacyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersNukeServiceProviderDelete(clientId, state, legacyId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Creates a new service provider in the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateServiceProviderRequest} [body] 
         * @param {string} [userId] The ID of the user who is creating the serivce provider profile. This parameter must be provided if the user  creating the provider is the same person as the service provider. This will set the newly created  service provider profile as the user&#x27;s service provider profile in the user&#x27;s ClientAssignment field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersPost(state: string, body?: CreateServiceProviderRequest, userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceProviderResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersPost(state, body, userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get response with all the service provider's caseloads.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet(serviceProviderId: string, state: string, clientId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetCaseloadResponse> {
            const localVarFetchArgs = ServiceProvidersApiFetchParamCreator(configuration).v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet(serviceProviderId, state, clientId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ServiceProvidersApi - factory interface
 * @export
 */
export const ServiceProvidersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get paginated report of staff directory for a specific client.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {StaffDirectorySortableColumnType} [sortBy] 
         * @param {OrderByDirection} [sortDirection] 
         * @param {boolean} [showInactives] 
         * @param {string} [searchTerm] 
         * @param {string} [districtFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersClientIdGetStaffDirectoryGet(clientId: string, state: string, pageNumber?: number, pageSize?: number, sortBy?: StaffDirectorySortableColumnType, sortDirection?: OrderByDirection, showInactives?: boolean, searchTerm?: string, districtFilter?: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersClientIdGetStaffDirectoryGet(clientId, state, pageNumber, pageSize, sortBy, sortDirection, showInactives, searchTerm, districtFilter, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all deleted service providers.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersDeletedGet(clientId: string, state: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersDeletedGet(clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all service providers.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersGet(clientId: string, state: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersGet(clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all service providers in a certain campus for drop down menu.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [campusIdsString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersGetAllServiceProvidersInCampusesGet(clientId: string, state: string, campusIdsString?: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersGetAllServiceProvidersInCampusesGet(clientId, state, campusIdsString, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Deletes a specified service provider by id.
         * @param {string} id The ID of the service provider to delete.
         * @param {string} clientId The ID of the client to delete the service provider from.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdDeletePatch(id: string, clientId: string, state: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersIdDeletePatch(id, clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets a specific service provider by id in a given client, specified by the client id.
         * @param {string} id The id of the service provider to retrieve.
         * @param {string} clientId The client in which to get the specified service provider.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdGet(id: string, clientId: string, state: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersIdGet(id, clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets a specific service provider by id, without needing a client id. This endpoint is for developers only, and not intended for use in the UI.
         * @param {string} id The id of the service provider to retrieve.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdGetProviderWithoutClientIdGet(id: string, state: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersIdGetProviderWithoutClientIdGet(id, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Patches a service provider in the database, updating only the fields that are provided in the request.
         * @param {string} id The ID of the service provider to patch.
         * @param {string} clientId The ID of the client for which to patch the ServiceProviderProfile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchServiceProviderRequest} [body] 
         * @param {string} [userId] The ID of the user who is patching the serivce provider profile. This parameter must be provided if the user  modifying the provider is the same person as the service provider. This will set the  service provider profile as the user&#x27;s ServiceProviderProfile in the user&#x27;s ClientAssignment field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdPatch(id: string, clientId: string, state: string, body?: PatchServiceProviderRequest, userId?: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersIdPatch(id, clientId, state, body, userId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Depricated
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateServiceProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdPut(id: string, state: string, body?: UpdateServiceProviderRequest, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersIdPut(id, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The endpoint to add a student to a service provider's caseload.
         * @param {string} id The ID of the service provider for whom to add the student to their caseload.
         * @param {string} clientId The ID of the client that the service provider and student are operating-in/attending.
         * @param {string} studentId The ID of the student to add to the service provider&#x27;s caseload.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdStudentCaseloadAddStudentPatch(id: string, clientId: string, studentId: string, state: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersIdStudentCaseloadAddStudentPatch(id, clientId, studentId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The endpoint to remove student(s) from a service provider's caseload.
         * @param {Array<string>} body The ID of the students to remove from the service provider&#x27;s caseload.
         * @param {string} id The ID of the service provider for whom to remove the student from their caseload.
         * @param {string} clientId The ID of the client that the service provider is operating in.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch(body: Array<string>, id: string, clientId: string, state: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch(body, id, clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Restores a deleted Service Provider profile by a specific Service Provider ID
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersIdUndoDeletePatch(id: string, clientId: string, state: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersIdUndoDeletePatch(id, clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary THIS IS NOT FOR USE IN PRODUCTION ONLY FOR TESTING PURPOSES. This is a method to delete all service providers with a specific legacy ID.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [legacyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersNukeServiceProviderDelete(clientId: string, state: string, legacyId?: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersNukeServiceProviderDelete(clientId, state, legacyId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Creates a new service provider in the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateServiceProviderRequest} [body] 
         * @param {string} [userId] The ID of the user who is creating the serivce provider profile. This parameter must be provided if the user  creating the provider is the same person as the service provider. This will set the newly created  service provider profile as the user&#x27;s service provider profile in the user&#x27;s ClientAssignment field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersPost(state: string, body?: CreateServiceProviderRequest, userId?: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersPost(state, body, userId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get response with all the service provider's caseloads.
         * @param {string} serviceProviderId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet(serviceProviderId: string, state: string, clientId?: string, options?: any) {
            return ServiceProvidersApiFp(configuration).v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet(serviceProviderId, state, clientId, options)(fetch, basePath);
        },
    };
};

/**
 * ServiceProvidersApi - interface
 * @export
 * @interface ServiceProvidersApi
 */
export interface ServiceProvidersApiInterface {
    /**
     * 
     * @summary Get paginated report of staff directory for a specific client.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {StaffDirectorySortableColumnType} [sortBy] 
     * @param {OrderByDirection} [sortDirection] 
     * @param {boolean} [showInactives] 
     * @param {string} [searchTerm] 
     * @param {string} [districtFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersClientIdGetStaffDirectoryGet(clientId: string, state: string, pageNumber?: number, pageSize?: number, sortBy?: StaffDirectorySortableColumnType, sortDirection?: OrderByDirection, showInactives?: boolean, searchTerm?: string, districtFilter?: string, options?: any): Promise<GetStaffDirectoryResponse>;

    /**
     * 
     * @summary Gets all deleted service providers.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersDeletedGet(clientId: string, state: string, options?: any): Promise<GetServiceProvidersResponse>;

    /**
     * 
     * @summary Gets all service providers.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersGet(clientId: string, state: string, options?: any): Promise<GetServiceProvidersResponse>;

    /**
     * 
     * @summary Gets all service providers in a certain campus for drop down menu.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [campusIdsString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersGetAllServiceProvidersInCampusesGet(clientId: string, state: string, campusIdsString?: string, options?: any): Promise<GetProviderCaseloadResponse>;

    /**
     * 
     * @summary Deletes a specified service provider by id.
     * @param {string} id The ID of the service provider to delete.
     * @param {string} clientId The ID of the client to delete the service provider from.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersIdDeletePatch(id: string, clientId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Gets a specific service provider by id in a given client, specified by the client id.
     * @param {string} id The id of the service provider to retrieve.
     * @param {string} clientId The client in which to get the specified service provider.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersIdGet(id: string, clientId: string, state: string, options?: any): Promise<ServiceProviderResponse>;

    /**
     * 
     * @summary Gets a specific service provider by id, without needing a client id. This endpoint is for developers only, and not intended for use in the UI.
     * @param {string} id The id of the service provider to retrieve.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersIdGetProviderWithoutClientIdGet(id: string, state: string, options?: any): Promise<ServiceProviderResponse>;

    /**
     * 
     * @summary Patches a service provider in the database, updating only the fields that are provided in the request.
     * @param {string} id The ID of the service provider to patch.
     * @param {string} clientId The ID of the client for which to patch the ServiceProviderProfile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchServiceProviderRequest} [body] 
     * @param {string} [userId] The ID of the user who is patching the serivce provider profile. This parameter must be provided if the user  modifying the provider is the same person as the service provider. This will set the  service provider profile as the user&#x27;s ServiceProviderProfile in the user&#x27;s ClientAssignment field.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersIdPatch(id: string, clientId: string, state: string, body?: PatchServiceProviderRequest, userId?: string, options?: any): Promise<ServiceProviderResponse>;

    /**
     * 
     * @summary Depricated
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateServiceProviderRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersIdPut(id: string, state: string, body?: UpdateServiceProviderRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary The endpoint to add a student to a service provider's caseload.
     * @param {string} id The ID of the service provider for whom to add the student to their caseload.
     * @param {string} clientId The ID of the client that the service provider and student are operating-in/attending.
     * @param {string} studentId The ID of the student to add to the service provider&#x27;s caseload.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersIdStudentCaseloadAddStudentPatch(id: string, clientId: string, studentId: string, state: string, options?: any): Promise<ServiceProviderResponse>;

    /**
     * 
     * @summary The endpoint to remove student(s) from a service provider's caseload.
     * @param {Array<string>} body The ID of the students to remove from the service provider&#x27;s caseload.
     * @param {string} id The ID of the service provider for whom to remove the student from their caseload.
     * @param {string} clientId The ID of the client that the service provider is operating in.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch(body: Array<string>, id: string, clientId: string, state: string, options?: any): Promise<ServiceProviderResponse>;

    /**
     * 
     * @summary Restores a deleted Service Provider profile by a specific Service Provider ID
     * @param {string} id 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersIdUndoDeletePatch(id: string, clientId: string, state: string, options?: any): Promise<ServiceProviderResponse>;

    /**
     * 
     * @summary THIS IS NOT FOR USE IN PRODUCTION ONLY FOR TESTING PURPOSES. This is a method to delete all service providers with a specific legacy ID.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [legacyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersNukeServiceProviderDelete(clientId: string, state: string, legacyId?: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Creates a new service provider in the database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateServiceProviderRequest} [body] 
     * @param {string} [userId] The ID of the user who is creating the serivce provider profile. This parameter must be provided if the user  creating the provider is the same person as the service provider. This will set the newly created  service provider profile as the user&#x27;s service provider profile in the user&#x27;s ClientAssignment field.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersPost(state: string, body?: CreateServiceProviderRequest, userId?: string, options?: any): Promise<ServiceProviderResponse>;

    /**
     * 
     * @summary Get response with all the service provider's caseloads.
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApiInterface
     */
    v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet(serviceProviderId: string, state: string, clientId?: string, options?: any): Promise<GetCaseloadResponse>;

}

/**
 * ServiceProvidersApi - object-oriented interface
 * @export
 * @class ServiceProvidersApi
 * @extends {BaseAPI}
 */
export class ServiceProvidersApi extends BaseAPI implements ServiceProvidersApiInterface {
    /**
     * 
     * @summary Get paginated report of staff directory for a specific client.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {StaffDirectorySortableColumnType} [sortBy] 
     * @param {OrderByDirection} [sortDirection] 
     * @param {boolean} [showInactives] 
     * @param {string} [searchTerm] 
     * @param {string} [districtFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersClientIdGetStaffDirectoryGet(clientId: string, state: string, pageNumber?: number, pageSize?: number, sortBy?: StaffDirectorySortableColumnType, sortDirection?: OrderByDirection, showInactives?: boolean, searchTerm?: string, districtFilter?: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersClientIdGetStaffDirectoryGet(clientId, state, pageNumber, pageSize, sortBy, sortDirection, showInactives, searchTerm, districtFilter, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all deleted service providers.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersDeletedGet(clientId: string, state: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersDeletedGet(clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all service providers.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersGet(clientId: string, state: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersGet(clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all service providers in a certain campus for drop down menu.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [campusIdsString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersGetAllServiceProvidersInCampusesGet(clientId: string, state: string, campusIdsString?: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersGetAllServiceProvidersInCampusesGet(clientId, state, campusIdsString, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Deletes a specified service provider by id.
     * @param {string} id The ID of the service provider to delete.
     * @param {string} clientId The ID of the client to delete the service provider from.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersIdDeletePatch(id: string, clientId: string, state: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersIdDeletePatch(id, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets a specific service provider by id in a given client, specified by the client id.
     * @param {string} id The id of the service provider to retrieve.
     * @param {string} clientId The client in which to get the specified service provider.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersIdGet(id: string, clientId: string, state: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersIdGet(id, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets a specific service provider by id, without needing a client id. This endpoint is for developers only, and not intended for use in the UI.
     * @param {string} id The id of the service provider to retrieve.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersIdGetProviderWithoutClientIdGet(id: string, state: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersIdGetProviderWithoutClientIdGet(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Patches a service provider in the database, updating only the fields that are provided in the request.
     * @param {string} id The ID of the service provider to patch.
     * @param {string} clientId The ID of the client for which to patch the ServiceProviderProfile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchServiceProviderRequest} [body] 
     * @param {string} [userId] The ID of the user who is patching the serivce provider profile. This parameter must be provided if the user  modifying the provider is the same person as the service provider. This will set the  service provider profile as the user&#x27;s ServiceProviderProfile in the user&#x27;s ClientAssignment field.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersIdPatch(id: string, clientId: string, state: string, body?: PatchServiceProviderRequest, userId?: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersIdPatch(id, clientId, state, body, userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Depricated
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateServiceProviderRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersIdPut(id: string, state: string, body?: UpdateServiceProviderRequest, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersIdPut(id, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The endpoint to add a student to a service provider's caseload.
     * @param {string} id The ID of the service provider for whom to add the student to their caseload.
     * @param {string} clientId The ID of the client that the service provider and student are operating-in/attending.
     * @param {string} studentId The ID of the student to add to the service provider&#x27;s caseload.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersIdStudentCaseloadAddStudentPatch(id: string, clientId: string, studentId: string, state: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersIdStudentCaseloadAddStudentPatch(id, clientId, studentId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The endpoint to remove student(s) from a service provider's caseload.
     * @param {Array<string>} body The ID of the students to remove from the service provider&#x27;s caseload.
     * @param {string} id The ID of the service provider for whom to remove the student from their caseload.
     * @param {string} clientId The ID of the client that the service provider is operating in.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch(body: Array<string>, id: string, clientId: string, state: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersIdStudentCaseloadRemoveStudentsPatch(body, id, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Restores a deleted Service Provider profile by a specific Service Provider ID
     * @param {string} id 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersIdUndoDeletePatch(id: string, clientId: string, state: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersIdUndoDeletePatch(id, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary THIS IS NOT FOR USE IN PRODUCTION ONLY FOR TESTING PURPOSES. This is a method to delete all service providers with a specific legacy ID.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [legacyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersNukeServiceProviderDelete(clientId: string, state: string, legacyId?: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersNukeServiceProviderDelete(clientId, state, legacyId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Creates a new service provider in the database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateServiceProviderRequest} [body] 
     * @param {string} [userId] The ID of the user who is creating the serivce provider profile. This parameter must be provided if the user  creating the provider is the same person as the service provider. This will set the newly created  service provider profile as the user&#x27;s service provider profile in the user&#x27;s ClientAssignment field.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersPost(state: string, body?: CreateServiceProviderRequest, userId?: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersPost(state, body, userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get response with all the service provider's caseloads.
     * @param {string} serviceProviderId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProvidersApi
     */
    public v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet(serviceProviderId: string, state: string, clientId?: string, options?: any) {
        return ServiceProvidersApiFp(this.configuration).v1ServiceProvidersServiceProviderIdGetServiceProviderCaseloadGet(serviceProviderId, state, clientId, options)(this.fetch, this.basePath);
    }

}
/**
 * StateSnapshotsApi - fetch parameter creator
 * @export
 */
export const StateSnapshotsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the default care provisions for the specified service type and service area based on the state medicaid policy at the specified date/time.
         * @param {string} serviceTypeId The id of the service type for which to get the default care provisions.
         * @param {string} serviceAreaId The id of the service area for which to get the default care provisions.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] Specifies the point in time with which to view the service provider type inventory for this state.
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateDefaultCareProvisionsGet(serviceTypeId: string, serviceAreaId: string, state: string, date?: Date, clientId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceTypeId' is not null or undefined
            if (serviceTypeId === null || serviceTypeId === undefined) {
                throw new RequiredError('serviceTypeId','Required parameter serviceTypeId was null or undefined when calling v1StateSnapshotsByDateDefaultCareProvisionsGet.');
            }
            // verify required parameter 'serviceAreaId' is not null or undefined
            if (serviceAreaId === null || serviceAreaId === undefined) {
                throw new RequiredError('serviceAreaId','Required parameter serviceAreaId was null or undefined when calling v1StateSnapshotsByDateDefaultCareProvisionsGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsByDateDefaultCareProvisionsGet.');
            }
            const localVarPath = `/v1/StateSnapshots/ByDate/DefaultCareProvisions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (serviceTypeId !== undefined) {
                localVarQueryParameter['serviceTypeId'] = serviceTypeId;
            }

            if (serviceAreaId !== undefined) {
                localVarQueryParameter['serviceAreaId'] = serviceAreaId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /StateSnapshots/ByDate?date=2021-07-15
         * @summary Get the most recently added state snapshot that was active on the specified date.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] The date to retrieve active state snapshots.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateGet(state: string, date?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsByDateGet.');
            }
            const localVarPath = `/v1/StateSnapshots/ByDate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Takes a serviceId and a date and returns the notator sections for that service determined by the current StateSnapshot's medicaid policy.
         * @param {string} serviceId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateNotatorSectionsGet(serviceId: string, state: string, date?: Date, clientId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceId' is not null or undefined
            if (serviceId === null || serviceId === undefined) {
                throw new RequiredError('serviceId','Required parameter serviceId was null or undefined when calling v1StateSnapshotsByDateNotatorSectionsGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsByDateNotatorSectionsGet.');
            }
            const localVarPath = `/v1/StateSnapshots/ByDate/NotatorSections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['serviceId'] = serviceId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /StateSnapshots/ServiceAreas?date=2021-07-15
         * @summary Gets the list of active service areas for the specified date.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {Date} [date] The date to retrieve active service areas.
         * @param {boolean} [filterForMedicaidInfoOnly] Pass in true if you just want to filter for service areas that are required in the medicaid info tab of the student profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateServiceAreasGet(state: string, clientId?: string, date?: Date, filterForMedicaidInfoOnly?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsByDateServiceAreasGet.');
            }
            const localVarPath = `/v1/StateSnapshots/ByDate/ServiceAreas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (filterForMedicaidInfoOnly !== undefined) {
                localVarQueryParameter['filterForMedicaidInfoOnly'] = filterForMedicaidInfoOnly;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the service provider type inventory as it existed on the specified date.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {Date} [date] The date for which to get the service provider type inventory.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateServiceProviderTypesGet(state: string, clientId?: string, date?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsByDateServiceProviderTypesGet.');
            }
            const localVarPath = `/v1/StateSnapshots/ByDate/ServiceProviderTypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the allowed services for a specific service provider type based on the state medicaid policy at the specified date/time.  Allowed services includes all services that fall under the service area of that service provider type.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] Specifies the point in time with which to view the service provider type inventory for this state.
         * @param {string} [serviceProviderTypeId] The id of the service provider type for which
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateServicesByServiceProviderTypeGet(state: string, date?: Date, serviceProviderTypeId?: string, clientId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsByDateServicesByServiceProviderTypeGet.');
            }
            const localVarPath = `/v1/StateSnapshots/ByDate/Services/ByServiceProviderType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (serviceProviderTypeId !== undefined) {
                localVarQueryParameter['serviceProviderTypeId'] = serviceProviderTypeId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes all unused fields from the request body for creating a new state snapshot. NOTE: This does not create a state snapshot in the database. This is   simply for cleaning the request body. This is helpful for keeping the request body clean and up to date in source control, when making changes to the state profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateStateSnapshotRequest} [body] The request body for creating a new state snapshot. NOTE: Will not be used to create a new snapshot, this is simply for cleaning the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsCleanSchemaPost(state: string, body?: CreateStateSnapshotRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsCleanSchemaPost.');
            }
            const localVarPath = `/v1/StateSnapshots/CleanSchema`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateStateSnapshotRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /StateSnapshots
         * @summary Gets a list of all state snapshots.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsGet.');
            }
            const localVarPath = `/v1/StateSnapshots`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the goal areas of focus.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsGoalAreasOfFocusGet(state: string, date?: Date, clientId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsGoalAreasOfFocusGet.');
            }
            const localVarPath = `/v1/StateSnapshots/GoalAreasOfFocus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /StateSnapshots/{id}
         * @summary Gets a specific state snapshot by its id.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsIdGet(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StateSnapshotsIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsIdGet.');
            }
            const localVarPath = `/v1/StateSnapshots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    POST /StateSnapshots      {                    }
         * @summary Creates a new snapshot of the state profile. The snapshot will be active from the specified start date  unitl the specified end date. If the end date is null, the snapshot will be active indefinitely, or until  another snapshot supercedes it.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateStateSnapshotRequest} [body] The request body to create the state snapshot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsPost(state: string, body?: CreateStateSnapshotRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsPost.');
            }
            const localVarPath = `/v1/StateSnapshots`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateStateSnapshotRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    PUT /StateSnapshots      {                    }
         * @summary Updates the current state profile by adding a new state snapshot which is a copy of the most recently added state  snapshot active currently, with the fields specified in the request replacing the fields in the current state snapshot.  Any fields in the request body that are null will not be updated.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateStateSnapshotRequest} [body] The request body to update the state snapshot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsPut(state: string, body?: UpdateStateSnapshotRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsPut.');
            }
            const localVarPath = `/v1/StateSnapshots`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateStateSnapshotRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} serviceTypeId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsServicesByServiceTypeServiceTypeIdGet(serviceTypeId: string, state: string, date?: Date, clientId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceTypeId' is not null or undefined
            if (serviceTypeId === null || serviceTypeId === undefined) {
                throw new RequiredError('serviceTypeId','Required parameter serviceTypeId was null or undefined when calling v1StateSnapshotsServicesByServiceTypeServiceTypeIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StateSnapshotsServicesByServiceTypeServiceTypeIdGet.');
            }
            const localVarPath = `/v1/StateSnapshots/Services/ByServiceType/{serviceTypeId}`
                .replace(`{${"serviceTypeId"}}`, encodeURIComponent(String(serviceTypeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StateSnapshotsApi - functional programming interface
 * @export
 */
export const StateSnapshotsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the default care provisions for the specified service type and service area based on the state medicaid policy at the specified date/time.
         * @param {string} serviceTypeId The id of the service type for which to get the default care provisions.
         * @param {string} serviceAreaId The id of the service area for which to get the default care provisions.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] Specifies the point in time with which to view the service provider type inventory for this state.
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateDefaultCareProvisionsGet(serviceTypeId: string, serviceAreaId: string, state: string, date?: Date, clientId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DefaultCareProvisionsResponse> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsByDateDefaultCareProvisionsGet(serviceTypeId, serviceAreaId, state, date, clientId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /StateSnapshots/ByDate?date=2021-07-15
         * @summary Get the most recently added state snapshot that was active on the specified date.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] The date to retrieve active state snapshots.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateGet(state: string, date?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetStateSnapshotsResponse> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsByDateGet(state, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Takes a serviceId and a date and returns the notator sections for that service determined by the current StateSnapshot's medicaid policy.
         * @param {string} serviceId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateNotatorSectionsGet(serviceId: string, state: string, date?: Date, clientId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NotatorSectionResponse> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsByDateNotatorSectionsGet(serviceId, state, date, clientId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /StateSnapshots/ServiceAreas?date=2021-07-15
         * @summary Gets the list of active service areas for the specified date.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {Date} [date] The date to retrieve active service areas.
         * @param {boolean} [filterForMedicaidInfoOnly] Pass in true if you just want to filter for service areas that are required in the medicaid info tab of the student profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateServiceAreasGet(state: string, clientId?: string, date?: Date, filterForMedicaidInfoOnly?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetServiceAreasResponse> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsByDateServiceAreasGet(state, clientId, date, filterForMedicaidInfoOnly, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets the service provider type inventory as it existed on the specified date.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {Date} [date] The date for which to get the service provider type inventory.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateServiceProviderTypesGet(state: string, clientId?: string, date?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceProviderTypesResponse> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsByDateServiceProviderTypesGet(state, clientId, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets the allowed services for a specific service provider type based on the state medicaid policy at the specified date/time.  Allowed services includes all services that fall under the service area of that service provider type.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] Specifies the point in time with which to view the service provider type inventory for this state.
         * @param {string} [serviceProviderTypeId] The id of the service provider type for which
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateServicesByServiceProviderTypeGet(state: string, date?: Date, serviceProviderTypeId?: string, clientId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServicesByServiceProviderTypeResponse> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsByDateServicesByServiceProviderTypeGet(state, date, serviceProviderTypeId, clientId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Removes all unused fields from the request body for creating a new state snapshot. NOTE: This does not create a state snapshot in the database. This is   simply for cleaning the request body. This is helpful for keeping the request body clean and up to date in source control, when making changes to the state profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateStateSnapshotRequest} [body] The request body for creating a new state snapshot. NOTE: Will not be used to create a new snapshot, this is simply for cleaning the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsCleanSchemaPost(state: string, body?: CreateStateSnapshotRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateStateSnapshotRequest> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsCleanSchemaPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /StateSnapshots
         * @summary Gets a list of all state snapshots.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetStateSnapshotsResponse> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets the goal areas of focus.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsGoalAreasOfFocusGet(state: string, date?: Date, clientId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetGoalAreasOfFocusResponse> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsGoalAreasOfFocusGet(state, date, clientId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /StateSnapshots/{id}
         * @summary Gets a specific state snapshot by its id.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsIdGet(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StateSnapshotResponse> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsIdGet(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    POST /StateSnapshots      {                    }
         * @summary Creates a new snapshot of the state profile. The snapshot will be active from the specified start date  unitl the specified end date. If the end date is null, the snapshot will be active indefinitely, or until  another snapshot supercedes it.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateStateSnapshotRequest} [body] The request body to create the state snapshot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsPost(state: string, body?: CreateStateSnapshotRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StateSnapshotResponse> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    PUT /StateSnapshots      {                    }
         * @summary Updates the current state profile by adding a new state snapshot which is a copy of the most recently added state  snapshot active currently, with the fields specified in the request replacing the fields in the current state snapshot.  Any fields in the request body that are null will not be updated.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateStateSnapshotRequest} [body] The request body to update the state snapshot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsPut(state: string, body?: UpdateStateSnapshotRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsPut(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} serviceTypeId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsServicesByServiceTypeServiceTypeIdGet(serviceTypeId: string, state: string, date?: Date, clientId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StateSnapshotsApiFetchParamCreator(configuration).v1StateSnapshotsServicesByServiceTypeServiceTypeIdGet(serviceTypeId, state, date, clientId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StateSnapshotsApi - factory interface
 * @export
 */
export const StateSnapshotsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Gets the default care provisions for the specified service type and service area based on the state medicaid policy at the specified date/time.
         * @param {string} serviceTypeId The id of the service type for which to get the default care provisions.
         * @param {string} serviceAreaId The id of the service area for which to get the default care provisions.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] Specifies the point in time with which to view the service provider type inventory for this state.
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateDefaultCareProvisionsGet(serviceTypeId: string, serviceAreaId: string, state: string, date?: Date, clientId?: string, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsByDateDefaultCareProvisionsGet(serviceTypeId, serviceAreaId, state, date, clientId, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /StateSnapshots/ByDate?date=2021-07-15
         * @summary Get the most recently added state snapshot that was active on the specified date.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] The date to retrieve active state snapshots.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateGet(state: string, date?: Date, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsByDateGet(state, date, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Takes a serviceId and a date and returns the notator sections for that service determined by the current StateSnapshot's medicaid policy.
         * @param {string} serviceId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateNotatorSectionsGet(serviceId: string, state: string, date?: Date, clientId?: string, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsByDateNotatorSectionsGet(serviceId, state, date, clientId, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /StateSnapshots/ServiceAreas?date=2021-07-15
         * @summary Gets the list of active service areas for the specified date.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {Date} [date] The date to retrieve active service areas.
         * @param {boolean} [filterForMedicaidInfoOnly] Pass in true if you just want to filter for service areas that are required in the medicaid info tab of the student profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateServiceAreasGet(state: string, clientId?: string, date?: Date, filterForMedicaidInfoOnly?: boolean, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsByDateServiceAreasGet(state, clientId, date, filterForMedicaidInfoOnly, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets the service provider type inventory as it existed on the specified date.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {Date} [date] The date for which to get the service provider type inventory.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateServiceProviderTypesGet(state: string, clientId?: string, date?: Date, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsByDateServiceProviderTypesGet(state, clientId, date, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets the allowed services for a specific service provider type based on the state medicaid policy at the specified date/time.  Allowed services includes all services that fall under the service area of that service provider type.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] Specifies the point in time with which to view the service provider type inventory for this state.
         * @param {string} [serviceProviderTypeId] The id of the service provider type for which
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsByDateServicesByServiceProviderTypeGet(state: string, date?: Date, serviceProviderTypeId?: string, clientId?: string, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsByDateServicesByServiceProviderTypeGet(state, date, serviceProviderTypeId, clientId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Removes all unused fields from the request body for creating a new state snapshot. NOTE: This does not create a state snapshot in the database. This is   simply for cleaning the request body. This is helpful for keeping the request body clean and up to date in source control, when making changes to the state profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateStateSnapshotRequest} [body] The request body for creating a new state snapshot. NOTE: Will not be used to create a new snapshot, this is simply for cleaning the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsCleanSchemaPost(state: string, body?: CreateStateSnapshotRequest, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsCleanSchemaPost(state, body, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /StateSnapshots
         * @summary Gets a list of all state snapshots.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsGet(state: string, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsGet(state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets the goal areas of focus.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsGoalAreasOfFocusGet(state: string, date?: Date, clientId?: string, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsGoalAreasOfFocusGet(state, date, clientId, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /StateSnapshots/{id}
         * @summary Gets a specific state snapshot by its id.
         * @param {string} id 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsIdGet(id: string, state: string, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsIdGet(id, state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    POST /StateSnapshots      {                    }
         * @summary Creates a new snapshot of the state profile. The snapshot will be active from the specified start date  unitl the specified end date. If the end date is null, the snapshot will be active indefinitely, or until  another snapshot supercedes it.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateStateSnapshotRequest} [body] The request body to create the state snapshot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsPost(state: string, body?: CreateStateSnapshotRequest, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsPost(state, body, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    PUT /StateSnapshots      {                    }
         * @summary Updates the current state profile by adding a new state snapshot which is a copy of the most recently added state  snapshot active currently, with the fields specified in the request replacing the fields in the current state snapshot.  Any fields in the request body that are null will not be updated.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateStateSnapshotRequest} [body] The request body to update the state snapshot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsPut(state: string, body?: UpdateStateSnapshotRequest, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsPut(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Depricated
         * @param {string} serviceTypeId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StateSnapshotsServicesByServiceTypeServiceTypeIdGet(serviceTypeId: string, state: string, date?: Date, clientId?: string, options?: any) {
            return StateSnapshotsApiFp(configuration).v1StateSnapshotsServicesByServiceTypeServiceTypeIdGet(serviceTypeId, state, date, clientId, options)(fetch, basePath);
        },
    };
};

/**
 * StateSnapshotsApi - interface
 * @export
 * @interface StateSnapshotsApi
 */
export interface StateSnapshotsApiInterface {
    /**
     * 
     * @summary Gets the default care provisions for the specified service type and service area based on the state medicaid policy at the specified date/time.
     * @param {string} serviceTypeId The id of the service type for which to get the default care provisions.
     * @param {string} serviceAreaId The id of the service area for which to get the default care provisions.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] Specifies the point in time with which to view the service provider type inventory for this state.
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsByDateDefaultCareProvisionsGet(serviceTypeId: string, serviceAreaId: string, state: string, date?: Date, clientId?: string, options?: any): Promise<DefaultCareProvisionsResponse>;

    /**
     * Sample Request:                    GET /StateSnapshots/ByDate?date=2021-07-15
     * @summary Get the most recently added state snapshot that was active on the specified date.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] The date to retrieve active state snapshots.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsByDateGet(state: string, date?: Date, options?: any): Promise<GetStateSnapshotsResponse>;

    /**
     * 
     * @summary Takes a serviceId and a date and returns the notator sections for that service determined by the current StateSnapshot's medicaid policy.
     * @param {string} serviceId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsByDateNotatorSectionsGet(serviceId: string, state: string, date?: Date, clientId?: string, options?: any): Promise<NotatorSectionResponse>;

    /**
     * Sample Request:                    GET /StateSnapshots/ServiceAreas?date=2021-07-15
     * @summary Gets the list of active service areas for the specified date.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [clientId] 
     * @param {Date} [date] The date to retrieve active service areas.
     * @param {boolean} [filterForMedicaidInfoOnly] Pass in true if you just want to filter for service areas that are required in the medicaid info tab of the student profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsByDateServiceAreasGet(state: string, clientId?: string, date?: Date, filterForMedicaidInfoOnly?: boolean, options?: any): Promise<GetServiceAreasResponse>;

    /**
     * 
     * @summary Gets the service provider type inventory as it existed on the specified date.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [clientId] 
     * @param {Date} [date] The date for which to get the service provider type inventory.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsByDateServiceProviderTypesGet(state: string, clientId?: string, date?: Date, options?: any): Promise<ServiceProviderTypesResponse>;

    /**
     * 
     * @summary Gets the allowed services for a specific service provider type based on the state medicaid policy at the specified date/time.  Allowed services includes all services that fall under the service area of that service provider type.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] Specifies the point in time with which to view the service provider type inventory for this state.
     * @param {string} [serviceProviderTypeId] The id of the service provider type for which
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsByDateServicesByServiceProviderTypeGet(state: string, date?: Date, serviceProviderTypeId?: string, clientId?: string, options?: any): Promise<ServicesByServiceProviderTypeResponse>;

    /**
     * 
     * @summary Removes all unused fields from the request body for creating a new state snapshot. NOTE: This does not create a state snapshot in the database. This is   simply for cleaning the request body. This is helpful for keeping the request body clean and up to date in source control, when making changes to the state profile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateStateSnapshotRequest} [body] The request body for creating a new state snapshot. NOTE: Will not be used to create a new snapshot, this is simply for cleaning the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsCleanSchemaPost(state: string, body?: CreateStateSnapshotRequest, options?: any): Promise<CreateStateSnapshotRequest>;

    /**
     * Sample Request:                    GET /StateSnapshots
     * @summary Gets a list of all state snapshots.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsGet(state: string, options?: any): Promise<GetStateSnapshotsResponse>;

    /**
     * 
     * @summary Gets the goal areas of focus.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsGoalAreasOfFocusGet(state: string, date?: Date, clientId?: string, options?: any): Promise<GetGoalAreasOfFocusResponse>;

    /**
     * Sample Request:                    GET /StateSnapshots/{id}
     * @summary Gets a specific state snapshot by its id.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsIdGet(id: string, state: string, options?: any): Promise<StateSnapshotResponse>;

    /**
     * Sample Request:                    POST /StateSnapshots      {                    }
     * @summary Creates a new snapshot of the state profile. The snapshot will be active from the specified start date  unitl the specified end date. If the end date is null, the snapshot will be active indefinitely, or until  another snapshot supercedes it.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateStateSnapshotRequest} [body] The request body to create the state snapshot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsPost(state: string, body?: CreateStateSnapshotRequest, options?: any): Promise<StateSnapshotResponse>;

    /**
     * Sample Request:                    PUT /StateSnapshots      {                    }
     * @summary Updates the current state profile by adding a new state snapshot which is a copy of the most recently added state  snapshot active currently, with the fields specified in the request replacing the fields in the current state snapshot.  Any fields in the request body that are null will not be updated.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateStateSnapshotRequest} [body] The request body to update the state snapshot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsPut(state: string, body?: UpdateStateSnapshotRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary Depricated
     * @param {string} serviceTypeId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApiInterface
     */
    v1StateSnapshotsServicesByServiceTypeServiceTypeIdGet(serviceTypeId: string, state: string, date?: Date, clientId?: string, options?: any): Promise<{}>;

}

/**
 * StateSnapshotsApi - object-oriented interface
 * @export
 * @class StateSnapshotsApi
 * @extends {BaseAPI}
 */
export class StateSnapshotsApi extends BaseAPI implements StateSnapshotsApiInterface {
    /**
     * 
     * @summary Gets the default care provisions for the specified service type and service area based on the state medicaid policy at the specified date/time.
     * @param {string} serviceTypeId The id of the service type for which to get the default care provisions.
     * @param {string} serviceAreaId The id of the service area for which to get the default care provisions.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] Specifies the point in time with which to view the service provider type inventory for this state.
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsByDateDefaultCareProvisionsGet(serviceTypeId: string, serviceAreaId: string, state: string, date?: Date, clientId?: string, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsByDateDefaultCareProvisionsGet(serviceTypeId, serviceAreaId, state, date, clientId, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /StateSnapshots/ByDate?date=2021-07-15
     * @summary Get the most recently added state snapshot that was active on the specified date.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] The date to retrieve active state snapshots.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsByDateGet(state: string, date?: Date, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsByDateGet(state, date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Takes a serviceId and a date and returns the notator sections for that service determined by the current StateSnapshot's medicaid policy.
     * @param {string} serviceId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsByDateNotatorSectionsGet(serviceId: string, state: string, date?: Date, clientId?: string, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsByDateNotatorSectionsGet(serviceId, state, date, clientId, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /StateSnapshots/ServiceAreas?date=2021-07-15
     * @summary Gets the list of active service areas for the specified date.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [clientId] 
     * @param {Date} [date] The date to retrieve active service areas.
     * @param {boolean} [filterForMedicaidInfoOnly] Pass in true if you just want to filter for service areas that are required in the medicaid info tab of the student profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsByDateServiceAreasGet(state: string, clientId?: string, date?: Date, filterForMedicaidInfoOnly?: boolean, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsByDateServiceAreasGet(state, clientId, date, filterForMedicaidInfoOnly, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets the service provider type inventory as it existed on the specified date.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [clientId] 
     * @param {Date} [date] The date for which to get the service provider type inventory.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsByDateServiceProviderTypesGet(state: string, clientId?: string, date?: Date, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsByDateServiceProviderTypesGet(state, clientId, date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets the allowed services for a specific service provider type based on the state medicaid policy at the specified date/time.  Allowed services includes all services that fall under the service area of that service provider type.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] Specifies the point in time with which to view the service provider type inventory for this state.
     * @param {string} [serviceProviderTypeId] The id of the service provider type for which
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsByDateServicesByServiceProviderTypeGet(state: string, date?: Date, serviceProviderTypeId?: string, clientId?: string, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsByDateServicesByServiceProviderTypeGet(state, date, serviceProviderTypeId, clientId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Removes all unused fields from the request body for creating a new state snapshot. NOTE: This does not create a state snapshot in the database. This is   simply for cleaning the request body. This is helpful for keeping the request body clean and up to date in source control, when making changes to the state profile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateStateSnapshotRequest} [body] The request body for creating a new state snapshot. NOTE: Will not be used to create a new snapshot, this is simply for cleaning the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsCleanSchemaPost(state: string, body?: CreateStateSnapshotRequest, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsCleanSchemaPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /StateSnapshots
     * @summary Gets a list of all state snapshots.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsGet(state: string, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsGet(state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets the goal areas of focus.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsGoalAreasOfFocusGet(state: string, date?: Date, clientId?: string, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsGoalAreasOfFocusGet(state, date, clientId, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /StateSnapshots/{id}
     * @summary Gets a specific state snapshot by its id.
     * @param {string} id 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsIdGet(id: string, state: string, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsIdGet(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    POST /StateSnapshots      {                    }
     * @summary Creates a new snapshot of the state profile. The snapshot will be active from the specified start date  unitl the specified end date. If the end date is null, the snapshot will be active indefinitely, or until  another snapshot supercedes it.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateStateSnapshotRequest} [body] The request body to create the state snapshot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsPost(state: string, body?: CreateStateSnapshotRequest, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    PUT /StateSnapshots      {                    }
     * @summary Updates the current state profile by adding a new state snapshot which is a copy of the most recently added state  snapshot active currently, with the fields specified in the request replacing the fields in the current state snapshot.  Any fields in the request body that are null will not be updated.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateStateSnapshotRequest} [body] The request body to update the state snapshot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsPut(state: string, body?: UpdateStateSnapshotRequest, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsPut(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Depricated
     * @param {string} serviceTypeId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateSnapshotsApi
     */
    public v1StateSnapshotsServicesByServiceTypeServiceTypeIdGet(serviceTypeId: string, state: string, date?: Date, clientId?: string, options?: any) {
        return StateSnapshotsApiFp(this.configuration).v1StateSnapshotsServicesByServiceTypeServiceTypeIdGet(serviceTypeId, state, date, clientId, options)(this.fetch, this.basePath);
    }

}
/**
 * StudentsApi - fetch parameter creator
 * @export
 */
export const StudentsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} providerId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsCaseloadGet(providerId: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'providerId' is not null or undefined
            if (providerId === null || providerId === undefined) {
                throw new RequiredError('providerId','Required parameter providerId was null or undefined when calling v1StudentsCaseloadGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsCaseloadGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsCaseloadGet.');
            }
            const localVarPath = `/v1/Students/Caseload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint is purely for referencing the example value and schema documentation for the StudentProfile data model.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDataModelGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsDataModelGet.');
            }
            const localVarPath = `/v1/Students/DataModel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all deleted students in a specific Client account, which is specified by the Client's ID.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDeletedGet(clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsDeletedGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsDeletedGet.');
            }
            const localVarPath = `/v1/Students/Deleted`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDuplicateStudentProfilesGet(clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsDuplicateStudentProfilesGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsDuplicateStudentProfilesGet.');
            }
            const localVarPath = `/v1/Students/DuplicateStudentProfiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {Date} [dateOfBirth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDuplicateStudentProfilesSingleStudentGet(clientId: string, state: string, firstName?: string, lastName?: string, dateOfBirth?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsDuplicateStudentProfilesSingleStudentGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsDuplicateStudentProfilesSingleStudentGet.');
            }
            const localVarPath = `/v1/Students/DuplicateStudentProfilesSingleStudent`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (dateOfBirth !== undefined) {
                localVarQueryParameter['dateOfBirth'] = (dateOfBirth as any).toISOString();
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all Student Profiles within a specific Client account, which is specified by the Client's ID.
         * @param {string} clientId The ID of the Client for which to retrieve all students.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGet(clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsGet.');
            }
            const localVarPath = `/v1/Students`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all ordered services from a student's ScheduleOfServices by StudentID.
         * @param {string} studentId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGetAllOrderedServicesGet(studentId: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling v1StudentsGetAllOrderedServicesGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsGetAllOrderedServicesGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsGetAllOrderedServicesGet.');
            }
            const localVarPath = `/v1/Students/GetAllOrderedServices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deprecated
         * @param {string} studentId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGetAllOrderedSubjectsGet(studentId: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling v1StudentsGetAllOrderedSubjectsGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsGetAllOrderedSubjectsGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsGetAllOrderedSubjectsGet.');
            }
            const localVarPath = `/v1/Students/GetAllOrderedSubjects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} studentId 
         * @param {string} clientId 
         * @param {string} serviceAreaId 
         * @param {string} serviceAreaName 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet(studentId: string, clientId: string, serviceAreaId: string, serviceAreaName: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet.');
            }
            // verify required parameter 'serviceAreaId' is not null or undefined
            if (serviceAreaId === null || serviceAreaId === undefined) {
                throw new RequiredError('serviceAreaId','Required parameter serviceAreaId was null or undefined when calling v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet.');
            }
            // verify required parameter 'serviceAreaName' is not null or undefined
            if (serviceAreaName === null || serviceAreaName === undefined) {
                throw new RequiredError('serviceAreaName','Required parameter serviceAreaName was null or undefined when calling v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet.');
            }
            const localVarPath = `/v1/Students/GetDefaultOrderedSubjectsByServiceArea`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (serviceAreaId !== undefined) {
                localVarQueryParameter['serviceAreaId'] = serviceAreaId;
            }

            if (serviceAreaName !== undefined) {
                localVarQueryParameter['serviceAreaName'] = serviceAreaName;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /{id}/ActiveGoals?clientId={clientId}&date={date}
         * @summary Gets all goals for one student that were active on the given date. Active means neither deactivated, ended, nor completed.
         * @param {string} id The ID of the student for whom to get active goals.
         * @param {string} clientId The client ID in which to retrieve the student&#x27;s profile and goal information.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] The date with which to evaluate if the goals are active.
         * @param {string} [serviceAreaId] DEPRECATED               The service area ID to filter the goals by. When set, the response will only contain goals with the specified               service area of focus. Remove this eventually.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdActiveGoalsGet(id: string, clientId: string, state: string, date?: Date, serviceAreaId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdActiveGoalsGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsIdActiveGoalsGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdActiveGoalsGet.');
            }
            const localVarPath = `/v1/Students/{id}/ActiveGoals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (serviceAreaId !== undefined) {
                localVarQueryParameter['serviceAreaId'] = serviceAreaId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} id The ID of the student for whom to get the default session goals.
         * @param {string} clientId The Id of the client that the student is under.
         * @param {string} serviceAreaId The Id of service area of the session for which to get the default goals.
         * @param {Date} date The date of the session for which to get the default goals.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdDefaultSessionGoalsGet(id: string, clientId: string, serviceAreaId: string, date: Date, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdDefaultSessionGoalsGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsIdDefaultSessionGoalsGet.');
            }
            // verify required parameter 'serviceAreaId' is not null or undefined
            if (serviceAreaId === null || serviceAreaId === undefined) {
                throw new RequiredError('serviceAreaId','Required parameter serviceAreaId was null or undefined when calling v1StudentsIdDefaultSessionGoalsGet.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling v1StudentsIdDefaultSessionGoalsGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdDefaultSessionGoalsGet.');
            }
            const localVarPath = `/v1/Students/{id}/DefaultSessionGoals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (serviceAreaId !== undefined) {
                localVarQueryParameter['serviceAreaId'] = serviceAreaId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a student profile by ID and ClientId and deletes that Student Profile.
         * @param {string} id The Id of the profile we want to delete.
         * @param {string} clientId The id of the client with the student profile we want to delete.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdDeletePatch(id: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdDeletePatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsIdDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdDeletePatch.');
            }
            const localVarPath = `/v1/Students/{id}/Delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a specific student, within specific a Client, both specified by ID.
         * @param {string} id The ID of the student to retrieve.
         * @param {string} clientId The ID of the client within which to search for the student.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGet(id: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdGet.');
            }
            const localVarPath = `/v1/Students/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /{id}/Goals?clientId={clientId}&includeEnded=true&includeDeactivated=true&includeCompleted=true
         * @summary Depricated
         * @param {string} id The ID of the student for whom to get the goals.
         * @param {string} clientId The ID of the client in which to get the student&#x27;s goals.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {boolean} [includeEnded] Determines if the response will include goals that have ended by now.
         * @param {boolean} [includeDeactivated] Determines if the response will include goals that have been deactivated by now.
         * @param {boolean} [includeCompleted] Determines if the response will include goals that have been completed by now.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGoalsGet(id: string, clientId: string, state: string, includeEnded?: boolean, includeDeactivated?: boolean, includeCompleted?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdGoalsGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsIdGoalsGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdGoalsGet.');
            }
            const localVarPath = `/v1/Students/{id}/Goals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (includeEnded !== undefined) {
                localVarQueryParameter['includeEnded'] = includeEnded;
            }

            if (includeDeactivated !== undefined) {
                localVarQueryParameter['includeDeactivated'] = includeDeactivated;
            }

            if (includeCompleted !== undefined) {
                localVarQueryParameter['includeCompleted'] = includeCompleted;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the student goal with the given goal number for the student with the given id.
         * @param {string} id The Id of the Student Profile to update a goal for.
         * @param {string} goalNumber The goal number of the goal to update.
         * @param {string} clientId The Id of the student&#x27;s client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateGoalRequest} [body] The body of the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGoalsGoalNumberPut(id: string, goalNumber: string, clientId: string, state: string, body?: UpdateGoalRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdGoalsGoalNumberPut.');
            }
            // verify required parameter 'goalNumber' is not null or undefined
            if (goalNumber === null || goalNumber === undefined) {
                throw new RequiredError('goalNumber','Required parameter goalNumber was null or undefined when calling v1StudentsIdGoalsGoalNumberPut.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsIdGoalsGoalNumberPut.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdGoalsGoalNumberPut.');
            }
            const localVarPath = `/v1/Students/{id}/Goals/{goalNumber}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"goalNumber"}}`, encodeURIComponent(String(goalNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateGoalRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new student goal for the student with the given id.
         * @param {string} id The Id of the Student Profile to create a goal for.
         * @param {string} clientId The Id of the student&#x27;s client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateGoalRequest} [body] The body of the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGoalsPost(id: string, clientId: string, state: string, body?: CreateGoalRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdGoalsPost.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsIdGoalsPost.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdGoalsPost.');
            }
            const localVarPath = `/v1/Students/{id}/Goals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateGoalRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patches the student profile with the given id. Any field of the request that are left null will remain unchanged.
         * @param {string} id The Id of the Student Profile to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchStudentRequest} [body] The body of the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdPatch(id: string, state: string, body?: PatchStudentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdPatch.');
            }
            const localVarPath = `/v1/Students/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchStudentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the perscribed care provisions for the specified student in the specified client.
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdPrescribedCareProvisionsByDateGet(id: string, clientId: string, state: string, date?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdPrescribedCareProvisionsByDateGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsIdPrescribedCareProvisionsByDateGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdPrescribedCareProvisionsByDateGet.');
            }
            const localVarPath = `/v1/Students/{id}/PrescribedCareProvisions/ByDate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Takes an ID and an UpdateStudentRequest object and updates the StudentProfile with the given ID.
         * @param {string} id The Id of the Student Profile we want to update
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateStudentRequest} [body] The UpdateStudentRequest object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdPut(id: string, state: string, body?: UpdateStudentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdPut.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdPut.');
            }
            const localVarPath = `/v1/Students/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateStudentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restores a deleted student profile.
         * @param {string} id The id of the student profile we want to restore.
         * @param {string} clientId The Id of the Client containing the student profile we want to restore.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdUndoDeletePatch(id: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1StudentsIdUndoDeletePatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsIdUndoDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsIdUndoDeletePatch.');
            }
            const localVarPath = `/v1/Students/{id}/UndoDelete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all students within a specific Client account that match the provided search text, and that are assigned to the campuses  specified by ids. Search results also include students that are not assigned to any campuses.
         * @param {string} clientId The ID of the Client for which to retrieve all students.
         * @param {string} searchText The text to use for searching.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [schoolCampusIdsString] A comma-delimited string containing the IDs of the school campuses to filter the results by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsMergeDisplayBySearchGet(clientId: string, searchText: string, state: string, schoolCampusIdsString?: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsMergeDisplayBySearchGet.');
            }
            // verify required parameter 'searchText' is not null or undefined
            if (searchText === null || searchText === undefined) {
                throw new RequiredError('searchText','Required parameter searchText was null or undefined when calling v1StudentsMergeDisplayBySearchGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsMergeDisplayBySearchGet.');
            }
            const localVarPath = `/v1/Students/MergeDisplay/BySearch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (schoolCampusIdsString !== undefined) {
                localVarQueryParameter['schoolCampusIdsString'] = schoolCampusIdsString;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The endpoint for merging student profiles
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {MergeStudentsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsMergeStudentsPatch(state: string, body?: MergeStudentsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsMergeStudentsPatch.');
            }
            const localVarPath = `/v1/Students/MergeStudents`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MergeStudentsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * NOTE: The new container must already be set up in the database. This endpoint will not create a new container. Be sure  to configure the partition key correctly before executing this endpoint as well.
         * @summary This endpoint will migrate all students within the database to a new container in the database.
         * @param {string} containerName 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {MigrateStudentsToContainerPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsMigrateToContainerContainerNamePost(containerName: string, state: string, body?: MigrateStudentsToContainerPostRequest, options: any = {}): FetchArgs {
            // verify required parameter 'containerName' is not null or undefined
            if (containerName === null || containerName === undefined) {
                throw new RequiredError('containerName','Required parameter containerName was null or undefined when calling v1StudentsMigrateToContainerContainerNamePost.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsMigrateToContainerContainerNamePost.');
            }
            const localVarPath = `/v1/Students/MigrateToContainer/{containerName}`
                .replace(`{${"containerName"}}`, encodeURIComponent(String(containerName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MigrateStudentsToContainerPostRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This is the endpoint to get the total ordered minutes for an ordered Service Area
         * @param {string} clientId 
         * @param {string} studentId 
         * @param {string} serviceAreaId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsOrderedServiceAreaTimeOrderedGet(clientId: string, studentId: string, serviceAreaId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsOrderedServiceAreaTimeOrderedGet.');
            }
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling v1StudentsOrderedServiceAreaTimeOrderedGet.');
            }
            // verify required parameter 'serviceAreaId' is not null or undefined
            if (serviceAreaId === null || serviceAreaId === undefined) {
                throw new RequiredError('serviceAreaId','Required parameter serviceAreaId was null or undefined when calling v1StudentsOrderedServiceAreaTimeOrderedGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsOrderedServiceAreaTimeOrderedGet.');
            }
            const localVarPath = `/v1/Students/OrderedServiceAreaTimeOrdered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (serviceAreaId !== undefined) {
                localVarQueryParameter['serviceAreaId'] = serviceAreaId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {OrderedSubject} body 
         * @param {string} clientId 
         * @param {string} studentId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsOrderedSubjectTimeOrderedGet(body: OrderedSubject, clientId: string, studentId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling v1StudentsOrderedSubjectTimeOrderedGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsOrderedSubjectTimeOrderedGet.');
            }
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling v1StudentsOrderedSubjectTimeOrderedGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsOrderedSubjectTimeOrderedGet.');
            }
            const localVarPath = `/v1/Students/OrderedSubjectTimeOrdered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (studentId !== undefined) {
                localVarQueryParameter['studentId'] = studentId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderedSubject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new student profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateStudentRequest} [body] The request body for creating a student profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsPost(state: string, body?: CreateStudentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsPost.');
            }
            const localVarPath = `/v1/Students`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateStudentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all students within a specific Client account that match the provided search text, and that are assigned to the campuses  specified by ids. Search results also include students that are not assigned to any campuses.
         * @param {string} clientId The ID of the Client for which to retrieve all students.
         * @param {string} searchText The text to use for searching.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [schoolCampusIdsString] A comma-delimited string containing the IDs of the school campuses to filter the results by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsSearchGet(clientId: string, searchText: string, state: string, schoolCampusIdsString?: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1StudentsSearchGet.');
            }
            // verify required parameter 'searchText' is not null or undefined
            if (searchText === null || searchText === undefined) {
                throw new RequiredError('searchText','Required parameter searchText was null or undefined when calling v1StudentsSearchGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsSearchGet.');
            }
            const localVarPath = `/v1/Students/Search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (schoolCampusIdsString !== undefined) {
                localVarQueryParameter['schoolCampusIdsString'] = schoolCampusIdsString;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This tests the connection to the Sessions API.
         * @param {string} serviceProviderId The id of the provider whose session to test.
         * @param {string} sessionId The id of the test session.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsSessionsTestServiceProviderIdSessionIdGet(serviceProviderId: string, sessionId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1StudentsSessionsTestServiceProviderIdSessionIdGet.');
            }
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling v1StudentsSessionsTestServiceProviderIdSessionIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsSessionsTestServiceProviderIdSessionIdGet.');
            }
            const localVarPath = `/v1/Students/SessionsTest/{serviceProviderId}/{sessionId}`
                .replace(`{${"serviceProviderId"}}`, encodeURIComponent(String(serviceProviderId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary this is a test.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsTestGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsTestGet.');
            }
            const localVarPath = `/v1/Students/Test`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Developer use only.  An endpoint to undelete and unmerge all merged profiles in a client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsUndoMergesPatch(state: string, clientId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1StudentsUndoMergesPatch.');
            }
            const localVarPath = `/v1/Students/UndoMerges`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentsApi - functional programming interface
 * @export
 */
export const StudentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} providerId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsCaseloadGet(providerId: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetCaseloadStudentsResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsCaseloadGet(providerId, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This endpoint is purely for referencing the example value and schema documentation for the StudentProfile data model.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDataModelGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StudentProfile> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsDataModelGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all deleted students in a specific Client account, which is specified by the Client's ID.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDeletedGet(clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetStudentsResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsDeletedGet(clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDuplicateStudentProfilesGet(clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsDuplicateStudentProfilesGet(clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {Date} [dateOfBirth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDuplicateStudentProfilesSingleStudentGet(clientId: string, state: string, firstName?: string, lastName?: string, dateOfBirth?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetDuplicateStudentProfilesForSingleStudentResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsDuplicateStudentProfilesSingleStudentGet(clientId, state, firstName, lastName, dateOfBirth, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all Student Profiles within a specific Client account, which is specified by the Client's ID.
         * @param {string} clientId The ID of the Client for which to retrieve all students.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGet(clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetStudentsResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsGet(clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all ordered services from a student's ScheduleOfServices by StudentID.
         * @param {string} studentId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGetAllOrderedServicesGet(studentId: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsGetAllOrderedServicesGet(studentId, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Deprecated
         * @param {string} studentId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGetAllOrderedSubjectsGet(studentId: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsGetAllOrderedSubjectsGet(studentId, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} studentId 
         * @param {string} clientId 
         * @param {string} serviceAreaId 
         * @param {string} serviceAreaName 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet(studentId: string, clientId: string, serviceAreaId: string, serviceAreaName: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet(studentId, clientId, serviceAreaId, serviceAreaName, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /{id}/ActiveGoals?clientId={clientId}&date={date}
         * @summary Gets all goals for one student that were active on the given date. Active means neither deactivated, ended, nor completed.
         * @param {string} id The ID of the student for whom to get active goals.
         * @param {string} clientId The client ID in which to retrieve the student&#x27;s profile and goal information.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] The date with which to evaluate if the goals are active.
         * @param {string} [serviceAreaId] DEPRECATED               The service area ID to filter the goals by. When set, the response will only contain goals with the specified               service area of focus. Remove this eventually.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdActiveGoalsGet(id: string, clientId: string, state: string, date?: Date, serviceAreaId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoalsResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdActiveGoalsGet(id, clientId, state, date, serviceAreaId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {string} id The ID of the student for whom to get the default session goals.
         * @param {string} clientId The Id of the client that the student is under.
         * @param {string} serviceAreaId The Id of service area of the session for which to get the default goals.
         * @param {Date} date The date of the session for which to get the default goals.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdDefaultSessionGoalsGet(id: string, clientId: string, serviceAreaId: string, date: Date, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoalsResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdDefaultSessionGoalsGet(id, clientId, serviceAreaId, date, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets a student profile by ID and ClientId and deletes that Student Profile.
         * @param {string} id The Id of the profile we want to delete.
         * @param {string} clientId The id of the client with the student profile we want to delete.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdDeletePatch(id: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdDeletePatch(id, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets a specific student, within specific a Client, both specified by ID.
         * @param {string} id The ID of the student to retrieve.
         * @param {string} clientId The ID of the client within which to search for the student.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGet(id: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StudentResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdGet(id, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /{id}/Goals?clientId={clientId}&includeEnded=true&includeDeactivated=true&includeCompleted=true
         * @summary Depricated
         * @param {string} id The ID of the student for whom to get the goals.
         * @param {string} clientId The ID of the client in which to get the student&#x27;s goals.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {boolean} [includeEnded] Determines if the response will include goals that have ended by now.
         * @param {boolean} [includeDeactivated] Determines if the response will include goals that have been deactivated by now.
         * @param {boolean} [includeCompleted] Determines if the response will include goals that have been completed by now.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGoalsGet(id: string, clientId: string, state: string, includeEnded?: boolean, includeDeactivated?: boolean, includeCompleted?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoalsResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdGoalsGet(id, clientId, state, includeEnded, includeDeactivated, includeCompleted, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Updates the student goal with the given goal number for the student with the given id.
         * @param {string} id The Id of the Student Profile to update a goal for.
         * @param {string} goalNumber The goal number of the goal to update.
         * @param {string} clientId The Id of the student&#x27;s client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateGoalRequest} [body] The body of the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGoalsGoalNumberPut(id: string, goalNumber: string, clientId: string, state: string, body?: UpdateGoalRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoalDisplay> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdGoalsGoalNumberPut(id, goalNumber, clientId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Creates a new student goal for the student with the given id.
         * @param {string} id The Id of the Student Profile to create a goal for.
         * @param {string} clientId The Id of the student&#x27;s client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateGoalRequest} [body] The body of the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGoalsPost(id: string, clientId: string, state: string, body?: CreateGoalRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoalDisplay> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdGoalsPost(id, clientId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Patches the student profile with the given id. Any field of the request that are left null will remain unchanged.
         * @param {string} id The Id of the Student Profile to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchStudentRequest} [body] The body of the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdPatch(id: string, state: string, body?: PatchStudentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StudentResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdPatch(id, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets the perscribed care provisions for the specified student in the specified client.
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdPrescribedCareProvisionsByDateGet(id: string, clientId: string, state: string, date?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PrescribedCareProvisionsResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdPrescribedCareProvisionsByDateGet(id, clientId, state, date, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Takes an ID and an UpdateStudentRequest object and updates the StudentProfile with the given ID.
         * @param {string} id The Id of the Student Profile we want to update
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateStudentRequest} [body] The UpdateStudentRequest object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdPut(id: string, state: string, body?: UpdateStudentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdPut(id, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Restores a deleted student profile.
         * @param {string} id The id of the student profile we want to restore.
         * @param {string} clientId The Id of the Client containing the student profile we want to restore.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdUndoDeletePatch(id: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StudentResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsIdUndoDeletePatch(id, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all students within a specific Client account that match the provided search text, and that are assigned to the campuses  specified by ids. Search results also include students that are not assigned to any campuses.
         * @param {string} clientId The ID of the Client for which to retrieve all students.
         * @param {string} searchText The text to use for searching.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [schoolCampusIdsString] A comma-delimited string containing the IDs of the school campuses to filter the results by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsMergeDisplayBySearchGet(clientId: string, searchText: string, state: string, schoolCampusIdsString?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetStudentMergeDisplaysResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsMergeDisplayBySearchGet(clientId, searchText, state, schoolCampusIdsString, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The endpoint for merging student profiles
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {MergeStudentsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsMergeStudentsPatch(state: string, body?: MergeStudentsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StudentProfileMergeResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsMergeStudentsPatch(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * NOTE: The new container must already be set up in the database. This endpoint will not create a new container. Be sure  to configure the partition key correctly before executing this endpoint as well.
         * @summary This endpoint will migrate all students within the database to a new container in the database.
         * @param {string} containerName 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {MigrateStudentsToContainerPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsMigrateToContainerContainerNamePost(containerName: string, state: string, body?: MigrateStudentsToContainerPostRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsMigrateToContainerContainerNamePost(containerName, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This is the endpoint to get the total ordered minutes for an ordered Service Area
         * @param {string} clientId 
         * @param {string} studentId 
         * @param {string} serviceAreaId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsOrderedServiceAreaTimeOrderedGet(clientId: string, studentId: string, serviceAreaId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsOrderedServiceAreaTimeOrderedGet(clientId, studentId, serviceAreaId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Depricated
         * @param {OrderedSubject} body 
         * @param {string} clientId 
         * @param {string} studentId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsOrderedSubjectTimeOrderedGet(body: OrderedSubject, clientId: string, studentId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsOrderedSubjectTimeOrderedGet(body, clientId, studentId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Creates a new student profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateStudentRequest} [body] The request body for creating a student profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsPost(state: string, body?: CreateStudentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StudentResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Gets all students within a specific Client account that match the provided search text, and that are assigned to the campuses  specified by ids. Search results also include students that are not assigned to any campuses.
         * @param {string} clientId The ID of the Client for which to retrieve all students.
         * @param {string} searchText The text to use for searching.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [schoolCampusIdsString] A comma-delimited string containing the IDs of the school campuses to filter the results by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsSearchGet(clientId: string, searchText: string, state: string, schoolCampusIdsString?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetCaseloadStudentsResponse> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsSearchGet(clientId, searchText, state, schoolCampusIdsString, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This tests the connection to the Sessions API.
         * @param {string} serviceProviderId The id of the provider whose session to test.
         * @param {string} sessionId The id of the test session.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsSessionsTestServiceProviderIdSessionIdGet(serviceProviderId: string, sessionId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsSessionsTestServiceProviderIdSessionIdGet(serviceProviderId, sessionId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary this is a test.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsTestGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsTestGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Developer use only.  An endpoint to undelete and unmerge all merged profiles in a client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsUndoMergesPatch(state: string, clientId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = StudentsApiFetchParamCreator(configuration).v1StudentsUndoMergesPatch(state, clientId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StudentsApi - factory interface
 * @export
 */
export const StudentsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} providerId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsCaseloadGet(providerId: string, clientId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsCaseloadGet(providerId, clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This endpoint is purely for referencing the example value and schema documentation for the StudentProfile data model.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDataModelGet(state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsDataModelGet(state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all deleted students in a specific Client account, which is specified by the Client's ID.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDeletedGet(clientId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsDeletedGet(clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDuplicateStudentProfilesGet(clientId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsDuplicateStudentProfilesGet(clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {Date} [dateOfBirth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsDuplicateStudentProfilesSingleStudentGet(clientId: string, state: string, firstName?: string, lastName?: string, dateOfBirth?: Date, options?: any) {
            return StudentsApiFp(configuration).v1StudentsDuplicateStudentProfilesSingleStudentGet(clientId, state, firstName, lastName, dateOfBirth, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all Student Profiles within a specific Client account, which is specified by the Client's ID.
         * @param {string} clientId The ID of the Client for which to retrieve all students.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGet(clientId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsGet(clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all ordered services from a student's ScheduleOfServices by StudentID.
         * @param {string} studentId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGetAllOrderedServicesGet(studentId: string, clientId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsGetAllOrderedServicesGet(studentId, clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Deprecated
         * @param {string} studentId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGetAllOrderedSubjectsGet(studentId: string, clientId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsGetAllOrderedSubjectsGet(studentId, clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Depricated
         * @param {string} studentId 
         * @param {string} clientId 
         * @param {string} serviceAreaId 
         * @param {string} serviceAreaName 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet(studentId: string, clientId: string, serviceAreaId: string, serviceAreaName: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet(studentId, clientId, serviceAreaId, serviceAreaName, state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /{id}/ActiveGoals?clientId={clientId}&date={date}
         * @summary Gets all goals for one student that were active on the given date. Active means neither deactivated, ended, nor completed.
         * @param {string} id The ID of the student for whom to get active goals.
         * @param {string} clientId The client ID in which to retrieve the student&#x27;s profile and goal information.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] The date with which to evaluate if the goals are active.
         * @param {string} [serviceAreaId] DEPRECATED               The service area ID to filter the goals by. When set, the response will only contain goals with the specified               service area of focus. Remove this eventually.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdActiveGoalsGet(id: string, clientId: string, state: string, date?: Date, serviceAreaId?: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdActiveGoalsGet(id, clientId, state, date, serviceAreaId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Depricated
         * @param {string} id The ID of the student for whom to get the default session goals.
         * @param {string} clientId The Id of the client that the student is under.
         * @param {string} serviceAreaId The Id of service area of the session for which to get the default goals.
         * @param {Date} date The date of the session for which to get the default goals.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdDefaultSessionGoalsGet(id: string, clientId: string, serviceAreaId: string, date: Date, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdDefaultSessionGoalsGet(id, clientId, serviceAreaId, date, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets a student profile by ID and ClientId and deletes that Student Profile.
         * @param {string} id The Id of the profile we want to delete.
         * @param {string} clientId The id of the client with the student profile we want to delete.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdDeletePatch(id: string, clientId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdDeletePatch(id, clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets a specific student, within specific a Client, both specified by ID.
         * @param {string} id The ID of the student to retrieve.
         * @param {string} clientId The ID of the client within which to search for the student.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGet(id: string, clientId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdGet(id, clientId, state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /{id}/Goals?clientId={clientId}&includeEnded=true&includeDeactivated=true&includeCompleted=true
         * @summary Depricated
         * @param {string} id The ID of the student for whom to get the goals.
         * @param {string} clientId The ID of the client in which to get the student&#x27;s goals.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {boolean} [includeEnded] Determines if the response will include goals that have ended by now.
         * @param {boolean} [includeDeactivated] Determines if the response will include goals that have been deactivated by now.
         * @param {boolean} [includeCompleted] Determines if the response will include goals that have been completed by now.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGoalsGet(id: string, clientId: string, state: string, includeEnded?: boolean, includeDeactivated?: boolean, includeCompleted?: boolean, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdGoalsGet(id, clientId, state, includeEnded, includeDeactivated, includeCompleted, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Updates the student goal with the given goal number for the student with the given id.
         * @param {string} id The Id of the Student Profile to update a goal for.
         * @param {string} goalNumber The goal number of the goal to update.
         * @param {string} clientId The Id of the student&#x27;s client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateGoalRequest} [body] The body of the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGoalsGoalNumberPut(id: string, goalNumber: string, clientId: string, state: string, body?: UpdateGoalRequest, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdGoalsGoalNumberPut(id, goalNumber, clientId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Creates a new student goal for the student with the given id.
         * @param {string} id The Id of the Student Profile to create a goal for.
         * @param {string} clientId The Id of the student&#x27;s client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateGoalRequest} [body] The body of the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdGoalsPost(id: string, clientId: string, state: string, body?: CreateGoalRequest, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdGoalsPost(id, clientId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Patches the student profile with the given id. Any field of the request that are left null will remain unchanged.
         * @param {string} id The Id of the Student Profile to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchStudentRequest} [body] The body of the request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdPatch(id: string, state: string, body?: PatchStudentRequest, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdPatch(id, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets the perscribed care provisions for the specified student in the specified client.
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {Date} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdPrescribedCareProvisionsByDateGet(id: string, clientId: string, state: string, date?: Date, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdPrescribedCareProvisionsByDateGet(id, clientId, state, date, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Takes an ID and an UpdateStudentRequest object and updates the StudentProfile with the given ID.
         * @param {string} id The Id of the Student Profile we want to update
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateStudentRequest} [body] The UpdateStudentRequest object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdPut(id: string, state: string, body?: UpdateStudentRequest, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdPut(id, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Restores a deleted student profile.
         * @param {string} id The id of the student profile we want to restore.
         * @param {string} clientId The Id of the Client containing the student profile we want to restore.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsIdUndoDeletePatch(id: string, clientId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsIdUndoDeletePatch(id, clientId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all students within a specific Client account that match the provided search text, and that are assigned to the campuses  specified by ids. Search results also include students that are not assigned to any campuses.
         * @param {string} clientId The ID of the Client for which to retrieve all students.
         * @param {string} searchText The text to use for searching.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [schoolCampusIdsString] A comma-delimited string containing the IDs of the school campuses to filter the results by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsMergeDisplayBySearchGet(clientId: string, searchText: string, state: string, schoolCampusIdsString?: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsMergeDisplayBySearchGet(clientId, searchText, state, schoolCampusIdsString, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The endpoint for merging student profiles
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {MergeStudentsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsMergeStudentsPatch(state: string, body?: MergeStudentsRequest, options?: any) {
            return StudentsApiFp(configuration).v1StudentsMergeStudentsPatch(state, body, options)(fetch, basePath);
        },
        /**
         * NOTE: The new container must already be set up in the database. This endpoint will not create a new container. Be sure  to configure the partition key correctly before executing this endpoint as well.
         * @summary This endpoint will migrate all students within the database to a new container in the database.
         * @param {string} containerName 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {MigrateStudentsToContainerPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsMigrateToContainerContainerNamePost(containerName: string, state: string, body?: MigrateStudentsToContainerPostRequest, options?: any) {
            return StudentsApiFp(configuration).v1StudentsMigrateToContainerContainerNamePost(containerName, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This is the endpoint to get the total ordered minutes for an ordered Service Area
         * @param {string} clientId 
         * @param {string} studentId 
         * @param {string} serviceAreaId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsOrderedServiceAreaTimeOrderedGet(clientId: string, studentId: string, serviceAreaId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsOrderedServiceAreaTimeOrderedGet(clientId, studentId, serviceAreaId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Depricated
         * @param {OrderedSubject} body 
         * @param {string} clientId 
         * @param {string} studentId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsOrderedSubjectTimeOrderedGet(body: OrderedSubject, clientId: string, studentId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsOrderedSubjectTimeOrderedGet(body, clientId, studentId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Creates a new student profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateStudentRequest} [body] The request body for creating a student profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsPost(state: string, body?: CreateStudentRequest, options?: any) {
            return StudentsApiFp(configuration).v1StudentsPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Gets all students within a specific Client account that match the provided search text, and that are assigned to the campuses  specified by ids. Search results also include students that are not assigned to any campuses.
         * @param {string} clientId The ID of the Client for which to retrieve all students.
         * @param {string} searchText The text to use for searching.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [schoolCampusIdsString] A comma-delimited string containing the IDs of the school campuses to filter the results by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsSearchGet(clientId: string, searchText: string, state: string, schoolCampusIdsString?: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsSearchGet(clientId, searchText, state, schoolCampusIdsString, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This tests the connection to the Sessions API.
         * @param {string} serviceProviderId The id of the provider whose session to test.
         * @param {string} sessionId The id of the test session.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsSessionsTestServiceProviderIdSessionIdGet(serviceProviderId: string, sessionId: string, state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsSessionsTestServiceProviderIdSessionIdGet(serviceProviderId, sessionId, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary this is a test.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsTestGet(state: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsTestGet(state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Developer use only.  An endpoint to undelete and unmerge all merged profiles in a client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StudentsUndoMergesPatch(state: string, clientId?: string, options?: any) {
            return StudentsApiFp(configuration).v1StudentsUndoMergesPatch(state, clientId, options)(fetch, basePath);
        },
    };
};

/**
 * StudentsApi - interface
 * @export
 * @interface StudentsApi
 */
export interface StudentsApiInterface {
    /**
     * 
     * @param {string} providerId 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsCaseloadGet(providerId: string, clientId: string, state: string, options?: any): Promise<GetCaseloadStudentsResponse>;

    /**
     * 
     * @summary This endpoint is purely for referencing the example value and schema documentation for the StudentProfile data model.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsDataModelGet(state: string, options?: any): Promise<StudentProfile>;

    /**
     * 
     * @summary Gets all deleted students in a specific Client account, which is specified by the Client's ID.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsDeletedGet(clientId: string, state: string, options?: any): Promise<GetStudentsResponse>;

    /**
     * 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsDuplicateStudentProfilesGet(clientId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {Date} [dateOfBirth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsDuplicateStudentProfilesSingleStudentGet(clientId: string, state: string, firstName?: string, lastName?: string, dateOfBirth?: Date, options?: any): Promise<GetDuplicateStudentProfilesForSingleStudentResponse>;

    /**
     * 
     * @summary Gets all Student Profiles within a specific Client account, which is specified by the Client's ID.
     * @param {string} clientId The ID of the Client for which to retrieve all students.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsGet(clientId: string, state: string, options?: any): Promise<GetStudentsResponse>;

    /**
     * 
     * @summary Gets all ordered services from a student's ScheduleOfServices by StudentID.
     * @param {string} studentId 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsGetAllOrderedServicesGet(studentId: string, clientId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Deprecated
     * @param {string} studentId 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsGetAllOrderedSubjectsGet(studentId: string, clientId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Depricated
     * @param {string} studentId 
     * @param {string} clientId 
     * @param {string} serviceAreaId 
     * @param {string} serviceAreaName 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet(studentId: string, clientId: string, serviceAreaId: string, serviceAreaName: string, state: string, options?: any): Promise<{}>;

    /**
     * Sample Request:                    GET /{id}/ActiveGoals?clientId={clientId}&date={date}
     * @summary Gets all goals for one student that were active on the given date. Active means neither deactivated, ended, nor completed.
     * @param {string} id The ID of the student for whom to get active goals.
     * @param {string} clientId The client ID in which to retrieve the student&#x27;s profile and goal information.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] The date with which to evaluate if the goals are active.
     * @param {string} [serviceAreaId] DEPRECATED               The service area ID to filter the goals by. When set, the response will only contain goals with the specified               service area of focus. Remove this eventually.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdActiveGoalsGet(id: string, clientId: string, state: string, date?: Date, serviceAreaId?: string, options?: any): Promise<GoalsResponse>;

    /**
     * 
     * @summary Depricated
     * @param {string} id The ID of the student for whom to get the default session goals.
     * @param {string} clientId The Id of the client that the student is under.
     * @param {string} serviceAreaId The Id of service area of the session for which to get the default goals.
     * @param {Date} date The date of the session for which to get the default goals.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdDefaultSessionGoalsGet(id: string, clientId: string, serviceAreaId: string, date: Date, state: string, options?: any): Promise<GoalsResponse>;

    /**
     * 
     * @summary Gets a student profile by ID and ClientId and deletes that Student Profile.
     * @param {string} id The Id of the profile we want to delete.
     * @param {string} clientId The id of the client with the student profile we want to delete.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdDeletePatch(id: string, clientId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Gets a specific student, within specific a Client, both specified by ID.
     * @param {string} id The ID of the student to retrieve.
     * @param {string} clientId The ID of the client within which to search for the student.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdGet(id: string, clientId: string, state: string, options?: any): Promise<StudentResponse>;

    /**
     * Sample Request:                    GET /{id}/Goals?clientId={clientId}&includeEnded=true&includeDeactivated=true&includeCompleted=true
     * @summary Depricated
     * @param {string} id The ID of the student for whom to get the goals.
     * @param {string} clientId The ID of the client in which to get the student&#x27;s goals.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {boolean} [includeEnded] Determines if the response will include goals that have ended by now.
     * @param {boolean} [includeDeactivated] Determines if the response will include goals that have been deactivated by now.
     * @param {boolean} [includeCompleted] Determines if the response will include goals that have been completed by now.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdGoalsGet(id: string, clientId: string, state: string, includeEnded?: boolean, includeDeactivated?: boolean, includeCompleted?: boolean, options?: any): Promise<GoalsResponse>;

    /**
     * 
     * @summary Updates the student goal with the given goal number for the student with the given id.
     * @param {string} id The Id of the Student Profile to update a goal for.
     * @param {string} goalNumber The goal number of the goal to update.
     * @param {string} clientId The Id of the student&#x27;s client.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateGoalRequest} [body] The body of the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdGoalsGoalNumberPut(id: string, goalNumber: string, clientId: string, state: string, body?: UpdateGoalRequest, options?: any): Promise<GoalDisplay>;

    /**
     * 
     * @summary Creates a new student goal for the student with the given id.
     * @param {string} id The Id of the Student Profile to create a goal for.
     * @param {string} clientId The Id of the student&#x27;s client.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateGoalRequest} [body] The body of the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdGoalsPost(id: string, clientId: string, state: string, body?: CreateGoalRequest, options?: any): Promise<GoalDisplay>;

    /**
     * 
     * @summary Patches the student profile with the given id. Any field of the request that are left null will remain unchanged.
     * @param {string} id The Id of the Student Profile to patch.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchStudentRequest} [body] The body of the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdPatch(id: string, state: string, body?: PatchStudentRequest, options?: any): Promise<StudentResponse>;

    /**
     * 
     * @summary Gets the perscribed care provisions for the specified student in the specified client.
     * @param {string} id 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdPrescribedCareProvisionsByDateGet(id: string, clientId: string, state: string, date?: Date, options?: any): Promise<PrescribedCareProvisionsResponse>;

    /**
     * 
     * @summary Takes an ID and an UpdateStudentRequest object and updates the StudentProfile with the given ID.
     * @param {string} id The Id of the Student Profile we want to update
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateStudentRequest} [body] The UpdateStudentRequest object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdPut(id: string, state: string, body?: UpdateStudentRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary Restores a deleted student profile.
     * @param {string} id The id of the student profile we want to restore.
     * @param {string} clientId The Id of the Client containing the student profile we want to restore.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsIdUndoDeletePatch(id: string, clientId: string, state: string, options?: any): Promise<StudentResponse>;

    /**
     * 
     * @summary Gets all students within a specific Client account that match the provided search text, and that are assigned to the campuses  specified by ids. Search results also include students that are not assigned to any campuses.
     * @param {string} clientId The ID of the Client for which to retrieve all students.
     * @param {string} searchText The text to use for searching.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [schoolCampusIdsString] A comma-delimited string containing the IDs of the school campuses to filter the results by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsMergeDisplayBySearchGet(clientId: string, searchText: string, state: string, schoolCampusIdsString?: string, options?: any): Promise<GetStudentMergeDisplaysResponse>;

    /**
     * 
     * @summary The endpoint for merging student profiles
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {MergeStudentsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsMergeStudentsPatch(state: string, body?: MergeStudentsRequest, options?: any): Promise<StudentProfileMergeResponse>;

    /**
     * NOTE: The new container must already be set up in the database. This endpoint will not create a new container. Be sure  to configure the partition key correctly before executing this endpoint as well.
     * @summary This endpoint will migrate all students within the database to a new container in the database.
     * @param {string} containerName 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {MigrateStudentsToContainerPostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsMigrateToContainerContainerNamePost(containerName: string, state: string, body?: MigrateStudentsToContainerPostRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary This is the endpoint to get the total ordered minutes for an ordered Service Area
     * @param {string} clientId 
     * @param {string} studentId 
     * @param {string} serviceAreaId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsOrderedServiceAreaTimeOrderedGet(clientId: string, studentId: string, serviceAreaId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Depricated
     * @param {OrderedSubject} body 
     * @param {string} clientId 
     * @param {string} studentId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsOrderedSubjectTimeOrderedGet(body: OrderedSubject, clientId: string, studentId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Creates a new student profile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateStudentRequest} [body] The request body for creating a student profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsPost(state: string, body?: CreateStudentRequest, options?: any): Promise<StudentResponse>;

    /**
     * 
     * @summary Gets all students within a specific Client account that match the provided search text, and that are assigned to the campuses  specified by ids. Search results also include students that are not assigned to any campuses.
     * @param {string} clientId The ID of the Client for which to retrieve all students.
     * @param {string} searchText The text to use for searching.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [schoolCampusIdsString] A comma-delimited string containing the IDs of the school campuses to filter the results by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsSearchGet(clientId: string, searchText: string, state: string, schoolCampusIdsString?: string, options?: any): Promise<GetCaseloadStudentsResponse>;

    /**
     * 
     * @summary This tests the connection to the Sessions API.
     * @param {string} serviceProviderId The id of the provider whose session to test.
     * @param {string} sessionId The id of the test session.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsSessionsTestServiceProviderIdSessionIdGet(serviceProviderId: string, sessionId: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary this is a test.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsTestGet(state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Developer use only.  An endpoint to undelete and unmerge all merged profiles in a client.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApiInterface
     */
    v1StudentsUndoMergesPatch(state: string, clientId?: string, options?: any): Promise<{}>;

}

/**
 * StudentsApi - object-oriented interface
 * @export
 * @class StudentsApi
 * @extends {BaseAPI}
 */
export class StudentsApi extends BaseAPI implements StudentsApiInterface {
    /**
     * 
     * @param {string} providerId 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsCaseloadGet(providerId: string, clientId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsCaseloadGet(providerId, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This endpoint is purely for referencing the example value and schema documentation for the StudentProfile data model.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsDataModelGet(state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsDataModelGet(state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all deleted students in a specific Client account, which is specified by the Client's ID.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsDeletedGet(clientId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsDeletedGet(clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsDuplicateStudentProfilesGet(clientId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsDuplicateStudentProfilesGet(clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {Date} [dateOfBirth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsDuplicateStudentProfilesSingleStudentGet(clientId: string, state: string, firstName?: string, lastName?: string, dateOfBirth?: Date, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsDuplicateStudentProfilesSingleStudentGet(clientId, state, firstName, lastName, dateOfBirth, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all Student Profiles within a specific Client account, which is specified by the Client's ID.
     * @param {string} clientId The ID of the Client for which to retrieve all students.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsGet(clientId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsGet(clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all ordered services from a student's ScheduleOfServices by StudentID.
     * @param {string} studentId 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsGetAllOrderedServicesGet(studentId: string, clientId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsGetAllOrderedServicesGet(studentId, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Deprecated
     * @param {string} studentId 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsGetAllOrderedSubjectsGet(studentId: string, clientId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsGetAllOrderedSubjectsGet(studentId, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Depricated
     * @param {string} studentId 
     * @param {string} clientId 
     * @param {string} serviceAreaId 
     * @param {string} serviceAreaName 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet(studentId: string, clientId: string, serviceAreaId: string, serviceAreaName: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsGetDefaultOrderedSubjectsByServiceAreaGet(studentId, clientId, serviceAreaId, serviceAreaName, state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /{id}/ActiveGoals?clientId={clientId}&date={date}
     * @summary Gets all goals for one student that were active on the given date. Active means neither deactivated, ended, nor completed.
     * @param {string} id The ID of the student for whom to get active goals.
     * @param {string} clientId The client ID in which to retrieve the student&#x27;s profile and goal information.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] The date with which to evaluate if the goals are active.
     * @param {string} [serviceAreaId] DEPRECATED               The service area ID to filter the goals by. When set, the response will only contain goals with the specified               service area of focus. Remove this eventually.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdActiveGoalsGet(id: string, clientId: string, state: string, date?: Date, serviceAreaId?: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdActiveGoalsGet(id, clientId, state, date, serviceAreaId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Depricated
     * @param {string} id The ID of the student for whom to get the default session goals.
     * @param {string} clientId The Id of the client that the student is under.
     * @param {string} serviceAreaId The Id of service area of the session for which to get the default goals.
     * @param {Date} date The date of the session for which to get the default goals.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdDefaultSessionGoalsGet(id: string, clientId: string, serviceAreaId: string, date: Date, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdDefaultSessionGoalsGet(id, clientId, serviceAreaId, date, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets a student profile by ID and ClientId and deletes that Student Profile.
     * @param {string} id The Id of the profile we want to delete.
     * @param {string} clientId The id of the client with the student profile we want to delete.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdDeletePatch(id: string, clientId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdDeletePatch(id, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets a specific student, within specific a Client, both specified by ID.
     * @param {string} id The ID of the student to retrieve.
     * @param {string} clientId The ID of the client within which to search for the student.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdGet(id: string, clientId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdGet(id, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /{id}/Goals?clientId={clientId}&includeEnded=true&includeDeactivated=true&includeCompleted=true
     * @summary Depricated
     * @param {string} id The ID of the student for whom to get the goals.
     * @param {string} clientId The ID of the client in which to get the student&#x27;s goals.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {boolean} [includeEnded] Determines if the response will include goals that have ended by now.
     * @param {boolean} [includeDeactivated] Determines if the response will include goals that have been deactivated by now.
     * @param {boolean} [includeCompleted] Determines if the response will include goals that have been completed by now.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdGoalsGet(id: string, clientId: string, state: string, includeEnded?: boolean, includeDeactivated?: boolean, includeCompleted?: boolean, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdGoalsGet(id, clientId, state, includeEnded, includeDeactivated, includeCompleted, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Updates the student goal with the given goal number for the student with the given id.
     * @param {string} id The Id of the Student Profile to update a goal for.
     * @param {string} goalNumber The goal number of the goal to update.
     * @param {string} clientId The Id of the student&#x27;s client.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateGoalRequest} [body] The body of the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdGoalsGoalNumberPut(id: string, goalNumber: string, clientId: string, state: string, body?: UpdateGoalRequest, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdGoalsGoalNumberPut(id, goalNumber, clientId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Creates a new student goal for the student with the given id.
     * @param {string} id The Id of the Student Profile to create a goal for.
     * @param {string} clientId The Id of the student&#x27;s client.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateGoalRequest} [body] The body of the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdGoalsPost(id: string, clientId: string, state: string, body?: CreateGoalRequest, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdGoalsPost(id, clientId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Patches the student profile with the given id. Any field of the request that are left null will remain unchanged.
     * @param {string} id The Id of the Student Profile to patch.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchStudentRequest} [body] The body of the request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdPatch(id: string, state: string, body?: PatchStudentRequest, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdPatch(id, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets the perscribed care provisions for the specified student in the specified client.
     * @param {string} id 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {Date} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdPrescribedCareProvisionsByDateGet(id: string, clientId: string, state: string, date?: Date, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdPrescribedCareProvisionsByDateGet(id, clientId, state, date, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Takes an ID and an UpdateStudentRequest object and updates the StudentProfile with the given ID.
     * @param {string} id The Id of the Student Profile we want to update
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateStudentRequest} [body] The UpdateStudentRequest object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdPut(id: string, state: string, body?: UpdateStudentRequest, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdPut(id, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Restores a deleted student profile.
     * @param {string} id The id of the student profile we want to restore.
     * @param {string} clientId The Id of the Client containing the student profile we want to restore.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsIdUndoDeletePatch(id: string, clientId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsIdUndoDeletePatch(id, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all students within a specific Client account that match the provided search text, and that are assigned to the campuses  specified by ids. Search results also include students that are not assigned to any campuses.
     * @param {string} clientId The ID of the Client for which to retrieve all students.
     * @param {string} searchText The text to use for searching.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [schoolCampusIdsString] A comma-delimited string containing the IDs of the school campuses to filter the results by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsMergeDisplayBySearchGet(clientId: string, searchText: string, state: string, schoolCampusIdsString?: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsMergeDisplayBySearchGet(clientId, searchText, state, schoolCampusIdsString, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The endpoint for merging student profiles
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {MergeStudentsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsMergeStudentsPatch(state: string, body?: MergeStudentsRequest, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsMergeStudentsPatch(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * NOTE: The new container must already be set up in the database. This endpoint will not create a new container. Be sure  to configure the partition key correctly before executing this endpoint as well.
     * @summary This endpoint will migrate all students within the database to a new container in the database.
     * @param {string} containerName 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {MigrateStudentsToContainerPostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsMigrateToContainerContainerNamePost(containerName: string, state: string, body?: MigrateStudentsToContainerPostRequest, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsMigrateToContainerContainerNamePost(containerName, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This is the endpoint to get the total ordered minutes for an ordered Service Area
     * @param {string} clientId 
     * @param {string} studentId 
     * @param {string} serviceAreaId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsOrderedServiceAreaTimeOrderedGet(clientId: string, studentId: string, serviceAreaId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsOrderedServiceAreaTimeOrderedGet(clientId, studentId, serviceAreaId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Depricated
     * @param {OrderedSubject} body 
     * @param {string} clientId 
     * @param {string} studentId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsOrderedSubjectTimeOrderedGet(body: OrderedSubject, clientId: string, studentId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsOrderedSubjectTimeOrderedGet(body, clientId, studentId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Creates a new student profile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateStudentRequest} [body] The request body for creating a student profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsPost(state: string, body?: CreateStudentRequest, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Gets all students within a specific Client account that match the provided search text, and that are assigned to the campuses  specified by ids. Search results also include students that are not assigned to any campuses.
     * @param {string} clientId The ID of the Client for which to retrieve all students.
     * @param {string} searchText The text to use for searching.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [schoolCampusIdsString] A comma-delimited string containing the IDs of the school campuses to filter the results by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsSearchGet(clientId: string, searchText: string, state: string, schoolCampusIdsString?: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsSearchGet(clientId, searchText, state, schoolCampusIdsString, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This tests the connection to the Sessions API.
     * @param {string} serviceProviderId The id of the provider whose session to test.
     * @param {string} sessionId The id of the test session.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsSessionsTestServiceProviderIdSessionIdGet(serviceProviderId: string, sessionId: string, state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsSessionsTestServiceProviderIdSessionIdGet(serviceProviderId, sessionId, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary this is a test.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsTestGet(state: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsTestGet(state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Developer use only.  An endpoint to undelete and unmerge all merged profiles in a client.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public v1StudentsUndoMergesPatch(state: string, clientId?: string, options?: any) {
        return StudentsApiFp(this.configuration).v1StudentsUndoMergesPatch(state, clientId, options)(this.fetch, this.basePath);
    }

}
/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary This endpoint allows for approved districts to be associated with a user.  This endpoint should also be used for activating users,   as activation is a sign that a user has one, or more, districts fully approved to them.
         * @param {AddDistrictsToUserRequest} body 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAddUserDistrictsPost(body: AddDistrictsToUserRequest, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling v1UsersAddUserDistrictsPost.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersAddUserDistrictsPost.');
            }
            const localVarPath = `/v1/Users/AddUserDistricts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddDistrictsToUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a user profile from the database, specified by service provider id and client id.
         * @param {string} serviceProviderId The service provider id of the user to retrieve from the database.
         * @param {string} clientId The client id of the user to retrieve from the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersByServiceProviderGet(serviceProviderId: string, clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1UsersByServiceProviderGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1UsersByServiceProviderGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersByServiceProviderGet.');
            }
            const localVarPath = `/v1/Users/ByServiceProvider`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /Users/Deleted
         * @summary Gets all users in the current database's users container that have been soft-deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersDeletedGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersDeletedGet.');
            }
            const localVarPath = `/v1/Users/Deleted`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    GET /Users
         * @summary Gets all users in the current database's users container.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersGet(state: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersGet.');
            }
            const localVarPath = `/v1/Users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user's authorized districts for the specified client. Does so by updating their user document, as well as the service provider  profile corresponding to the client in which the authorized districts are being updated.
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchAuthorizedDistrictsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch(id: string, clientId: string, state: string, body?: PatchAuthorizedDistrictsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch.');
            }
            const localVarPath = `/v1/Users/{id}/ClientAssignments/{clientId}/AuthorizedDistricts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchAuthorizedDistrictsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patches a client assignment for a user, allowing for replacing the lists of either  appointing service providers or supervised service providers.
         * @param {string} id The ID of the user to patch.
         * @param {string} clientId The ID of the client to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchClientAssignmentRequest} [body] The request containing the client assignment data to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdClientAssignmentsClientIdPatch(id: string, clientId: string, state: string, body?: PatchClientAssignmentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdClientAssignmentsClientIdPatch.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1UsersIdClientAssignmentsClientIdPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersIdClientAssignmentsClientIdPatch.');
            }
            const localVarPath = `/v1/Users/{id}/ClientAssignments/{clientId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchClientAssignmentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    DELETE /Users/{userId}
         * @summary Performs a soft deletes of a user profile, specified by id.
         * @param {string} id The id of the user profile to delete.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdDelete(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdDelete.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersIdDelete.');
            }
            const localVarPath = `/v1/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patches the electronic signature for a user.
         * @param {string} id The ID of the user to patch.
         * @param {StatementType} statementType The type of statement to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchElectronicSignatureRequest} [body] The request containing the electronic signature data to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdElectronicSignaturesStatementTypePatch(id: string, statementType: StatementType, state: string, body?: PatchElectronicSignatureRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdElectronicSignaturesStatementTypePatch.');
            }
            // verify required parameter 'statementType' is not null or undefined
            if (statementType === null || statementType === undefined) {
                throw new RequiredError('statementType','Required parameter statementType was null or undefined when calling v1UsersIdElectronicSignaturesStatementTypePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersIdElectronicSignaturesStatementTypePatch.');
            }
            const localVarPath = `/v1/Users/{id}/ElectronicSignatures/{statementType}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"statementType"}}`, encodeURIComponent(String(statementType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchElectronicSignatureRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a user profile from the database, specified by ID.
         * @param {string} id The ID of the user to retrieve from the database.  Should come from the objectId of the identity provider.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [emailAddress] The emailAddress of the user&#x27;s account. Sending this will result in the retrieval of the user&#x27;s  UserProfile migrated from legacy, if it exists.
         * @param {boolean} [includeInactiveClients] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdGet(id: string, state: string, emailAddress?: string, includeInactiveClients?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersIdGet.');
            }
            const localVarPath = `/v1/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (includeInactiveClients !== undefined) {
                localVarQueryParameter['includeInactiveClients'] = includeInactiveClients;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patches a user profile, leaving any null values unchanged.
         * @param {string} id The ID of the user to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdPatch(id: string, state: string, body?: PatchUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersIdPatch.');
            }
            const localVarPath = `/v1/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PatchUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    PUT /Users/{userId}      {          \"firstName\": \"John\",          \"lastName\": \"Doe\",          \"isSuperAdmin\": false,          \"isMsbAdmin\": false,          \"districtAssignments\": []      }
         * @summary Updates a user profile.
         * @param {string} id The id of the user profile to update.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateUserRequest} [body] The request body for updating a user profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdPut(id: string, state: string, body?: UpdateUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdPut.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersIdPut.');
            }
            const localVarPath = `/v1/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    PATCH /Users/{userId}/UndoDelete
         * @summary Restores/undoes a soft delete of a user profile, specified by id.
         * @param {string} id The id of the user profile to undo the soft delete of.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdUndoDeletePatch(id: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1UsersIdUndoDeletePatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersIdUndoDeletePatch.');
            }
            const localVarPath = `/v1/Users/{id}/UndoDelete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This needs to a get a list of each notification for a user in a certain client. If the user is a district admin,  it should get all district access requests directed at the user's authorized districts.
         * @param {string} userId 
         * @param {string} serviceProviderId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [districtIds] This should be null if the user is not a district admin. If the user is a district admin,              this should be a comma-delimited list of ids for the districts the user is authorized in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersNotificationsGet(userId: string, serviceProviderId: string, clientId: string, state: string, districtIds?: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling v1UsersNotificationsGet.');
            }
            // verify required parameter 'serviceProviderId' is not null or undefined
            if (serviceProviderId === null || serviceProviderId === undefined) {
                throw new RequiredError('serviceProviderId','Required parameter serviceProviderId was null or undefined when calling v1UsersNotificationsGet.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1UsersNotificationsGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersNotificationsGet.');
            }
            const localVarPath = `/v1/Users/Notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (districtIds !== undefined) {
                localVarQueryParameter['districtIds'] = districtIds;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (serviceProviderId !== undefined) {
                localVarQueryParameter['serviceProviderId'] = serviceProviderId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {string} partitionKey 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersNotificationsNotificationIdReadPatch(notificationId: string, partitionKey: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling v1UsersNotificationsNotificationIdReadPatch.');
            }
            // verify required parameter 'partitionKey' is not null or undefined
            if (partitionKey === null || partitionKey === undefined) {
                throw new RequiredError('partitionKey','Required parameter partitionKey was null or undefined when calling v1UsersNotificationsNotificationIdReadPatch.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersNotificationsNotificationIdReadPatch.');
            }
            const localVarPath = `/v1/Users/Notifications/{notificationId}/Read`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (partitionKey !== undefined) {
                localVarQueryParameter['partitionKey'] = partitionKey;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary THIS IS NOT FOR USE IN PRODUCTION ONLY FOR TESTING PURPOSES. This is a method to delete all users with a specific legacy ID.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [legacyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersNukeUserDelete(state: string, legacyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersNukeUserDelete.');
            }
            const localVarPath = `/v1/Users/NukeUser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (legacyId !== undefined) {
                localVarQueryParameter['legacyId'] = legacyId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample Request:                    POST /Users      {          \"firstName\": \"John\",          \"lastName\": \"Doe\",          \"isSuperAdmin\": false,          \"isMsbAdmin\": false,          \"districtAssignments\": []      }
         * @summary Creates a new user profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateUserRequest} [body] The request body for creating a new user profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersPost(state: string, body?: CreateUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersPost.');
            }
            const localVarPath = `/v1/Users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint is for requesting access to a client as an MSB employee. Results in a user having access to all client and all district within the client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToClientAsMSBEmployeePost(state: string, userId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersRequestAccessToClientAsMSBEmployeePost.');
            }
            const localVarPath = `/v1/Users/RequestAccessToClientAsMSBEmployee`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The endpoint for requesting access to a client. Results in a notification being created for each district the client is associated with.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RequestAccessToClientRequest} [body] Describes the request body required to request access to a client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToClientPost(state: string, body?: RequestAccessToClientRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersRequestAccessToClientPost.');
            }
            const localVarPath = `/v1/Users/RequestAccessToClient`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RequestAccessToClientRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary An endpoint to allow responding to many DistrictAccessRequests at once.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RespondToManyRequestsForDistrictAccessRequest} [body] The request body for responding to a number of requests for district access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToDistrictRespondManyPost(state: string, body?: RespondToManyRequestsForDistrictAccessRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersRequestAccessToDistrictRespondManyPost.');
            }
            const localVarPath = `/v1/Users/RequestAccessToDistrict/RespondMany`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RespondToManyRequestsForDistrictAccessRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The endpoint for responding to a request for district access.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RespondToRequestForDistrictAccessRequest} [body] The request body for responding to a request for district access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToDistrictRespondPost(state: string, body?: RespondToRequestForDistrictAccessRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersRequestAccessToDistrictRespondPost.');
            }
            const localVarPath = `/v1/Users/RequestAccessToDistrict/Respond`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RespondToRequestForDistrictAccessRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The endpoint for requesting access to post for a service provider. Results in a notification being created for service provider to approve or deny user to post on their account.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PostAccessRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestProxyAccessToPostPost(state: string, body?: PostAccessRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersRequestProxyAccessToPostPost.');
            }
            const localVarPath = `/v1/Users/RequestProxyAccessToPost`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PostAccessRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The endpoint for responding to a request for proxy access.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RespondToRequestForAccessToPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestProxyAccessToPostRespondPost(clientId: string, state: string, body?: RespondToRequestForAccessToPost, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1UsersRequestProxyAccessToPostRespondPost.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersRequestProxyAccessToPostRespondPost.');
            }
            const localVarPath = `/v1/Users/RequestProxyAccessToPost/Respond`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RespondToRequestForAccessToPost" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint should create a new service provider profile using the details in the request. The endpoint should then  create a UserProfile for an unregistered user, setting the user id to the email, which will later be used to match up the profile  with an azure b2c identity on sign-up. The user profile should contain a client assignment for the client in the request, which  should define the role of Service Provider - Autonomous, and should reference the newly created service provider profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateUnregisteredProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersUnregisteredProviderPost(state: string, body?: CreateUnregisteredProviderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersUnregisteredProviderPost.');
            }
            const localVarPath = `/v1/Users/UnregisteredProvider`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUnregisteredProviderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint gets the user management cards for viewing unapproved and denied users in a given client, as well as getting a history of previous denials and approvals for each user.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersUserManagementCardsGet(clientId: string, state: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling v1UsersUserManagementCardsGet.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling v1UsersUserManagementCardsGet.');
            }
            const localVarPath = `/v1/Users/UserManagementCards`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (state !== undefined && state !== null) {
                localVarHeaderParameter['State'] = String(state);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary This endpoint allows for approved districts to be associated with a user.  This endpoint should also be used for activating users,   as activation is a sign that a user has one, or more, districts fully approved to them.
         * @param {AddDistrictsToUserRequest} body 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAddUserDistrictsPost(body: AddDistrictsToUserRequest, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersAddUserDistrictsPost(body, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves a user profile from the database, specified by service provider id and client id.
         * @param {string} serviceProviderId The service provider id of the user to retrieve from the database.
         * @param {string} clientId The client id of the user to retrieve from the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersByServiceProviderGet(serviceProviderId: string, clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersByServiceProviderGet(serviceProviderId, clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /Users/Deleted
         * @summary Gets all users in the current database's users container that have been soft-deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersDeletedGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetUsersResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersDeletedGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    GET /Users
         * @summary Gets all users in the current database's users container.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersGet(state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetUsersResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersGet(state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Updates the user's authorized districts for the specified client. Does so by updating their user document, as well as the service provider  profile corresponding to the client in which the authorized districts are being updated.
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchAuthorizedDistrictsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch(id: string, clientId: string, state: string, body?: PatchAuthorizedDistrictsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch(id, clientId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Patches a client assignment for a user, allowing for replacing the lists of either  appointing service providers or supervised service providers.
         * @param {string} id The ID of the user to patch.
         * @param {string} clientId The ID of the client to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchClientAssignmentRequest} [body] The request containing the client assignment data to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdClientAssignmentsClientIdPatch(id: string, clientId: string, state: string, body?: PatchClientAssignmentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersIdClientAssignmentsClientIdPatch(id, clientId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    DELETE /Users/{userId}
         * @summary Performs a soft deletes of a user profile, specified by id.
         * @param {string} id The id of the user profile to delete.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdDelete(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersIdDelete(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Patches the electronic signature for a user.
         * @param {string} id The ID of the user to patch.
         * @param {StatementType} statementType The type of statement to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchElectronicSignatureRequest} [body] The request containing the electronic signature data to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdElectronicSignaturesStatementTypePatch(id: string, statementType: StatementType, state: string, body?: PatchElectronicSignatureRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersIdElectronicSignaturesStatementTypePatch(id, statementType, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieves a user profile from the database, specified by ID.
         * @param {string} id The ID of the user to retrieve from the database.  Should come from the objectId of the identity provider.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [emailAddress] The emailAddress of the user&#x27;s account. Sending this will result in the retrieval of the user&#x27;s  UserProfile migrated from legacy, if it exists.
         * @param {boolean} [includeInactiveClients] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdGet(id: string, state: string, emailAddress?: string, includeInactiveClients?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersIdGet(id, state, emailAddress, includeInactiveClients, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Patches a user profile, leaving any null values unchanged.
         * @param {string} id The ID of the user to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdPatch(id: string, state: string, body?: PatchUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersIdPatch(id, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    PUT /Users/{userId}      {          \"firstName\": \"John\",          \"lastName\": \"Doe\",          \"isSuperAdmin\": false,          \"isMsbAdmin\": false,          \"districtAssignments\": []      }
         * @summary Updates a user profile.
         * @param {string} id The id of the user profile to update.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateUserRequest} [body] The request body for updating a user profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdPut(id: string, state: string, body?: UpdateUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersIdPut(id, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    PATCH /Users/{userId}/UndoDelete
         * @summary Restores/undoes a soft delete of a user profile, specified by id.
         * @param {string} id The id of the user profile to undo the soft delete of.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdUndoDeletePatch(id: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersIdUndoDeletePatch(id, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This needs to a get a list of each notification for a user in a certain client. If the user is a district admin,  it should get all district access requests directed at the user's authorized districts.
         * @param {string} userId 
         * @param {string} serviceProviderId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [districtIds] This should be null if the user is not a district admin. If the user is a district admin,              this should be a comma-delimited list of ids for the districts the user is authorized in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersNotificationsGet(userId: string, serviceProviderId: string, clientId: string, state: string, districtIds?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccessNotificationResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersNotificationsGet(userId, serviceProviderId, clientId, state, districtIds, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {string} partitionKey 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersNotificationsNotificationIdReadPatch(notificationId: string, partitionKey: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersNotificationsNotificationIdReadPatch(notificationId, partitionKey, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary THIS IS NOT FOR USE IN PRODUCTION ONLY FOR TESTING PURPOSES. This is a method to delete all users with a specific legacy ID.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [legacyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersNukeUserDelete(state: string, legacyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersNukeUserDelete(state, legacyId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sample Request:                    POST /Users      {          \"firstName\": \"John\",          \"lastName\": \"Doe\",          \"isSuperAdmin\": false,          \"isMsbAdmin\": false,          \"districtAssignments\": []      }
         * @summary Creates a new user profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateUserRequest} [body] The request body for creating a new user profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersPost(state: string, body?: CreateUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This endpoint is for requesting access to a client as an MSB employee. Results in a user having access to all client and all district within the client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToClientAsMSBEmployeePost(state: string, userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersRequestAccessToClientAsMSBEmployeePost(state, userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The endpoint for requesting access to a client. Results in a notification being created for each district the client is associated with.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RequestAccessToClientRequest} [body] Describes the request body required to request access to a client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToClientPost(state: string, body?: RequestAccessToClientRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccessNotificationResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersRequestAccessToClientPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary An endpoint to allow responding to many DistrictAccessRequests at once.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RespondToManyRequestsForDistrictAccessRequest} [body] The request body for responding to a number of requests for district access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToDistrictRespondManyPost(state: string, body?: RespondToManyRequestsForDistrictAccessRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersRequestAccessToDistrictRespondManyPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The endpoint for responding to a request for district access.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RespondToRequestForDistrictAccessRequest} [body] The request body for responding to a request for district access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToDistrictRespondPost(state: string, body?: RespondToRequestForDistrictAccessRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersRequestAccessToDistrictRespondPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The endpoint for requesting access to post for a service provider. Results in a notification being created for service provider to approve or deny user to post on their account.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PostAccessRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestProxyAccessToPostPost(state: string, body?: PostAccessRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProxyPostAccessRequest> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersRequestProxyAccessToPostPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary The endpoint for responding to a request for proxy access.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RespondToRequestForAccessToPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestProxyAccessToPostRespondPost(clientId: string, state: string, body?: RespondToRequestForAccessToPost, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProxyPostAccessResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersRequestProxyAccessToPostRespondPost(clientId, state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This endpoint should create a new service provider profile using the details in the request. The endpoint should then  create a UserProfile for an unregistered user, setting the user id to the email, which will later be used to match up the profile  with an azure b2c identity on sign-up. The user profile should contain a client assignment for the client in the request, which  should define the role of Service Provider - Autonomous, and should reference the newly created service provider profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateUnregisteredProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersUnregisteredProviderPost(state: string, body?: CreateUnregisteredProviderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateUnregisteredProviderResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersUnregisteredProviderPost(state, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary This endpoint gets the user management cards for viewing unapproved and denied users in a given client, as well as getting a history of previous denials and approvals for each user.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersUserManagementCardsGet(clientId: string, state: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserManagementCardsResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).v1UsersUserManagementCardsGet(clientId, state, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary This endpoint allows for approved districts to be associated with a user.  This endpoint should also be used for activating users,   as activation is a sign that a user has one, or more, districts fully approved to them.
         * @param {AddDistrictsToUserRequest} body 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersAddUserDistrictsPost(body: AddDistrictsToUserRequest, state: string, options?: any) {
            return UsersApiFp(configuration).v1UsersAddUserDistrictsPost(body, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves a user profile from the database, specified by service provider id and client id.
         * @param {string} serviceProviderId The service provider id of the user to retrieve from the database.
         * @param {string} clientId The client id of the user to retrieve from the database.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersByServiceProviderGet(serviceProviderId: string, clientId: string, state: string, options?: any) {
            return UsersApiFp(configuration).v1UsersByServiceProviderGet(serviceProviderId, clientId, state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /Users/Deleted
         * @summary Gets all users in the current database's users container that have been soft-deleted.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersDeletedGet(state: string, options?: any) {
            return UsersApiFp(configuration).v1UsersDeletedGet(state, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    GET /Users
         * @summary Gets all users in the current database's users container.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersGet(state: string, options?: any) {
            return UsersApiFp(configuration).v1UsersGet(state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Updates the user's authorized districts for the specified client. Does so by updating their user document, as well as the service provider  profile corresponding to the client in which the authorized districts are being updated.
         * @param {string} id 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchAuthorizedDistrictsRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch(id: string, clientId: string, state: string, body?: PatchAuthorizedDistrictsRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch(id, clientId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Patches a client assignment for a user, allowing for replacing the lists of either  appointing service providers or supervised service providers.
         * @param {string} id The ID of the user to patch.
         * @param {string} clientId The ID of the client to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchClientAssignmentRequest} [body] The request containing the client assignment data to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdClientAssignmentsClientIdPatch(id: string, clientId: string, state: string, body?: PatchClientAssignmentRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersIdClientAssignmentsClientIdPatch(id, clientId, state, body, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    DELETE /Users/{userId}
         * @summary Performs a soft deletes of a user profile, specified by id.
         * @param {string} id The id of the user profile to delete.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdDelete(id: string, state: string, options?: any) {
            return UsersApiFp(configuration).v1UsersIdDelete(id, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Patches the electronic signature for a user.
         * @param {string} id The ID of the user to patch.
         * @param {StatementType} statementType The type of statement to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchElectronicSignatureRequest} [body] The request containing the electronic signature data to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdElectronicSignaturesStatementTypePatch(id: string, statementType: StatementType, state: string, body?: PatchElectronicSignatureRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersIdElectronicSignaturesStatementTypePatch(id, statementType, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieves a user profile from the database, specified by ID.
         * @param {string} id The ID of the user to retrieve from the database.  Should come from the objectId of the identity provider.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [emailAddress] The emailAddress of the user&#x27;s account. Sending this will result in the retrieval of the user&#x27;s  UserProfile migrated from legacy, if it exists.
         * @param {boolean} [includeInactiveClients] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdGet(id: string, state: string, emailAddress?: string, includeInactiveClients?: boolean, options?: any) {
            return UsersApiFp(configuration).v1UsersIdGet(id, state, emailAddress, includeInactiveClients, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Patches a user profile, leaving any null values unchanged.
         * @param {string} id The ID of the user to patch.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PatchUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdPatch(id: string, state: string, body?: PatchUserRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersIdPatch(id, state, body, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    PUT /Users/{userId}      {          \"firstName\": \"John\",          \"lastName\": \"Doe\",          \"isSuperAdmin\": false,          \"isMsbAdmin\": false,          \"districtAssignments\": []      }
         * @summary Updates a user profile.
         * @param {string} id The id of the user profile to update.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {UpdateUserRequest} [body] The request body for updating a user profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdPut(id: string, state: string, body?: UpdateUserRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersIdPut(id, state, body, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    PATCH /Users/{userId}/UndoDelete
         * @summary Restores/undoes a soft delete of a user profile, specified by id.
         * @param {string} id The id of the user profile to undo the soft delete of.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersIdUndoDeletePatch(id: string, state: string, options?: any) {
            return UsersApiFp(configuration).v1UsersIdUndoDeletePatch(id, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This needs to a get a list of each notification for a user in a certain client. If the user is a district admin,  it should get all district access requests directed at the user's authorized districts.
         * @param {string} userId 
         * @param {string} serviceProviderId 
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [districtIds] This should be null if the user is not a district admin. If the user is a district admin,              this should be a comma-delimited list of ids for the districts the user is authorized in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersNotificationsGet(userId: string, serviceProviderId: string, clientId: string, state: string, districtIds?: string, options?: any) {
            return UsersApiFp(configuration).v1UsersNotificationsGet(userId, serviceProviderId, clientId, state, districtIds, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {string} partitionKey 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersNotificationsNotificationIdReadPatch(notificationId: string, partitionKey: string, state: string, options?: any) {
            return UsersApiFp(configuration).v1UsersNotificationsNotificationIdReadPatch(notificationId, partitionKey, state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary THIS IS NOT FOR USE IN PRODUCTION ONLY FOR TESTING PURPOSES. This is a method to delete all users with a specific legacy ID.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [legacyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersNukeUserDelete(state: string, legacyId?: string, options?: any) {
            return UsersApiFp(configuration).v1UsersNukeUserDelete(state, legacyId, options)(fetch, basePath);
        },
        /**
         * Sample Request:                    POST /Users      {          \"firstName\": \"John\",          \"lastName\": \"Doe\",          \"isSuperAdmin\": false,          \"isMsbAdmin\": false,          \"districtAssignments\": []      }
         * @summary Creates a new user profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateUserRequest} [body] The request body for creating a new user profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersPost(state: string, body?: CreateUserRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This endpoint is for requesting access to a client as an MSB employee. Results in a user having access to all client and all district within the client.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToClientAsMSBEmployeePost(state: string, userId?: string, options?: any) {
            return UsersApiFp(configuration).v1UsersRequestAccessToClientAsMSBEmployeePost(state, userId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The endpoint for requesting access to a client. Results in a notification being created for each district the client is associated with.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RequestAccessToClientRequest} [body] Describes the request body required to request access to a client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToClientPost(state: string, body?: RequestAccessToClientRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersRequestAccessToClientPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary An endpoint to allow responding to many DistrictAccessRequests at once.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RespondToManyRequestsForDistrictAccessRequest} [body] The request body for responding to a number of requests for district access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToDistrictRespondManyPost(state: string, body?: RespondToManyRequestsForDistrictAccessRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersRequestAccessToDistrictRespondManyPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The endpoint for responding to a request for district access.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RespondToRequestForDistrictAccessRequest} [body] The request body for responding to a request for district access.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestAccessToDistrictRespondPost(state: string, body?: RespondToRequestForDistrictAccessRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersRequestAccessToDistrictRespondPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The endpoint for requesting access to post for a service provider. Results in a notification being created for service provider to approve or deny user to post on their account.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {PostAccessRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestProxyAccessToPostPost(state: string, body?: PostAccessRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersRequestProxyAccessToPostPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary The endpoint for responding to a request for proxy access.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {RespondToRequestForAccessToPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersRequestProxyAccessToPostRespondPost(clientId: string, state: string, body?: RespondToRequestForAccessToPost, options?: any) {
            return UsersApiFp(configuration).v1UsersRequestProxyAccessToPostRespondPost(clientId, state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This endpoint should create a new service provider profile using the details in the request. The endpoint should then  create a UserProfile for an unregistered user, setting the user id to the email, which will later be used to match up the profile  with an azure b2c identity on sign-up. The user profile should contain a client assignment for the client in the request, which  should define the role of Service Provider - Autonomous, and should reference the newly created service provider profile.
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {CreateUnregisteredProviderRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersUnregisteredProviderPost(state: string, body?: CreateUnregisteredProviderRequest, options?: any) {
            return UsersApiFp(configuration).v1UsersUnregisteredProviderPost(state, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary This endpoint gets the user management cards for viewing unapproved and denied users in a given client, as well as getting a history of previous denials and approvals for each user.
         * @param {string} clientId 
         * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UsersUserManagementCardsGet(clientId: string, state: string, options?: any) {
            return UsersApiFp(configuration).v1UsersUserManagementCardsGet(clientId, state, options)(fetch, basePath);
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * 
     * @summary This endpoint allows for approved districts to be associated with a user.  This endpoint should also be used for activating users,   as activation is a sign that a user has one, or more, districts fully approved to them.
     * @param {AddDistrictsToUserRequest} body 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersAddUserDistrictsPost(body: AddDistrictsToUserRequest, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Retrieves a user profile from the database, specified by service provider id and client id.
     * @param {string} serviceProviderId The service provider id of the user to retrieve from the database.
     * @param {string} clientId The client id of the user to retrieve from the database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersByServiceProviderGet(serviceProviderId: string, clientId: string, state: string, options?: any): Promise<UserResponse>;

    /**
     * Sample Request:                    GET /Users/Deleted
     * @summary Gets all users in the current database's users container that have been soft-deleted.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersDeletedGet(state: string, options?: any): Promise<GetUsersResponse>;

    /**
     * Sample Request:                    GET /Users
     * @summary Gets all users in the current database's users container.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersGet(state: string, options?: any): Promise<GetUsersResponse>;

    /**
     * 
     * @summary Updates the user's authorized districts for the specified client. Does so by updating their user document, as well as the service provider  profile corresponding to the client in which the authorized districts are being updated.
     * @param {string} id 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchAuthorizedDistrictsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch(id: string, clientId: string, state: string, body?: PatchAuthorizedDistrictsRequest, options?: any): Promise<UserResponse>;

    /**
     * 
     * @summary Patches a client assignment for a user, allowing for replacing the lists of either  appointing service providers or supervised service providers.
     * @param {string} id The ID of the user to patch.
     * @param {string} clientId The ID of the client to patch.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchClientAssignmentRequest} [body] The request containing the client assignment data to patch.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersIdClientAssignmentsClientIdPatch(id: string, clientId: string, state: string, body?: PatchClientAssignmentRequest, options?: any): Promise<UserResponse>;

    /**
     * Sample Request:                    DELETE /Users/{userId}
     * @summary Performs a soft deletes of a user profile, specified by id.
     * @param {string} id The id of the user profile to delete.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersIdDelete(id: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary Patches the electronic signature for a user.
     * @param {string} id The ID of the user to patch.
     * @param {StatementType} statementType The type of statement to patch.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchElectronicSignatureRequest} [body] The request containing the electronic signature data to patch.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersIdElectronicSignaturesStatementTypePatch(id: string, statementType: StatementType, state: string, body?: PatchElectronicSignatureRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary Retrieves a user profile from the database, specified by ID.
     * @param {string} id The ID of the user to retrieve from the database.  Should come from the objectId of the identity provider.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [emailAddress] The emailAddress of the user&#x27;s account. Sending this will result in the retrieval of the user&#x27;s  UserProfile migrated from legacy, if it exists.
     * @param {boolean} [includeInactiveClients] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersIdGet(id: string, state: string, emailAddress?: string, includeInactiveClients?: boolean, options?: any): Promise<UserResponse>;

    /**
     * 
     * @summary Patches a user profile, leaving any null values unchanged.
     * @param {string} id The ID of the user to patch.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchUserRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersIdPatch(id: string, state: string, body?: PatchUserRequest, options?: any): Promise<UserResponse>;

    /**
     * Sample Request:                    PUT /Users/{userId}      {          \"firstName\": \"John\",          \"lastName\": \"Doe\",          \"isSuperAdmin\": false,          \"isMsbAdmin\": false,          \"districtAssignments\": []      }
     * @summary Updates a user profile.
     * @param {string} id The id of the user profile to update.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateUserRequest} [body] The request body for updating a user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersIdPut(id: string, state: string, body?: UpdateUserRequest, options?: any): Promise<{}>;

    /**
     * Sample Request:                    PATCH /Users/{userId}/UndoDelete
     * @summary Restores/undoes a soft delete of a user profile, specified by id.
     * @param {string} id The id of the user profile to undo the soft delete of.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersIdUndoDeletePatch(id: string, state: string, options?: any): Promise<UserResponse>;

    /**
     * 
     * @summary This needs to a get a list of each notification for a user in a certain client. If the user is a district admin,  it should get all district access requests directed at the user's authorized districts.
     * @param {string} userId 
     * @param {string} serviceProviderId 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [districtIds] This should be null if the user is not a district admin. If the user is a district admin,              this should be a comma-delimited list of ids for the districts the user is authorized in.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersNotificationsGet(userId: string, serviceProviderId: string, clientId: string, state: string, districtIds?: string, options?: any): Promise<AccessNotificationResponse>;

    /**
     * 
     * @param {string} notificationId 
     * @param {string} partitionKey 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersNotificationsNotificationIdReadPatch(notificationId: string, partitionKey: string, state: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary THIS IS NOT FOR USE IN PRODUCTION ONLY FOR TESTING PURPOSES. This is a method to delete all users with a specific legacy ID.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [legacyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersNukeUserDelete(state: string, legacyId?: string, options?: any): Promise<{}>;

    /**
     * Sample Request:                    POST /Users      {          \"firstName\": \"John\",          \"lastName\": \"Doe\",          \"isSuperAdmin\": false,          \"isMsbAdmin\": false,          \"districtAssignments\": []      }
     * @summary Creates a new user profile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateUserRequest} [body] The request body for creating a new user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersPost(state: string, body?: CreateUserRequest, options?: any): Promise<UserResponse>;

    /**
     * 
     * @summary This endpoint is for requesting access to a client as an MSB employee. Results in a user having access to all client and all district within the client.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersRequestAccessToClientAsMSBEmployeePost(state: string, userId?: string, options?: any): Promise<{}>;

    /**
     * 
     * @summary The endpoint for requesting access to a client. Results in a notification being created for each district the client is associated with.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RequestAccessToClientRequest} [body] Describes the request body required to request access to a client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersRequestAccessToClientPost(state: string, body?: RequestAccessToClientRequest, options?: any): Promise<AccessNotificationResponse>;

    /**
     * 
     * @summary An endpoint to allow responding to many DistrictAccessRequests at once.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RespondToManyRequestsForDistrictAccessRequest} [body] The request body for responding to a number of requests for district access.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersRequestAccessToDistrictRespondManyPost(state: string, body?: RespondToManyRequestsForDistrictAccessRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary The endpoint for responding to a request for district access.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RespondToRequestForDistrictAccessRequest} [body] The request body for responding to a request for district access.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersRequestAccessToDistrictRespondPost(state: string, body?: RespondToRequestForDistrictAccessRequest, options?: any): Promise<{}>;

    /**
     * 
     * @summary The endpoint for requesting access to post for a service provider. Results in a notification being created for service provider to approve or deny user to post on their account.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PostAccessRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersRequestProxyAccessToPostPost(state: string, body?: PostAccessRequest, options?: any): Promise<ProxyPostAccessRequest>;

    /**
     * 
     * @summary The endpoint for responding to a request for proxy access.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RespondToRequestForAccessToPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersRequestProxyAccessToPostRespondPost(clientId: string, state: string, body?: RespondToRequestForAccessToPost, options?: any): Promise<ProxyPostAccessResponse>;

    /**
     * 
     * @summary This endpoint should create a new service provider profile using the details in the request. The endpoint should then  create a UserProfile for an unregistered user, setting the user id to the email, which will later be used to match up the profile  with an azure b2c identity on sign-up. The user profile should contain a client assignment for the client in the request, which  should define the role of Service Provider - Autonomous, and should reference the newly created service provider profile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateUnregisteredProviderRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersUnregisteredProviderPost(state: string, body?: CreateUnregisteredProviderRequest, options?: any): Promise<CreateUnregisteredProviderResponse>;

    /**
     * 
     * @summary This endpoint gets the user management cards for viewing unapproved and denied users in a given client, as well as getting a history of previous denials and approvals for each user.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    v1UsersUserManagementCardsGet(clientId: string, state: string, options?: any): Promise<UserManagementCardsResponse>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * 
     * @summary This endpoint allows for approved districts to be associated with a user.  This endpoint should also be used for activating users,   as activation is a sign that a user has one, or more, districts fully approved to them.
     * @param {AddDistrictsToUserRequest} body 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersAddUserDistrictsPost(body: AddDistrictsToUserRequest, state: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersAddUserDistrictsPost(body, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves a user profile from the database, specified by service provider id and client id.
     * @param {string} serviceProviderId The service provider id of the user to retrieve from the database.
     * @param {string} clientId The client id of the user to retrieve from the database.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersByServiceProviderGet(serviceProviderId: string, clientId: string, state: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersByServiceProviderGet(serviceProviderId, clientId, state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /Users/Deleted
     * @summary Gets all users in the current database's users container that have been soft-deleted.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersDeletedGet(state: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersDeletedGet(state, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    GET /Users
     * @summary Gets all users in the current database's users container.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersGet(state: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersGet(state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Updates the user's authorized districts for the specified client. Does so by updating their user document, as well as the service provider  profile corresponding to the client in which the authorized districts are being updated.
     * @param {string} id 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchAuthorizedDistrictsRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch(id: string, clientId: string, state: string, body?: PatchAuthorizedDistrictsRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdClientAssignmentsClientIdAuthorizedDistrictsPatch(id, clientId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Patches a client assignment for a user, allowing for replacing the lists of either  appointing service providers or supervised service providers.
     * @param {string} id The ID of the user to patch.
     * @param {string} clientId The ID of the client to patch.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchClientAssignmentRequest} [body] The request containing the client assignment data to patch.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdClientAssignmentsClientIdPatch(id: string, clientId: string, state: string, body?: PatchClientAssignmentRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdClientAssignmentsClientIdPatch(id, clientId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    DELETE /Users/{userId}
     * @summary Performs a soft deletes of a user profile, specified by id.
     * @param {string} id The id of the user profile to delete.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdDelete(id: string, state: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdDelete(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Patches the electronic signature for a user.
     * @param {string} id The ID of the user to patch.
     * @param {StatementType} statementType The type of statement to patch.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchElectronicSignatureRequest} [body] The request containing the electronic signature data to patch.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdElectronicSignaturesStatementTypePatch(id: string, statementType: StatementType, state: string, body?: PatchElectronicSignatureRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdElectronicSignaturesStatementTypePatch(id, statementType, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Retrieves a user profile from the database, specified by ID.
     * @param {string} id The ID of the user to retrieve from the database.  Should come from the objectId of the identity provider.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [emailAddress] The emailAddress of the user&#x27;s account. Sending this will result in the retrieval of the user&#x27;s  UserProfile migrated from legacy, if it exists.
     * @param {boolean} [includeInactiveClients] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdGet(id: string, state: string, emailAddress?: string, includeInactiveClients?: boolean, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdGet(id, state, emailAddress, includeInactiveClients, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Patches a user profile, leaving any null values unchanged.
     * @param {string} id The ID of the user to patch.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PatchUserRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdPatch(id: string, state: string, body?: PatchUserRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdPatch(id, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    PUT /Users/{userId}      {          \"firstName\": \"John\",          \"lastName\": \"Doe\",          \"isSuperAdmin\": false,          \"isMsbAdmin\": false,          \"districtAssignments\": []      }
     * @summary Updates a user profile.
     * @param {string} id The id of the user profile to update.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {UpdateUserRequest} [body] The request body for updating a user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdPut(id: string, state: string, body?: UpdateUserRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdPut(id, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    PATCH /Users/{userId}/UndoDelete
     * @summary Restores/undoes a soft delete of a user profile, specified by id.
     * @param {string} id The id of the user profile to undo the soft delete of.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersIdUndoDeletePatch(id: string, state: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersIdUndoDeletePatch(id, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This needs to a get a list of each notification for a user in a certain client. If the user is a district admin,  it should get all district access requests directed at the user's authorized districts.
     * @param {string} userId 
     * @param {string} serviceProviderId 
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [districtIds] This should be null if the user is not a district admin. If the user is a district admin,              this should be a comma-delimited list of ids for the districts the user is authorized in.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersNotificationsGet(userId: string, serviceProviderId: string, clientId: string, state: string, districtIds?: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersNotificationsGet(userId, serviceProviderId, clientId, state, districtIds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} notificationId 
     * @param {string} partitionKey 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersNotificationsNotificationIdReadPatch(notificationId: string, partitionKey: string, state: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersNotificationsNotificationIdReadPatch(notificationId, partitionKey, state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary THIS IS NOT FOR USE IN PRODUCTION ONLY FOR TESTING PURPOSES. This is a method to delete all users with a specific legacy ID.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [legacyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersNukeUserDelete(state: string, legacyId?: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersNukeUserDelete(state, legacyId, options)(this.fetch, this.basePath);
    }

    /**
     * Sample Request:                    POST /Users      {          \"firstName\": \"John\",          \"lastName\": \"Doe\",          \"isSuperAdmin\": false,          \"isMsbAdmin\": false,          \"districtAssignments\": []      }
     * @summary Creates a new user profile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateUserRequest} [body] The request body for creating a new user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersPost(state: string, body?: CreateUserRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This endpoint is for requesting access to a client as an MSB employee. Results in a user having access to all client and all district within the client.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersRequestAccessToClientAsMSBEmployeePost(state: string, userId?: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersRequestAccessToClientAsMSBEmployeePost(state, userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The endpoint for requesting access to a client. Results in a notification being created for each district the client is associated with.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RequestAccessToClientRequest} [body] Describes the request body required to request access to a client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersRequestAccessToClientPost(state: string, body?: RequestAccessToClientRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersRequestAccessToClientPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary An endpoint to allow responding to many DistrictAccessRequests at once.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RespondToManyRequestsForDistrictAccessRequest} [body] The request body for responding to a number of requests for district access.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersRequestAccessToDistrictRespondManyPost(state: string, body?: RespondToManyRequestsForDistrictAccessRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersRequestAccessToDistrictRespondManyPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The endpoint for responding to a request for district access.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RespondToRequestForDistrictAccessRequest} [body] The request body for responding to a request for district access.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersRequestAccessToDistrictRespondPost(state: string, body?: RespondToRequestForDistrictAccessRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersRequestAccessToDistrictRespondPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The endpoint for requesting access to post for a service provider. Results in a notification being created for service provider to approve or deny user to post on their account.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {PostAccessRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersRequestProxyAccessToPostPost(state: string, body?: PostAccessRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersRequestProxyAccessToPostPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary The endpoint for responding to a request for proxy access.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {RespondToRequestForAccessToPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersRequestProxyAccessToPostRespondPost(clientId: string, state: string, body?: RespondToRequestForAccessToPost, options?: any) {
        return UsersApiFp(this.configuration).v1UsersRequestProxyAccessToPostRespondPost(clientId, state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This endpoint should create a new service provider profile using the details in the request. The endpoint should then  create a UserProfile for an unregistered user, setting the user id to the email, which will later be used to match up the profile  with an azure b2c identity on sign-up. The user profile should contain a client assignment for the client in the request, which  should define the role of Service Provider - Autonomous, and should reference the newly created service provider profile.
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {CreateUnregisteredProviderRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersUnregisteredProviderPost(state: string, body?: CreateUnregisteredProviderRequest, options?: any) {
        return UsersApiFp(this.configuration).v1UsersUnregisteredProviderPost(state, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary This endpoint gets the user management cards for viewing unapproved and denied users in a given client, as well as getting a history of previous denials and approvals for each user.
     * @param {string} clientId 
     * @param {string} state Describes which State the action is taking place in, and determines which context the action&#x27;s operations run under. Required for state-specific operations, such as database reads/writes, and operations that rely on state-specific configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public v1UsersUserManagementCardsGet(clientId: string, state: string, options?: any) {
        return UsersApiFp(this.configuration).v1UsersUserManagementCardsGet(clientId, state, options)(this.fetch, this.basePath);
    }

}
