/**
 * In the future this component won't be necessary if we are able to redirect users
 * to a page provided by azure or whoever, but this implementation will work for now
 */

import { Typography } from "@mui/material";
import Box from "../design/components-dev/BoxExtended";
import {
  BLUE_BACKGROUND,
  FloatingLayout,
  HEADER_SIZE,
  NextButtonJustifiedRight,
} from "../views/registration_flow/layout";
import { getSizing } from "../design/sizing";

export default function MaintenanceSplashPage(props: { onLogout: () => void }) {
  return (
    <Box sx={BLUE_BACKGROUND}>
      <FloatingLayout>
        <Typography variant={HEADER_SIZE}>Server Maintenance</Typography>
        <Typography sx={{ marginTop: getSizing(2), textAlign: "justify" }} variant="body1">
          {MESSAGE}
        </Typography>
        <NextButtonJustifiedRight overrideText="Logout" onNext={() => props.onLogout()} />
      </FloatingLayout>
    </Box>
  );
}

/**
 * In the future this should probably be passed in as a prop so it can be dynamic,
 * but we currently dont have anything setup outside the app to generate these messages so we are hardcoding this message for now
 */
const MESSAGE =
  "We apologize for the inconvenience. X Logs is currently undergoing scheduled maintenance to improve your experience. The client care team is available M-F 7am to 4:30pm CST. Thank you for you patience as we work to serve you better";
