import { useMemo } from "react";
import { useNotatorTools } from "../tools";
import {
  Accommodation,
  Activity,
  Modification,
  PrescribedCareProvisionsResponse,
} from "../../../profile-sdk";

interface StudentDefaultCareProvisions {
  defaultActivities: string[];
  defaultAccommodations: string[];
  defaultModifications: string[];
}

/**
 * Combines a student prescribed's care provisions with the default care provisions for this session to produce the
 * list of all care provision options we render for a given student.
 */
export function useStudentDefaultCareProvisions(props: {
  fetchedStudentPrescribedCareProvisions: PrescribedCareProvisionsResponse | null;
}): StudentDefaultCareProvisions {
  const { fetchedStudentPrescribedCareProvisions } = props;
  const notatorTools = useNotatorTools();
  const { fetchedDefaultCareProvisions } = notatorTools;

  const result: StudentDefaultCareProvisions = useMemo(() => {
    if (!fetchedDefaultCareProvisions || !fetchedStudentPrescribedCareProvisions) {
      return {
        defaultActivities: [],
        defaultAccommodations: [],
        defaultModifications: [],
      };
    }

    const defaultActivities = mergeCareProvisions(
      fetchedDefaultCareProvisions.activities?.map((aii) => aii.name!) ?? [],
      fetchedStudentPrescribedCareProvisions.activities ?? [],
    );

    const defaultAccommodations = mergeCareProvisions(
      fetchedDefaultCareProvisions.accommodations ?? [],
      fetchedStudentPrescribedCareProvisions.accommodations ?? [],
    );

    const defaultModifications = mergeCareProvisions(
      fetchedDefaultCareProvisions.modifications ?? [],
      fetchedStudentPrescribedCareProvisions.modifications ?? [],
    );

    return {
      defaultActivities,
      defaultAccommodations,
      defaultModifications,
    };
  }, [fetchedDefaultCareProvisions, fetchedStudentPrescribedCareProvisions]);

  return result;
}

function mergeCareProvisions(
  defaultProvisions: string[],
  prescribedProvisions: (Activity | Accommodation | Modification)[],
): string[] {
  const defaultNames = defaultProvisions.map((provision) => provision);
  const prescribedNotIncluded = prescribedProvisions
    .map((provision) => provision.name!)
    .filter((name) => !defaultNames.includes(name!));

  return [...defaultProvisions, ...prescribedNotIncluded];
}
