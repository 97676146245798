import { useState } from "react";
import { DefaultCareProvisionsResponse } from "../../../../profile-sdk";
import dayjs from "dayjs";
import { SessionResponse } from "../../../../session-sdk";
import { API_STATESNAPSHOTS } from "../../../../api/api";
import useEffectSkipMount from "../../../../hooks/use_effect_after_mount";
import { useXNGSelector } from "../../../../context/store";
import { selectClientID } from "../../../../context/slices/loggedInClientSlice";

export function useFetchedDefaultCareProvisions(props: {
  draftSession: SessionResponse;
  stateInUS: string;
  session: SessionResponse;
}): DefaultCareProvisionsResponse | null {
  const { draftSession, stateInUS, session } = props;

  const [defaultCareProvisions, setDefaultCareProvisions] =
    useState<DefaultCareProvisionsResponse | null>(null);
  const clientID = useXNGSelector(selectClientID);

  useEffectSkipMount(() => {
    const service = session.service;
    if (!service) throw new Error("Session's service data point was undefined");
    fetchAndSetDefaultCareProvisions();

    async function fetchAndSetDefaultCareProvisions() {
      if (session.service?.area?.id && session.service?.type?.id) {
        const sessionDate = dayjs(draftSession.meetingDetails?.startTime).toDate();

        try {
          const defaultCareProvisionsResponse =
            await API_STATESNAPSHOTS.v1StateSnapshotsByDateDefaultCareProvisionsGet(
              session.service?.type.id,
              session.service?.area?.id,
              stateInUS,
              sessionDate,
              clientID,
            );
          setDefaultCareProvisions(defaultCareProvisionsResponse);
        } catch (e) {
          console.error("Error while fetching default care provisions", e);
        }
      }
    }
  }, [session]);

  return defaultCareProvisions;
}
