import useApiQueryData from "../use_api_query_data";
import { API_STATESNAPSHOTS } from "../../api";
import XNGApiQueryParamObject from "../../../types/xng_api_query_param_object";
import { useXNGSelector } from "../../../context/store";
import { selectClientID } from "../../../context/slices/loggedInClientSlice";

type Data = Awaited<
  ReturnType<typeof API_STATESNAPSHOTS.v1StateSnapshotsByDateServiceProviderTypesGet>
>;
type QueryParams = {
  state: string;
  date?: Date;
};

const useApiQueryGetServiceProviderTypesByDate = (
  paramObject: XNGApiQueryParamObject<QueryParams, Data>,
) => {
  const { state, date } = paramObject.queryParams;
  const clientID = useXNGSelector(selectClientID);

  return useApiQueryData({
    queryFn: async () =>
      await API_STATESNAPSHOTS.v1StateSnapshotsByDateServiceProviderTypesGet(state, clientID, date),
    queryKey: ["v1StateSnapshotsByDateServiceProviderTypesGet", state, date],
    staleTime: 1000 * 60 * 60 * 5, // 5 hours
    ...(paramObject.options ?? {}),
  });
};

export default useApiQueryGetServiceProviderTypesByDate;
