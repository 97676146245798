import { useEffect, useState } from "react";
import { selectClientID } from "../../../context/slices/loggedInClientSlice";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";
import { useXNGSelector } from "../../../context/store";
import { PrescribedCareProvisionsResponse } from "../../../profile-sdk";
import { API_STUDENTS } from "../../../api/api";
import { useNotatorTools } from "../tools";
import dayjs from "dayjs";

/**
 * Retrieves the prescribed care provisions for each student. Runs on a per-student level.
 */
export function useFetchedStudentPrescribedCareProvisions(
  props: Readonly<{ studentIndex: number }>,
) {
  const { draftSession } = useNotatorTools();
  const { studentIndex } = props;
  const state = useXNGSelector(selectStateInUS);
  const loggedInClientId = useXNGSelector(selectClientID);

  const [fetchedStudentPrescribedCareProvisions, setFetchedStudentPrescribedCareProvisions] =
    useState<PrescribedCareProvisionsResponse | null>(null);

  useEffect(() => {
    (async function fetchAndSet() {
      const response = await API_STUDENTS.v1StudentsIdPrescribedCareProvisionsByDateGet(
        draftSession.studentJournalList![studentIndex].student?.id!,
        loggedInClientId!,
        state,
        dayjs(draftSession.meetingDetails?.date).toDate(),
      );
      setFetchedStudentPrescribedCareProvisions(response);
    })();
  }, [studentIndex]);

  return fetchedStudentPrescribedCareProvisions;
}
