import { Box, BoxProps, InputAdornment, TextField, TextFieldProps, useTheme } from "@mui/material";
import MSBIconRenderer from "../iconography/icon_renderer";
import MSBICONS from "../iconography/icons";
import MSBClose from "./button_close";

type CustomProps = {
  value: string;
  useClear?: { onClear: () => void };
  rootBoxProps?: BoxProps;
};

type MSBSearchProps = CustomProps & TextFieldProps;

function MSBSearch(props: MSBSearchProps) {
  const { palette } = useTheme();
  const {sx:rootBoxPropsSx, ...rootBoxProps} = props.rootBoxProps ?? {};
  return (
    <Box
      sx={{
        ".MuiTextField-root": { bgcolor: "transparent" }, // Ensures the default white background won't appear behind the input's rounded edges when component is on a non-white background
        ...rootBoxPropsSx,
      }}
      {...rootBoxProps}
    >
      <TextField
        variant="outlined"
        placeholder="Search..."
        size="small"
        InputProps={{
          sx: { pl: ".5rem", bgcolor: "white" },
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                pointerEvents: "none",
              }}
            >
              <MSBIconRenderer i={<MSBICONS.Search />} size="sm" color={palette.text.primary} />
            </InputAdornment>
          ),
          ...(props.useClear &&
            props.value.length > 0 && {
              endAdornment: (
                <InputAdornment position="end">
                  <MSBClose onClick={() => props.useClear?.onClear()} />
                </InputAdornment>
              ),
            }),
        }}
        {...props}
      />
    </Box>
  );
}

export default MSBSearch;
